import { disconnectMetaMask, getUserData } from "../userWallet.js";
import { blockChainSigner } from "../../core/blockchain/blockchainSigner";
import { userDataDefault } from "../userDefault.js";

console.log("WSLOGIN-MODULE-INIT");

const wssLoginUrl =
  "wss://gfsw0rprz3.execute-api.eu-west-3.amazonaws.com/sandbox";

var websocketLogin;
var openedWebSocketLogin;
var sessionWebSocketLogin;
var hashLoginWebSocketLogin;
var publicAddressWebSocketLogin;
var local_reducer; //USER REDUCER TO CHANGE STATE IN userData

var session = null;

export function webSocketLogout() {
  /*
    {	
        
    "action" : "sendLogOut",
    "wallet": "metamask",
    "publicAddress": "0x1465014386d7174885807A89617B3B0f3772EB39",
    "sessionId": "ATd4Eiqvl9yrSKqUSBC5KDQD42D42cppuWH2H-aK4qE3WLJeW4opYoY3sTXbVYp1T6Hq6b0KlVYILMEtLNG2FoYwFCyxWQU7qw7pfi-rKsYfAhuyrxdn8zzo-059xLNaCu8jxgEHbcDOVTdmpTtMuAaZHbbBoWHo5RalSoMOVFBIhruSsjMgobeXjcYz4j8uf77PrjlmojWcXOzW-2emnyLdtoybUTlnjeduxTaij2XxGiXiIabI04Vl0ztkoYFy",
    "hashLogin": "-14213915981-1041911929141951599611171032286"
   }

   */
  var lambda5 = {
    action: "sendLogOut",
    wallet: "metamask",
    publicAddress: publicAddressWebSocketLogin,
    sessionId: sessionWebSocketLogin,
    hashLogin: hashLoginWebSocketLogin,
  };
  console.log("LOGIN OUT", lambda5);

  websocketLogin.send(JSON.stringify(lambda5));
}

export function wsLogin (publicAddress, hashLogin, _reducer) {
  this.session = session;
  this.connectionId = " ";
  this.hasConnectionId = false;

  openedWebSocketLogin = false;

  var scope = this;

  var arraySends = [];
  this.publicAddress = publicAddress;
  var dreceive;
  this.dreceive = dreceive;
  hashLoginWebSocketLogin = hashLogin;
  publicAddressWebSocketLogin = publicAddress;
  local_reducer = _reducer;

  managementLogin();
  function managementLogin() {
    console.log("L$L$L$L$L$L$ SOCKET LOGIN CONNECTION");
    websocketLogin = new WebSocket(wssLoginUrl);

    websocketLogin.onopen = function (event) {
      console.log("L$L$L$L$L$L$ SOCKET LOGIN ON_OPEN:", event);

      openedWebSocketLogin = true;
      websocketLogin.send("{ERROR}");
      if (arraySends.length > 0) {
        for (let i = 0; i < arraySends.length; i++) {
          var send = arraySends[i];
          websocketLogin.send(send);
          console.log(
            "L$L$L$L$L$L$ SOCKET LOGIN : ARRAY SENDING DATA...",
            send
          );
        }
        arraySends.length = 0;
      }
    };

    websocketLogin.onclose = function (event) {
      openedWebSocketLogin = false;
      console.log("L$L$L$L$L$L$ SOCKET LOGIN: Socket is closed. Reason=",event);
      if(event.reason.includes("Going away")){
        console.log("------------------------------");
        console.log("----Going Away detector-------");
        console.log("------------------------------");
        let e = document.getElementById("expiringElement");
        let o = e.parentNode;
        o.removeChild(e);
        //e.style.visibility="hidden";
        console.log("DOCUMENT OBJECT", o);
        console.log("PROPS", this.props);
        
        sessionStorage.clear();
        local_reducer.dispatchUser({type: "SET_SESSION",payload:undefined});
        local_reducer.dispatchUser({type: "SET_TYPE",payload:undefined});
        local_reducer.dispatchUser({type: "SET_INITIAL_STATE",payload:userDataDefault()});
       
        console.log("RELOAD...");
        window.location.reload();  
      }
    };

    websocketLogin.onmessage = async function (event) {
      console.log("L$L$L$L$L$L$ SOCKET LOGIN: ON_MESSAGE, receive:");
      console.log(event.data);

      console.log("L$L$L$L$L$L$ SOCKET LOGIN: PARSING in DRECEIVE");
      dreceive = JSON.parse(event.data);
      console.log(dreceive);

      if (!scope.hasConnectionId && event.data.includes("connectionId")) {
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: CONNECTION_ID_CATCHING:");
        scope.connectionId = dreceive.connectionId;
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: ConnectionId=",
          scope.connectionId
        );
        scope.hasConnectionId = true;
      }

      if (event.data.includes("nonce")) {
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: CATCH NONCE", dreceive.nonce);

        blockChainSigner(dreceive.nonce, publicAddress)
          .then((response) => {
            console.log("L$L$L$L$L$L$ SOCKET LOGIN: SIGNED RESPONSE", response);
            var signature = response.signature;
            console.log(
              "L$L$L$L$L$L$ SOCKET LOGIN: SIGNATURE IN RESPONSE",
              signature
            );
            var lambda2 = {
              action: "sendSignature",
              wallet: "METAMASK",
              publicAddress: publicAddress,
              signature: signature,
              hashLogin: hashLogin,
            };
            websocketLogin.send(JSON.stringify(lambda2));
          })
          .catch((error) => {
            console.log("L$L$L$L$L$L$ SOCKET LOGIN: ERROR!!!");
            var lambda3 = {
              action: "cancelSignature",
              wallet: "METAMASK",
              publicAddress: publicAddress,
              hashLogin: hashLogin,
            };
            websocketLogin.send(JSON.stringify(lambda3));
          });
      }

      if (event.data.includes("addLogOutUser")) {
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: LOGOUT USER...");
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: CLOSING...");
        websocketLogin.close(1000,"WsLogin-close-logout");
      }

      if (event.data.includes("Hello CancelDeleteLogin")) {
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: CLOSING...");
        websocketLogin.close(1000,"WsLogin-close-CancelDeleteLogin");
      }

      /*
            {message: 'sessionExpired', 
            session: 'kUXn8Hx2DMdDagcQzVN4HBe9JL139p2ueYRqyjyUsFhXISzS7G…2cwZ7duXGMNyt35eMg3YZjPQ3c7QiYtHuU4g0jxO9b5dFSSnC', 
            userAddressId: '0x1465014386d7174885807A89617B3B0f3772EB39', 
            timestampEpoch: 1647271383254
            */
      if (
        event.data.includes("sessionExpired") &&
        event.data.includes("userAddressId") &&
        event.data.includes("session")
      ) {
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: SESSION EXPIRED:",
          dreceive.session
        );
        scope.session = dreceive.session;
        var sessionE = dreceive.session;
        //getUserData(sessionE);
        let message = dreceive.message;
        let userAddressId = dreceive.userAddressId;
        let timestampEpoch = dreceive.timestampEpoch;

        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: SESSION EXPIRED: MESSAGE",
          message
        );
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: SESSION EXPIRED: SESSION",
          sessionE
        );
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: SESSION EXPIRED: USERADDRESSID",
          userAddressId
        );
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: SESSION EXPIRED: TIMESTAMPEPOCH",
          timestampEpoch
        );
        let e = document.getElementById("expiringElement");
        let o = e.parentNode;
        o.removeChild(e);
        //e.style.visibility="hidden";
        console.log("DOCUMENT OBJECT", o);
        console.log("PROPS", this.props);
        
        sessionStorage.clear();
        local_reducer.dispatchUser({type: "SET_SESSION",payload:undefined});
        local_reducer.dispatchUser({type: "SET_TYPE",payload:undefined});
        local_reducer.dispatchUser({type: "SET_INITIAL_STATE",payload:userDataDefault()});
        console.log("CLOSING...");
        await websocketLogin.close(1000,"WsLogin-close-SessionExpiring");
        console.log("RELOAD...");
        window.location.reload();    
        
      }
      if (
        event.data.includes("Hello ValidateSignature") &&
        event.data.includes("session") &&
        event.data.includes("match") &&
        event.data.includes("true")
      ) {
        console.log("L$L$L$L$L$L$ SOCKET LOGIN:SESSION:", dreceive.session);
        scope.session = dreceive.session;
        session = dreceive.session;
        sessionWebSocketLogin = scope.session;
        console.log("\n\nGET USER DATA FROM RECENT SESSION!!!!",session,"\n\n");
        getUserData(session);

        //FIRST REFRESH WEBSOCKET LAMBDA INVOCATION
        /*
                var lambda4={
                    "action": "refresh",
                    "wallet": "metamask",
                    "publicAddress": publicAddress,
                    "sessionId": session,
                    "connectionId": scope.connectionId
                }
                websocketLogin.send(JSON.stringify(lambda4));
                */
        // websocketLogin.close();
      }
      if (
        event.data.includes("refreshSession") &&
        event.data.includes("about") &&
        event.data.includes("to") &&
        event.data.includes("expire")
      ) {
        let message = dreceive.message;
        let session = dreceive.sessionToExpire;
        let userAddressId = dreceive.userAddressId;
        let timestampEpoch = dreceive.timestampEpoch;

        console.log("L$L$L$L$L$L$ SOCKET LOGIN: MESSAGE", message);
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: SESSIONID", session);
        console.log("L$L$L$L$L$L$ SOCKET LOGIN: USERADDRESSID", userAddressId);
        console.log(
          "L$L$L$L$L$L$ SOCKET LOGIN: TIMESTAMPEPOCH",
          timestampEpoch
        );

        var newElement = document.createElement("form");
        newElement.id = "expiringElement";
        newElement.style.zIndex = "1";
        newElement.style.transition = "0.3s";
        newElement.style.color = "white";
        newElement.style.textAlign = "center";
        newElement.style.border = "5px solid green";
        newElement.style.boxSizing = "border-box";
        newElement.style.background = "black";
        newElement.style.position = "fixed";
        newElement.style.display = "block";
        newElement.style.width = "400px";
        newElement.style.top = "50%";
        newElement.style.left = "50%";
        newElement.style.transform = "translate(-50%,-50%)";

        //newElement.style.width="50vw";
        //newElement.style.marginLeft="25vw";
        var label1 = document.createElement("LABEL");
        label1.setAttribute("value", "ALERT");
        newElement.appendChild(label1);
        var label2 = document.createElement("LABEL");
        label2.setAttribute("value", `USERADDRESSID: ${userAddressId}`);
        newElement.appendChild(label2);
        var h1 = document.createElement("H1");
        var t = document.createTextNode(
          "THE SESION NEED REFRESH TO NOT EXPIRING"
        );
        h1.appendChild(t);
        newElement.appendChild(h1);
        var but = document.createElement("button");
        but.innerHTML = "OK";
        newElement.appendChild(but);
        but.addEventListener("mousedown", butRefresh, false);

        document.body.appendChild(newElement);
      }

      function butRefresh(event) {
        var lambda4 = {
          action: "refresh",
          wallet: "metamask",
          publicAddress: publicAddress,
          sessionId: session.session,
          connectionId: scope.connectionId,
        };
        websocketLogin.send(JSON.stringify(lambda4));
        but.removeEventListener("mousedown", butRefresh, false);
        let e = document.getElementById("expiringElement");
        let o = e.parentNode;
        o.removeChild(e);
      }
    };

    websocketLogin.onerror = function (error) {
      console.log("L$L$L$L$L$L$ SOCKET LOGIN: [error] ", error.message);
      websocketLogin.close();
    };
  }

  scope.send = function (data) {
    if (!openedWebSocketLogin) arraySends.push(data);
    else {
      websocketLogin.send(data);
      console.log("L$L$L$L$L$L$ SOCKET LOGIN: SENDING DATA...", data);
    }
  };
}