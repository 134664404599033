import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react"
import {Drivers} from "../../../core/Utils.js"; 

HcMore(Highcharts);

function createOptions(datapos,dataneg)
{  
   return({
    series: [
      {
        name: "Positive",
        data: datapos,
        _colorIndex: 5
      },
      {
        name: "Negative",
        data: dataneg,
        _colorIndex: 9
      }
    ],

    chart: {
      polar: true,
      type: "column",
      backgroundColor: "#34495E",
      borderColor: "#ffffff",
      borderWidth: 0.5,
      borderRadius: 25
    },

    title: {
      text: "Emotional drivers & Polarity"
    },

    subtitle: {
      text: "Wind rose by polarity"
    },

    pane: {
      size: "85%"
    },

    legend: {
      align: "center",
      horizontalAlign: "down",
      layout: "horizontal"
    },

    xAxis: {
      tickmarkPlacement: "on",
      type: "category"
    },

    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        }
      },
      reversedStacks: true
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>'
    },

    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on"
      }
    },
    credits: {
      enabled: false
    }
  });
}

function DriversPolarityWindRose() {
  /*
  var datapos=[
    ["Meaning", 200],
    ["Empowerment", 10],
    ["Social Influence", 180],
    ["Unpredictability", 80],
    ["Avoidance", 140],
    ["Scarcity", 60],
    ["Ownership", 120],
    ["Acomplishment", 120]
  ];

  var dataneg=[
    ["Meaning", 200],
    ["Empowerment", 10],
    ["Social Influence", 180],
    ["Unpredictability", 80],
    ["Avoidance", 140],
    ["Scarcity", 60],
    ["Ownership", 120],
    ["Acomplishment", 120]
  ];
  */
  var datapos=[
    ["Meaning", 0],
    ["Empowerment", 0],
    ["Social Influence", 0],
    ["Unpredictability", 0],
    ["Avoidance", 0],
    ["Scarcity", 0],
    ["Ownership", 0],
    ["Acomplishment", 0]
  ];

  var dataneg=[
    ["Meaning", 0],
    ["Empowerment", 0],
    ["Social Influence", 0],
    ["Unpredictability", 0],
    ["Avoidance", 0],
    ["Scarcity", 0],
    ["Ownership", 0],
    ["Acomplishment", 0]
  ];
  const [options,setOptions] = useState(createOptions(datapos,dataneg));
  const [metricsState, dispatchMetrics] = useContext(MetricsContext); 

  useEffect(()=>{
    if(metricsState.metricResults && metricsState.metricResults.DriverPolarity)
    { 
        
        var newdatapos = [];
        var newdataneg = [];

        for(let i=0;i<metricsState.metricResults.DriverPolarity.length;i++)
        {
          let d=metricsState.metricResults.DriverPolarity[i];
          for(let j=0;j<Drivers.length;j++)
          {
            if(d.driver === Drivers[j].key)
            {
              newdatapos.push([Drivers[j].text,d.positives]);
              newdataneg.push([Drivers[j].text,d.negatives]);
            }
          }
        }
        console.log("DRIVERPOLARITY:newdatapos",newdatapos);
        console.log("DRIVERPOLARITY:newdataneg",newdataneg);
        setOptions(createOptions(newdatapos,newdataneg));

    }
  },[metricsState.metricResults]);

  return (
    
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default DriversPolarityWindRose;
