import { Segment } from "semantic-ui-react";

import React from "react";
import Description from "./EmoPlanetDetail/Description";
import Detail from "./EmoPlanetDetail/Detail";
import { updateDescription } from "../../services/Graphql/Game/updateGame.js";
import Tags from "./EmoPlanetDetail/Tags";


console.log("DATAPLANETDETAIL-COMPONENT-MODULE-INIT");

const DataPlanetDetail = (props) => {

  console.log("---DATAPLANETDETAIL-COMPONENT-INIT---");

  const updateDesc = async (gameId, planetOperation, description) => {
    await updateDescription(gameId, planetOperation, description);
    props.gameData.description = description;
  };
  return (
    <>
      <Segment textAlign="left">
        <Description gameData={props.gameData} updateDesc={updateDesc} />
      </Segment>

      <Detail gameData={props.gameData} />

      <Segment textAlign="left">
        <Tags gameData={props.gameData} />
      </Segment>
    </>
  );
};

export default DataPlanetDetail;
