import React, { useEffect, useState, useContext } from "react";
import { format, getDay, getYear } from "date-fns";
import { Header, Icon,Image } from "semantic-ui-react";
import "./userHeader.scss";
import { toast } from "react-toastify";
import { UserContext } from "../../services/userContext";

console.log("USER-HEADER-COMPONENT-MODULE-INIT");

const UserHeader = () => {
  
  console.log("----USER-HEADER-COMPONENT-INIT----");
  const [userState, dispatchUser] = useContext(UserContext);
  const [userData,setUserData] = useState(null);
  const [joined, setJoined] = useState({
    month: "",
    year: "",
    day: "",
  });

  // SET PLACEHOLDER, COMMENT FOR PRODUCTION
  const userDataPlaceHolder = {
    userAddressId: "0X 1083030209 DUMMY USER ADDRESS ID 242412489",
    joined: new Date(Date.now()).toDateString() + ":", //"28/Jan/2022:20:00:02 +0000"
    user: "@DummyUser",
  };

  

 const settingJoined = (uu) => {
    var userjoined = uu.joined.split(":")[0];

    var joinedmonth = format(Date.parse(userjoined), "LLLL");
    var joinedyear = getYear(Date.parse(userjoined));
    var joinedday = userjoined.split(/[\/]/)[0];

    console.log("USERJOINED", userjoined);
    console.log("MONTH:", userjoined.moth);
    console.log("YEAR:", userjoined.year);
    console.log("DAY", userjoined.day);

    setJoined({
      month: joinedmonth,
      year: joinedyear,
      day: joinedday,
    });
  };

  

  const notify = () =>
    toast.success("Link copied to clipboard!", {
      className: "toast-success-container toast-success-container-after",
    });

  const copyToClipBoard = (account) => {
    navigator.clipboard
      .writeText(account)
      .then(() => {
        notify();
        // console.log("Text copied to clipboard...")
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  };

 

    useEffect(()=>{
       
            if(userState.userData){
                setUserData(userState.userData); 
                settingJoined(userState.userData);
            }  
        }

    ,[userState.userData]);




  return (
    <>
      <Header as="h1" centered >MY GALAXY </Header>
      <Header as="h1">
        {userData && userData.user ? (<> {userData.user+ " "} </>) : ("no user ")}
        
        <Image 
            src={(userData)?
                ((userData.avatarLink)?userData.avatarLink:
                (userData.noAvatar)?userData.noAvatar:null):null 
            }
            circular
        />
      
      </Header>
      
      <Header as="h3">
        {userData && userData.userAddressId && `${userData.userAddressId.slice(0, 6)}...${userData.userAddressId.slice(userData.userAddressId.length - 4, userData.userAddressId.length)}`}

        {userData && userData.userAddressId ? (
          <Icon
            name="copy outline"
            onClick={() => copyToClipBoard(userData.userAddressId)}
          ></Icon>
        ) : null}
      </Header>

      <div> Joined first : 
        {userData && userData.user ? "Joined" : null} {joined.day}{" "}
        {joined.month} {joined.year}
      </div>
    </>
  );
};

export default UserHeader;
