import React from "react";
// Import Highcharts
import Highcharts from "highcharts";
import wordCloud from "highcharts/modules/wordcloud.js";
//import HighchartsReact from "./HighchartsReact.js";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";
import { ConsoleLogger } from "@aws-amplify/core";

DarkUnica(Highcharts);
wordCloud(Highcharts);

Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function (
  relativeWeight
) {
  var maxFontSize = 55;
  // Will return a fontSize between 0px and 25px.
  return Math.floor(maxFontSize * relativeWeight);
};

function createData(text){

  var lines = text.split(/[, ]+/g);
  return Highcharts.reduce(
      lines,
      function (arr, word) {
        var obj = Highcharts.find(arr, function (obj) {
          return obj.name === word;
        });
        if (obj) {
          obj.weight += 1;
        } else {
          obj = {
            name: word,
            weight: Math.ceil(Math.random() * 250)
          };
          arr.push(obj);
        }
        return arr;
      },
      []
    );
  
}

function createState(data){
  return (
    {
      chart: {
        backgroundColor: "black",
        borderColor: "#ffffff",
        borderWidth: 1.6,
        borderRadius: 25,
        shadow: true
      },
    
      title: {
        text: "Emotions-Cloud Top-15"
      },
    
      subtitle: {
        text: "List of top emotions"
      },
    
      pane: {
        size: "85%"
      },
    
      legend: {
        align: "right",
        horizontalAlign: "right",
        layout: "vertical"
      },
    
      series: [
        {
          type: "wordcloud",
          data: data
        }
      ],
      credits: {
        enabled: false
      }
    }
  )

}



// Render app with demo chart
function EmotionsCloud() {
  var data= createData("");
  const [options,setOptions] = useState(createState(data));
  const [metricsState, dispatchMetrics] = useContext(MetricsContext); 

  useEffect(()=>{
    let newdata="";
    if(metricsState.metricResults && metricsState.metricResults.TopEmoCloud)
    {
      for(let i=0;i<metricsState.metricResults.TopEmoCloud.length;i++)
      {
        let e = metricsState.metricResults.TopEmoCloud[i].emoname;
        newdata = newdata + e + " ";
      }
      console.log("EMOCLOUD:",newdata);
   
      setOptions(createState(createData(newdata)));
    }
  },[metricsState.metricResults]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default EmotionsCloud;
