import React from "react";
import { Container, Grid } from "semantic-ui-react";
import TotalsTopBox from "./MetricsCards/TotalsTopBox";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";
import { Drivers,Super_Emos } from "../../../core/Utils";

const TotalsTop = () => {

  const [top3hashtags,setTop3hashtags] = useState([]);
  const [top3superemo,setTop3superemo] = useState([]);
  const [top3locations,setTop3locations] = useState([]);
  const [top3drivers,setTop3drivers] = useState([]);

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);

  useEffect(()=>{

      
    if(metricsState.metricResults && 
      metricsState.metricResults.TopDrivers && 
      metricsState.metricResults.TopLocations && 
      metricsState.metricResults.TopHashTags && 
      metricsState.metricResults.TotalGamesSuperEmo){

      console.log("METRICS RESULT IS CHANGING,METRICSSTATE=",metricsState);
      //HASHTAGS
      let h=[];  
      for(let i=0;i<metricsState.metricResults.TopHashTags.length;i++){
        h.push(metricsState.metricResults.TopHashTags[i].hashtag);
      }
      console.log("HASHTAG LIST:",h);
      setTop3hashtags(h);
      // LOCATIONS
      let l=[];
      for(let i=0;i<metricsState.metricResults.TopLocations.length;i++)
      {
        if(i<3)
          l.push(metricsState.metricResults.TopLocations[i].location);
      }
      console.log("LOCATIONS LIST:",l);
      setTop3locations(l);

      //DRIVERS
      let d=[];
      for(let i=0;i<metricsState.metricResults.TopDrivers.length;i++)
      {
        let dkey=metricsState.metricResults.TopDrivers[i].driver;
        //console.log("DKEY=",dkey)
        for(let j=0;j<Drivers.length;j++)
        {
          if (dkey === Drivers[j].key){
            
            d.push(Drivers[j].text);
          }
        }
      }
      //console.log("DRIVERS LIST",d);
      setTop3drivers(d);
      //TOP3SUPEREMO
      let s=[];
      let arraySuperEmo=metricsState.metricResults.TotalGamesSuperEmo;
      //console.log("SUPEREMO RESULT:",arraySuperEmo);
      
      arraySuperEmo.sort((a,b)=>{
          if( a.sum_points>b.sum_points){
            return -1;
          }
          if(a.sum_points<b.sum_points){
            return 1;
          }
          return 0;
      });
      
      console.log("SUPEREMO ORDERED RESULT:",arraySuperEmo);
      for(let i=0;i<4;i++)
      {
        let skey=arraySuperEmo[i].semo;
        for(let j=0;j<Super_Emos.length;j++)
        {
            if(skey === Super_Emos[j].key)
            {
              s.push(Super_Emos[j].text);
            }
        }
      }
      console.log("SUPEREMO_LIST",s);
      setTop3superemo(s);    
    }
},[metricsState.metricResults]);

  return (
    <Container
      style={{
        paddingLeft: "20px"
      }}
    >
      <Grid.Row>
        <Grid columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TotalsTopBox
              content="top four ranking hashtags"
              value={top3hashtags}
              colorRibbon="purple"
              dataRibbon="TOP 4 HASHTAGS"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TotalsTopBox
              content="top four ranking super-emos"
              value={top3superemo}
              colorRibbon="black"
              dataRibbon="TOP 4 SUPER-EMO"
            />
          </Grid.Column>
        </Grid>
      </Grid.Row>
      <Grid.Row>
        <Grid columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TotalsTopBox
              content="top three ranking locations"
              value={top3locations}
              colorRibbon="grey"
              dataRibbon="TOP 3 LOCATIONS"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TotalsTopBox
              content="top three ranking drivers"
              value={top3drivers}
              colorRibbon="blue"
              dataRibbon="TOP 3 DRIVERS"
            />
          </Grid.Column>
        </Grid>
      </Grid.Row>
    </Container>
  );
};
export default TotalsTop;
