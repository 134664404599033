import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";
import {Super_Emos} from "../../../core/Utils.js";

DarkUnica(Highcharts);
HcMore(Highcharts);

function createOptions(datapos,dataneg)
{
  return ({
    series: [
      {
        name: "Positive",
        data: datapos,
        _colorIndex: 9
      },
      {
        name: "Negative",
        data: dataneg,
        _colorIndex: 5
      }
    ],

    chart: {
      polar: false,
      type: "column",
      backgroundColor: "#34495E",
      borderColor: "#ffffff",
      borderWidth: 0.5,
      borderRadius: 25
    },

    title: {
      text: "Top-5 Super-Emotions"
    },

    subtitle: {
      text: "Top-5 Super-Emotions"
    },

    pane: {
      size: "85%"
    },

    legend: {
      align: "center",
      horizontalAlign: "down",

      layout: "horizontal"
    },

    xAxis: {
      tickmarkPlacement: "on",
      type: "category"
    },

    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        }
      },
      reversedStacks: true
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>'
    },

    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on"
      }
    },
    credits: {
      enabled: false
    }
  });
}
function TopEmotionsBar() {
  //TopEmoRank
  /*
  var datapos=[
    ["EMO1", 200],
    ["EMO2", 1000],
    ["EMO3", 180],
    ["EMO4", 700],
    ["EMO5", 140]
  ];
  
  var dataneg=[
    ["EMO10", 10],
    ["EMO11", 180],
    ["EMO12", 80],
    ["EMO13", 140],
    ["EMO14", 60]
  ];
  */
  var datapos=[
    ["EMO1", 0],
    ["EMO2", 0],
    ["EMO3", 0],
    ["EMO4", 0],
    ["EMO5", 0]
  ];
  
  var dataneg=[
    ["EMO10", 0],
    ["EMO11", 0],
    ["EMO12", 0],
    ["EMO13", 0],
    ["EMO14", 0]
  ];
  const [options,setOptions]=useState(createOptions(datapos,dataneg));
  const [metricsState, dispatchMetrics] = useContext(MetricsContext); 

  useEffect(()=>{
    var semolistpos=[];
    var semolistneg=[];
    var newdatapos=[];
    var newdataneg=[];
    var auxdatapos=[];
    var auxdataneg=[];

    if(metricsState.metricResults && metricsState.metricResults.TotalGamesSuperEmo)
    {
      
      for(let i=0;i<metricsState.metricResults.TotalGamesSuperEmo.length;i++)
      {
        let se=metricsState.metricResults.TotalGamesSuperEmo[i];
        let val=parseInt(se.semo.split("O")[1]);
        //console.log("VAL=",val);
        if(val<9){
          semolistpos.push(se);
        }else{
          semolistneg.push(se);
        }


      }

      for(let i=0;i<semolistpos.length;i++)
      {
          let e=semolistpos[i];
          auxdatapos.push([e.semo.split("_")[0],e.sum_points]);         
      }

      for(let i=0;i<semolistneg.length;i++)
      {
        let e=semolistneg[i];
        auxdataneg.push([e.semo.split("_")[0],e.sum_points]);
      }
     
      
      for(let i=0;i<5;i++){
        newdatapos.push(auxdatapos[i]);
        newdataneg.push(auxdataneg[i]);
      }

      newdatapos.sort((a,b)=>{
        if(parseInt(a[0].split("O")[1])>parseInt(b[0].split("O")[1])) return 1;
        if(parseInt(a[0].split("O")[1])<parseInt(b[0].split("O")[1])) return -1;
        return 0;
      });

      newdataneg.sort((a,b)=>{
        if(parseInt(a[0].split("O")[1])>parseInt(b[0].split("O")[1])) return 1;
        if(parseInt(a[0].split("O")[1])<parseInt(b[0].split("O")[1])) return -1;
        return 0;
      })
      console.log("TOPEMOTIONS 5: emopos",newdatapos);
      console.log("TOPEMOTIONS 5: emoneg",newdataneg);
      setOptions(createOptions(newdatapos,newdataneg));
        
    }

  },[metricsState.metricResults]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default TopEmotionsBar;
