// EMOACTION PARTS:

// emoAction.type
// emoAction.payload
// emoAction.error
// emoAction.meta
// emoAction.OTHER <= ( all other things! )

console.log("GLOBALREDUCER-MODULE-INIT");

export const emoReducer = (emoState, emoAction) => {
    var result=null;
    switch (emoAction.type) {
        case "SET_MARKETPLACE_GRAPHQL_PARAMS":
            result= {
                ...emoState,
                marketPlaceGrapQlParams: emoAction.payload
            };
            console.log("EMOREDUCER:SET_MARKETPLACE_GRAPHQL_PARAMS",emoAction.payload);
            break;
        case "SET_MARKETPLACE_PLANET_RESULTS":
            result= {
                ...emoState,
                marketPlacePlanetResults: emoAction.payload
            };
            console.log("EMOREDUCER:SET_MARKETPLACE_PLANET_RESULTS",emoAction.payload);
            break;
        case "SET_WALLET":
            result= {
                ...emoState,
                userWallet: emoAction.payload
            };
            console.log("EMOREDUCER: SET_WALLET",emoAction.payload);
            break;
        default:
            result = emoState;
    }

    sessionStorage.setItem("globalState",JSON.stringify(result));
    return result;

}