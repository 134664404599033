import React from "react";
import { Dropdown, Label, Menu } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";


const options = [
  {
    key: "DRI6",
    text: "Scarcity",
    value: "DRI6",
    description:
      "There is not enough of it for the people who need it or want it."
  },
  {
    key: "DRI7",
    text: "Ownership",
    value: "DRI7",
    description: "The state or fact of being an owner."
  },
  {
    key: "DRI5",
    text: "Avoidance",
    value: "DRI5",
    description: " The act of keeping away from or preventing from happening"
  },
  {
    key: "DRI4",
    text: "Unpredictability",
    value: "DRI4",
    description: "Something not predictable; not to be foreseen or foretold"
  },
  {
    key: "DRI3",
    text: "Social Influence",
    value: "DRI3",
    description:
      "A person's attitudes or behaviour are altered by some form of social communication."
  },
  {
    key: "DRI2",
    text: "Empowerment",
    value: "DRI2",
    description: "The giving or delegation of power or authority; authorization"
  },
  {
    key: "DRI1",
    text: "Meaning",
    value: "DRI1",
    description:
      "The inner, symbolic, or true interpretation, value. Intending; having purpose"
  },
  {
    key: "DRI8",
    text: "Acomplishment",
    value: "DRI8",
    description: "The successful achievement of a task."
  }
];

const Drivers = () => {
  const renderLabel = (label) => ({
    color: "purple",
    content: `${label.text}`,
    icon: "check"
  });
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const saveOptionsToContext=(e,{value})=>{
   
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_SUPEREMOS_DRIVERS",payload:value})

  }

  return (
    <>
      <Menu fluid inverted stackable>
        <Menu.Item style={{ fontSize: "18px", width: "190px" }}>
          Drivers
          <Label color="purple" size="large">
          { 
              0
          }
          </Label>
        </Menu.Item>

        <Dropdown
          fluid
          openOnFocus
          search
          clearable
          style={{ background: "#34495E" }}
          placeholder="Search locations or n select them from the list"
          multiple
          selection
          options={options}
          renderLabel={renderLabel}
          onChange={saveOptionsToContext}
        />
      </Menu>
    </>
  );
};

export default Drivers;
