import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighChartExport from "highcharts/modules/exporting";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";
//HcMore(Highcharts);
DarkUnica(Highcharts);
HighChartExport(Highcharts);

function createOptions(datapos,dataneg)
{
  return ({
    exporting: {
      enabled: true
    },
    chart: {
      type: "area",
      backgroundColor: "#34495E",
      borderColor: "#ffffff",
      borderWidth: 1.1,
      borderRadius: 25,
      padding: "10px",
      shadow: true
    },
    title: {
      text: "Polarity & Day"
    },

    subtitle: {
      text: "Polarity grouped by day"
    },

    xAxis: {
      minRange: 1,
      labels: {
        format: "{value:%b %e}"
      },
      tickInterval: 60480000,
      type: "datetime",
      //min: 1569888000000 // 2019-10-01T00.00.00.000Z
    },

    credits: {
      enabled: false
    },
    series: [
      {
        name: "positve",
        data: datapos,
        _colorIndex: 9
      },

      {
        name: "negative",
        data: dataneg,
        _colorIndex: 5
      }
    ]
  });
}

// Render app with demo chart
function PolarityByDayArea() {
  var datapos=[
    [1595338200000, 150],
    [1595424600000, 150],
    [1595511000000, 580],
    [1595597400000, 80],
    [1595856600000, 90],
    [1595943000000, 500],
    [1596029400000, 820],
    [1596115800000, 800],
    [1596202200000, 900],
    [1596461400000, 920],
    [1596547800000, 600],
    [1596634200000, 890],
    [1596720600000, 150],
    [1596807000000, 250],
    [1597066200000, 400],
    [1597152600000, 600],
    [1597239000000, 1000],
    [1597325400000, 500]
  ];
  var dataneg=[
    [1595338200000, 150],
    [1595424600000, 50],
    [1595511000000, 80],
    [1595597400000, 180],
    [1595856600000, 90],
    [1595943000000, 500],
    [1596029400000, 20],
    [1596115800000, 400],
    [1596202200000, 500],
    [1596461400000, 1100],
    [1596547800000, 500],
    [1596634200000, 100],
    [1596720600000, 50],
    [1596807000000, 250],
    [1597066200000, 600],
    [1597152600000, 100],
    [1597239000000, 1000],
    [1597325400000, 100]
  ];

  const [options,setOptions]=useState(createOptions(datapos,dataneg));
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);

  useEffect(()=>{

    if(metricsState.metricResults && metricsState.metricResults.dataAllGames)
    {
        var newdataneg=[];
        var newdatapos=[];
        for(let i=0;i<metricsState.metricResults.dataAllGames.length;i++)
        {
          let d=metricsState.metricResults.dataAllGames[i];
          newdataneg.push([d.epoch,d.sum_points_negative]);
          newdatapos.push([d.epoch,d.sum_points_positive]);
        }
        console.log("DATAPOS",newdatapos);
        console.log("DATANEG",newdataneg);
        setOptions(createOptions(newdatapos,newdataneg));
    }

  },[metricsState.metricResults]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default PolarityByDayArea;
