import imag from "./fiber/assets/images/image_defaultbb.png";
import iconRotate from "./fiber/assets/images/icon_rotate.png";

import { Segment, Image, Header, Button, Input, Icon } from "semantic-ui-react";
import { Suspense, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { webSocketEngine } from "./fiber/websocketEngine.js";
import { OrbitControls, Stars } from "@react-three/drei";
import { Cloud } from "./fiber/cloudEmotion";
import { Core } from "./fiber/coreEmoSphere";
import { get18HashTags } from "./fiber/fakeData.js";
import { getEmotionyNames } from "./fiber/getemotionyname.js";
import { dataGameCreatorInit } from "./fiber/dataGame.js";
import { getDataGame, changeGeneralData } from "./fiber/dataGame.js";
import { userData } from "../../services/userParams.js";
import { setGameData } from "../../services/gameParams.js";
import { IMAGE } from "./fiber/image.js";
import NetworkShare from "../PlanetDetail/UserPlanetDetail/NetworkShare.js";
import { mode } from "../../mode.js";
import ToggleButton from "react-toggle-button";
import { ButtonEmotions } from "./fiber/buttonEmotion";
import { useHistory } from "react-router-dom";
import { showVars } from "../../core/initVarsAndFunctions";

import catImage from "../../shared/assets/images/cat.png";
import "./GameApp.scss";

console.log("GAMEAPP-COMPONENT-MODULE-INIT");

var emotionList = [
  ["open", 0],
  ["loving", 0],
  ["happy", 0],
  ["interested", 0],
  ["alive", 0],
  ["positive", 0],
  ["peaceful", 0],
  ["strong", 0],
  ["relaxed", 0],
  ["angry", 0],
  ["depressed", 0],
  ["confused", 0],
  ["helpless", 0],
  ["indiferent", 0],
  ["afraid", 0],
  ["hurt", 0],
  ["sad", 0],
  ["judmental", 0],
];

var w = null; // WEBSOCKET ENGINE

const GameApp = (props) => {
  //GAMEAPP
  const [listValues, setlistValues] = useState([]);
  const [typeCloud, setTypeCloud] = useState("hashtag");
  const [typeValuesSetting, setTypeValuesSetting] = useState(false);
  const [imgPlanet, setImgPlanet] = useState(imag);
  const [finishGame, setFinishGame] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [notFoundImage, setNotFoundImage] = useState(false);
  const [location, setLocation] = useState(null);
  

  console.log("\n\n-----GAME APP INIT ----");
  console.log( showVars());
  console.log("-----GAME APP INIT ----\n\n");
  console.log(" HASHTAGS IN PROPS:", props, props.hashtags);

  var hashtagsFromProps = null;
  if (props.hashtags) {
    var auxHashtags = props.hashtags;
    console.log("AUXHASHTAGS:", auxHashtags);
    if (auxHashtags) {
      var splitaux = auxHashtags.split(",");
      //console.log("SPLIT AUXHASHTAGS:",splitaux);
      hashtagsFromProps = "";
      for (var iH = 0; iH < splitaux.length; iH++) {
        hashtagsFromProps += "#" + splitaux[iH];
        if (iH < splitaux.length - 1) hashtagsFromProps += " ";
      }
    }

    console.log(">>>>HASHTAGS FROM PROPS:", hashtagsFromProps);
  }
  //getGeolocation_jsonp(geolocation_jsonp);

  function getLoc(data) {
    console.log("GETLOC DATAGAME", data.generalData.geoloc);
    // console.log('geolocation');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    //console.log("Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude + "Accuraccy:"+position.coords.accuracy);
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
    });
  }

  useEffect(() => {
    if (location) {
      var dataInLocation = getDataGame();
      dataInLocation.generalData.geoloc = location;
      console.log("LOCATIONUSEEFFECT", location);
      console.log("DATAGAME IN EFFECT", dataInLocation);
    }
  }, [location]);

  useEffect(() => {
    console.log("GAMEAPP USEEFFECT SET TYPECLOUD", typeCloud);
    console.log("GAMEAPP USEEFFECT SET TYPEVALUESETTING", typeValuesSetting);

    if (finishGame && typeCloud === "image") {
      console.log("GAMEAPP USEEFFECT FINISHGAME", finishGame);
      w = new webSocketEngine(mode, notFoundImage, setNotFoundImage);

      var data = getDataGame();

      data.textGame = inputValue;
      data.planetOperation = "planetCreationTest";

      w.setImgPlanet = setImgPlanet;

      //var idK = "00000000000kKey";

      changeGeneralData(data.generalData, w, "");

      const gameId = data.generalData.pngId;
      setGameData(gameId);
      console.log("gameId", gameId);

      w.imgurl = w.url + gameId + ".png";

      // recreate dataGame with new data and websocket

      console.log("FINISHING GAME");
      console.log("DATA GAME EMOTIONY = ", data);
      console.log("w.imgurl = ", w.imgurl);

      w.set(data);
      //w.send(data);
    }

    if (!typeValuesSetting && typeCloud === "emotions") {
      setList(getEmotionyNames(dataGameCreatorInit()), typeCloud);
      setTypeValuesSetting(true);

      var data = getDataGame();
      getLoc(data);

      if (userData.userAddressId) {
        console.log("SETTING USERADDRESID", userData.userAddressId);
        data.userAddressId = userData.userAddressId;
      } else {
        console.log(" NO USERADDRESSID EXISTS!!!, NOT SETTED");
      }

      console.log("GETUSERDATA FROM WALLET:JS", userData);
    }
  }, [typeCloud]);

  console.log(typeCloud);

  if (!typeValuesSetting && typeCloud === "hashtag") {
    setList(get18HashTags(), typeCloud);
    setTypeValuesSetting(true);
    if (hashtagsFromProps) {
      setInputValue(hashtagsFromProps);
      setlistValues(hashtagsFromProps);

      console.log("PREVIOUS EMOTIONLIST", emotionList);

      console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
      console.log("BUTTPLAY LISTVALUES", listValues);
      //console.log("DATAGAME",getDataGame());
      //getDataGame().textGame=hashtagValue;
      setTypeValuesSetting(false);
      setTypeCloud("emotions");

      //setList(get18EmotionyNames(dataGameCreatorInit()),typeCloud);
      console.log("LAST EMOTIONLIST", emotionList);
      hashtagsFromProps = null;
    }
  }

  if (typeCloud === "hashtag") {
    return (
      <>
        <Image centered size="tiny" src={catImage} wrapped ui={false} />

        <Header as="h2" className="mt--none">Something on your mind?</Header>
        <Header as="h3" className="mt--none">Discover your emotions!</Header>

        <HashtagButt
          inputValue={inputValue}
          setInputValue={setInputValue}
          setTypeValuesSetting={setTypeValuesSetting}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          props={props}
        />        

        <ButtPlay
          setTypeValuesSetting={setTypeValuesSetting}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          inputValue={inputValue}
        />
      </>
    );
  } else if (typeCloud === "emotions") {
    return (
      <>
        <GameAppEmotiony
          setImgPlanet={setImgPlanet}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          setFinishGame={setFinishGame}
          inputValue={inputValue}
        />

        <OnlyEndGameButton
          setTypeCloud={setTypeCloud}
          setImgPlanet={setImgPlanet}
          setFinishGame={setFinishGame}
          props={props}
        />

        <Image centered size="mini" src={catImage} wrapped ui={false} />
      </>
    );
  } else {
    return (
      <>
        <IMAGE
          src={imgPlanet}
          alt_txt={imgPlanet}
          width="100%"
          notFoundImage={notFoundImage}
          setNotFoundImage={setNotFoundImage}
          wengine={w}
          finishGame={finishGame}
          setFinishGame={setFinishGame}
        />

        <ReplayShareButton
          imgPlanet={imgPlanet}
          setlistValues={setlistValues}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          setTypeValuesSetting={setTypeValuesSetting}
          setImgPlanet={setImgPlanet}
          setFinishGame={setFinishGame}
          setInputValue={setInputValue}
          notFoundImage={notFoundImage}
          props={props}
        />
      </>
    );
  }
};

const handleClickIndicationGame = (event) => {
  console.log("CLICKED INDICATION GAME", event);
  var button = document.getElementById("buttIndic");
  button.style.display = "none";
};

export default GameApp;

const GameAppEmotiony = ({
  setImgPlanet,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  setFinishGame,
  inputValue,
}) => {
  // TOOGLE SETTINGS

  const [checkToogle, setCheckToogle] = useState(false);

  function toogleFunction() {
    console.log("toogleFunction");
    //const checkbox = document.getElementById("toogle");
    //console.log("CHECKBOX",checkbox)
    //checkbox.ckecked = !checkbox.checked;
    setCheckToogle(!checkToogle);
  }

  return (
    <div className="emotionsList">
      <ButtonEmotions
        setImgPlanet={setImgPlanet}
        emotionList={emotionList}
        listValues={listValues}
        setlistValues={setlistValues}
        typeCloud={typeCloud}
        setTypeCloud={setTypeCloud}
        setFinishGame={setFinishGame}
        inputValue={inputValue}
      />

      {/* {
        checkToogle && typeCloud === "emotions" ? (
          <Canvas
            shadowMap
            colorManagement
            dpr={[1, 2]}
            camera={{
              position: [0, 0, 35],
              fov: 90,
              zoom: mode.ZoomCanvas,
            }}
            concurrent
          >
            <Suspense fallback={null}>
              <fog attach="fog" args={["#061610", 18, 45]} />
              <ambientLight intensity={0.5} />
              <directionalLight position={[0, 0, 51]} intensity={1450} />
              <pointLight position={[0, -10, 5]} intensity={900} />
              <Stars
                radius={100}
                depth={50}
                count={1000}
                factor={3}
                saturation={1}
                fade="true"
              />
              <Cloud
                setImgPlanet={setImgPlanet}
                emotionList={emotionList}
                listValues={listValues}
                setlistValues={setlistValues}
                type={typeCloud}
                setTypeCloud={setTypeCloud}
                setFinishGame={setFinishGame}
                inputValue={inputValue}
                radius={mode.RadiusEmoSphere}
              />
              )
              <OrbitControls
                enableZoom={false}
                enablePan={false}
                autoRotate
                autoRotateSpeed="1"
              />
              <Core type={typeCloud} />
            </Suspense>
          </Canvas>
        ) : typeCloud === "emotions" ? (
          <ButtonEmotions
            setImgPlanet={setImgPlanet}
            emotionList={emotionList}
            listValues={listValues}
            setlistValues={setlistValues}
            typeCloud={typeCloud}
            setTypeCloud={setTypeCloud}
            setFinishGame={setFinishGame}
            inputValue={inputValue}
          />
        ) : null
      } */}
      {/* <div>
        {false && (
          <ToggleButton
            inactiveLabel={"Butt."}
            activeLabel={"Sph."}
            colors={{
              activeThumb: {
                base: "rgb(50,50,50)",
              },
              inactiveThumb: {
                base: "rgb(62,30,77)",
              },
              active: {
                base: "rgb(62,30,77)",
                hover: "rgb(92,70,127)",
              },
              inactive: {
                base: "rgb(65,66,68)",
                hover: "rgb(95,96,98)",
              },
            }}
            value={checkToogle}
            onToggle={toogleFunction}
          />
        )}
      </div> */}
    </div>
  );
};

const ButtPlay = ({
  setTypeValuesSetting,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  inputValue,
}) => {
  const [activePlay, setActivePlay] = useState(false);

  function handleClickButton(event) {
    event.stopPropagation();

    console.log("HANDLECLICKBUTTON", event);
    setHashtagsAndChangeEmotionCloud(false);
  }

  function setHashtagsAndChangeEmotionCloud(typeValueSetting) {
    console.log("PREVIOUS EMOTIONLIST", emotionList);

    console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
    console.log("BUTTPLAY LISTVALUES", listValues);
    //console.log("DATAGAME",getDataGame());
    //getDataGame().textGame=hashtagValue;
    setTypeValuesSetting(typeValueSetting);
    setTypeCloud("emotions");

    //setList(get18EmotionyNames(dataGameCreatorInit()),typeCloud);
    console.log("LAST EMOTIONLIST", emotionList);
  }

  useEffect(() => {
    if (inputValue.length > 2) setActivePlay(true);
    else setActivePlay(false);
  }, [inputValue]);

  return (
    <Button fluid size="big" disabled={!activePlay} onClick={handleClickButton} className="filled mt--double">Play</Button>
  );
};

const HashtagButt = ({
  inputValue,
  setInputValue,
  setTypeValuesSetting,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  props,
}) => {
  const handleClickInputValue = (e) => {
    e.stopPropagation();
    let input = e.target;
    let end = input.value.length;
    console.log("HANDLECLICKINPUTVALUE", e, input, end);
    input.setSelectionRange(end, end);
    input.focus();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      //INTRO KEY!!!

      console.log("PREVIOUS EMOTIONLIST", emotionList);

      console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
      //console.log("BUTTPLAY LISTVALUES", listValues);

      setTypeValuesSetting(false);
      setTypeCloud("emotions");

      console.log("LAST EMOTIONLIST", emotionList);
    }
  };

  const handleChangeInputValue = (e) => {
    e.stopPropagation();

    setInputValue(e.target.value);
    console.log(e.target.value, e.nativeEvent.inputType);
    if (
      e.nativeEvent.inputType &&
      e.nativeEvent.inputType.includes("insertFromPaste")
    ) {
      //HANDLEPASTE
      console.log("HANDLE PASTE EVENT", e);
      let inputPaste = e.target.value;
      console.log("PASTE:", inputPaste);

      //PARSE CLIPBOARD PASTE:
      let subPaste = inputPaste.substring(0, 8);
      console.log("SUBPASTE:", subPaste);
      if (
        inputPaste.substring(0, 8) === "https://" ||
        inputPaste.substring(0, 7) === "http://"
      ) {
        //PARSE HTTP
        console.log("IS HTTP(S):", inputPaste);
        let phrase = "";
        setInputValue(phrase);
        //setlistValues(phrase);
      } else {
        let phrase = "";
        let word = "";
        let wordCatch = false;
        for (let ipaste = 0; ipaste < inputPaste.length; ipaste++) {
          let char = inputPaste[ipaste];
          console.log("CHAR:" + char);
          if (char === "#") wordCatch = true;
          if (wordCatch) word += char;

          // IF IS AT THE END OF INPUT OR CHAR===' '
          if ((ipaste === inputPaste.length - 1 || char === " ") && wordCatch) {
            wordCatch = false;
            phrase = phrase + word;
            word = "";
          }
        }
        console.log("PHRASE CATCH:", phrase);
        setInputValue(phrase);
        //setlistValues(phrase);
      }
    }

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      console.log("DELETING");
    }
  };

  return (
      <Input 
      iconPosition='left' 
      placeholder='Type your sentence, hashtags, link, or what you want.'
      onChange={handleChangeInputValue}
      onClick={handleClickInputValue}
      onKeyDown={handleKeyDown}
      value={inputValue}
      >
        <Icon name='hashtag' />
        <input />
      </Input>
  );
};

const OnlyEndGameButton = ({
  setTypeCloud,
  setImgPlanet,
  setFinishGame,
  ...props
}) => {
  function handleClickonlyEndGameButt(event) {
    console.log("FINISH GAME EVENT handleClickonlyEndGameButt", event);
    event.stopPropagation();
    setTypeCloud("image");
    setFinishGame(true);
  }

  return (
    <Button fluid size="big" onClick={handleClickonlyEndGameButt} className="filled">Create your planet</Button>
  );
};

const ReplayShareButton = ({
  imgPlanet,
  setlistValues,
  typeCloud,
  setTypeCloud,
  setTypeValuesSetting,
  setImgPlanet,
  setFinishGame,
  setInputValue,
  notFoundImage,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const history = useHistory();
  console.log("REPLAYSHAREBUTTON imgPlanet", imgPlanet);
  var uriPlanet = imgPlanet
    ? imgPlanet.split(".")[imgPlanet.split(".").length - 2].split("/")[1]
    : "";
  if (uriPlanet) uriPlanet = uriPlanet.substring(0, uriPlanet.length - 1);
  console.log("URIPLANET=", uriPlanet);

  const shareButtonPropsFa = {
    url:
      //imgPlanet,
      //"https://dev.emotiony.io/share/poster/"+uriPlanet,
      "https://emo-htmlfileshare.s3.eu-west-3.amazonaws.com/" +
      uriPlanet +
      ".html",

    network: "twitter",
    text: uriPlanet,
    longtext: "EMOTIONY PLANET ",
    //"Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch."
  };

  function handleClickReplayShareButt(event) {
    console.log("EVENT handleClickReplayShareButt", event);
    event.stopPropagation();

    setTypeValuesSetting(false);
    setImgPlanet(imag);
    setFinishGame(false);
    setInputValue("");
    setlistValues([]);
    setTypeCloud("hashtag");
    history.push ('/'); // window.location.href = "/";
  }

  useEffect(() => {
    console.log("ReplayShareButton - CHANGE imgPlanet:", imgPlanet);
    console.log("ReplayShareButton - CHANGE URIPLANET:", uriPlanet);

    if (typeCloud === "image") {
      if (imgPlanet.includes("planetsimagelowresolution")) {
        console.log(imgPlanet, "CONTAINS planetsimagelowresolution");
        console.log("POSTEERRRRR", shareButtonPropsFa.url);
        setActive(true);
      } else {
        setActive(false);
      }

      if (notFoundImage) {
        console.log("USE EFFECT NOT FOUND IMAGE");
        setActive(true);
      }
    }
  }, [imgPlanet, notFoundImage]);

  return (
    <>
      <div>
        <Button
          fluid
          size="big" 
          id="replayShareButt"
          onClick={handleClickReplayShareButt}
          disabled={!active}
          className="filled mt--simple"
        >
          Play again
        </Button>

        <Button
          fluid
          size="big" 
          disabled={!active}
          className="outlined mt--simple"
        >
          Share planet
        </Button>
        
        {!active || notFoundImage ? null : (
          <NetworkShare
            shareUrl={shareButtonPropsFa.url}
            title={shareButtonPropsFa.longtext}
            exampleImage={imgPlanet}
          />
        )}
      </div>
    </>
  );
};

function setList(list, type) {
  emotionList = [];

  for (var i = 0; i < mode.NumEmotions; i++) {
    console.log(" = list[" + i + "]:" + list[i]);
    emotionList.push([list[i], 0]);
  }
}
