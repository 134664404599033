import React from "react";
import { Card, Button, Image, List, Label } from "semantic-ui-react";
import logo from "../../shared/assets/images/logo-emotiony2.png";

console.log("BOX-COMPONENT-MODULE-INIT");

const Box = (props) => {

  console.log("---BOX-COMPONENT-INIT---");

  const { price, title, extra, feature } = props;
  const listItems = feature.map((item) => <List.Item>{item}</List.Item>);

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Label ribbon content="Popular" />

          <Card.Header textAlign="center">
            {title}
            <Image floated="center" size="big" src={logo} wrapped ui={false} />
          </Card.Header>
        
          <Card.Meta textAlign="center">
            <span>{price} month</span>
          </Card.Meta>

          <Card.Description textAlign="center">
            <List>{listItems}</List>
          </Card.Description>
        </Card.Content>

        <Button fluid size="big">
          {extra}
        </Button>
      </Card>
    </>
  );
};

export default Box;
