import { sassTrue } from "sass";
import { TorusBufferGeometry } from "three";


export var mode = {
  modetest: false,               // true:TEST BUTTONS // false:NO TEST BUTTONS
  modetestServerConnection: false, // true:test AWS Routes // false:production AWS Routes
  notVideos: false,                 // true: not video // false: normal video,
  NumEmotions: 18,
  NumHashtags: 18,
  RadiusEmoSphere: 8,
  ZoomCanvas: 2.7,
  GameAppHeight: "54vh",
  GameAppWidth: "80vw"  
}

