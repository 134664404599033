
import { version } from 'react';
import { mode } from '../../../mode.js';
//import importHashtags from './assets/hashtagstemplate.json';
//import {google} from 'googleapis';

//import {gapi, drive, loadGapiInsideDOM,loadAuth2, loadAuth2WithProps, loadClientAuth2 } from 'gapi-script';


//import importHashtags from 'https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.json';

//const fileJsonid='1xlo5UEz5pMsXnhl78AxsPHK2l-dbxp9n';
var dataJsonFromAWS;

console.log("FAKEDATA-COMPONENT-MODULE-INIT");


// async function fakeDataInit(){
//     console.log("fakedataInit");
    
//     let gapiClient= await loadClientAuth2(gapi,
//                      "106715243957650770284",
//                     'https://www.googleapis.com/auth/drive.readonly'
//                     );

    
//     var request=gapi.client.request({
//             auth: './assets/hashtagstemplate.json',
//             path: '/drive/v2/files/'+fileJsonid,
//             fileId: fileJsonid,
//             method: 'GET',
//             params:{
//                 projection: "FULL"
//             }
//         });
    
    
//         request.execute(function(status,data){
//             console.log("FAKEDATA RESPONSE",status,data);
//         })

//         const res = await gapi.client.drive.files.get({
//             fileId: fileJsonid,
//             alt: 'media',
//           }, {
//             responseType: 'arraybuffer',
//           });
    
//           res.execute(function(status,data){
//             console.log("FAKEDATA RES RESPONSE",status,data);
//         })
//     var fs=require('fs');
//     console.log("FSSSSSSS",fs);
   
    

          
        
// }
    // var request = gapiComplete.client.drive.files.get({
    //   'fileId': fileJsonid
    // });

    // request.execute(function(resp) {
    //   console.log('Title: ' + resp.title);
    //   console.log('Description: ' + resp.description);
    //   console.log('MIME type: ' + resp.mimeType);
    // });

//fakeDataInit();


//FAILED
function fakeDataInit2(){
    console.log("---FAKEDATAINIT2-COMPONENT-INIT---");
    console.log("FAKEDATA fakeDataInit2");
    var req = new XMLHttpRequest();
    //req.open('GET', 'https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.json', false);
    //req.open('GET', 'https://drive.google.com/file/d/1xlo5UEz5pMsXnhl78AxsPHK2l-dbxp9n', false);
    //req.open('GET', 'https://drive.google.com/file/d/1xlo5UEz5pMsXnhl78AxsPHK2l-dbxp9n/view',false);
    //req.open('GET', 'http://217.71.200.247/hashtagstemplate.json',false);
    //req.open('GET', 'https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.html',false);
    req.open('GET', 'https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.json',false);
   //req.open('GET', 'https://emo-htmlfileshare.s3.eu-west-3.amazonaws.com/hashtagstemplate.html',false);
    //req.open('GET',
    //"https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.html?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMyJGMEQCIBYg2vL8yhlf3XNsjBq3UTFpi9UXet8po6Jd7GXl1LysAiAf0%2BOt16xmkxasi7pKy7ZfZcSjjPFk4hvx%2BgQ0Vj68RirtAgi%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDY5OTc1ODU4MjI5NiIMxV48Lt0GJNV7KWlHKsECa2rB4z%2BxnKzN4zNab4lMDuhOOBmNDmiSLjDj3E56wORxEL43ijQGN8cKhgQgzLiovsZg2oghztV2I7SaoAe4V4iF4as7Fep%2FbY3%2BK8ZXSAdBmyiF29j98G%2BHJz3BsO%2F%2FtIeepwP5LRL17jbY3UrBKYoM4am9P2FhDybVje%2BcR5ESyXrYTG4c%2BN8CTMSeoXTL14rOKvRBkzVBfe0BgAayp53TBm0bvZvuBoq42MdFaGGfXk8VKnpqIpai0QhLdp8ewMdvRdrivVjVsCKVhsMPWNyvrQpHcxesnS6%2B3nFIGuJXOyNmv6aBl2K5GKSZ7sl1zduX%2B%2F4SHe15n0p72mHjWDS597OQ%2Bg%2BS3hxlgIVYwlus1rRDoRBeensivOZq4v98kKyxdrlPNRKHFWlg8DqpCgpWgZ5R5ltR%2Ff9tp7zvMwjJMKK5w5QGOrQCcjrA%2B7j%2BwcoNNQs%2BZaA94hjfOQvHXLjGZcUcVgNxmTp7va2eDsvHRSd3fxnWRr7XWF0Y5fCfP3XfQ%2B4Hbh89blniGppDXmBlTAsdCHLs2VkR669ndOrtKC6bPgNVZM3mGT1EQmgEVqXt7aZ4lr6%2BSwwTIvbekBRKCeB0b0%2F8MD9raSbK%2FvlWe6Qwjbh3NLVTQXuuUt8bUyUxjlNuyZdVeGFpyaCjm0%2BBedJ%2BVMKc68Yd8OkyNiSerkC0uqwZ7S3tmtjJ9b9EYBsnvAAmBNry9v2WOZHIIPCf87krcvSf4CsHi0UPi4soKCaDy796%2Bvx07%2B5EEZhyMj87w9rep8s8%2F6nNSMYGaIGs9v7ZWj%2BEVU8xINVM3wDMoQd87bAu4BwvvRW3R8lo04lO%2FEIEPi7%2FTMRJyVI%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220527T143619Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIA2F3G4TIML2ZAZKVC%2F20220527%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Signature=f8b8432ca4755c58e569ae30abcde8ae0140b7f1471ae89daeb0221ad3dbe38f"
    //,false);

    //var gapi=require('gapi');
    //gapi.server.load('plus','v1',function(){});
    //var request=gapi.server.plus.people.get({});
    //req.setRequestHeader('Access-Control-Allow-Origin','*');
    req.send(null);
    
    //console.log("REQUEST:",req.responseText);
    //const jsonData = document.querySelector('.json-data');
    dataJsonFromAWS = JSON.parse(req.responseText);

    console.log("JSON ES=",dataJsonFromAWS)
}

//FAILED

// async function fakeDataInit3(){

//     console.log("FAKEDATA fakeDataInit3");
//     //const googledrive=require('@googleapis/drive');
//     //const {google}= require('googleapis');
    
//     const auth = new google.auth.GoogleAuth({
//         keyFile: './assets/hashtagstemplate.json',
//         // Scopes can be specified either as an array or as a single, space-delimited string.
//         scopes: [
//             'https://www.googleapis.com/auth/drive.readonly',
//           ],
//       });

//       const drive=google.drive({
//           version: 'v1',
//           auth: auth
//       });

//     //  const drive=googledrive.drive({
//     //      version: "v3",
//     //      auth: auth
//     //  })

//       const res= await drive.files.get({
//           fileId: fileJsonid
//       })
      
//       console.log(res.data);

 
    
    
//}

//FAILED
// function loadJsonFromFakeImage(){
//     console.log("FAKEDATA loadJsonFromFakeImage");
//     var fakeimg=document.getElementById("hashtagsTemplate");
//     console.log(fakeimg);
// }

//fakeDataInit();
fakeDataInit2();
//fakeDataInit3();
//loadJsonFromFakeImage();

export const userAdressidList=[

//"0x46a7bd7B1A92cB6ddbC7aB8ebFc608471187D1ed",
//"0x570d0c26Be47312F3648E5fe27692803f8fD8ac6",
"0x8A1E4F5203550E7115cC1672B6B6Db5e14DAbbA3",
//"0x224fb42C5bA622E26BA1F69babba2506d6e1dd78",
"0x2a238A2C3f7e8B41B9a1f85c07374eA897A1DD4e",
"0x54d0997F5E192bC641a60b34fC95947e116Fe6d5",
"0x76e95eA01D73765C45117A8f9489fc557cE9002A",
"0x10998a005EbCA52F82A6bd722cC55b85fF42a629",
"0xc2BfcCB6279f65A1279121fC8C24838FB4A9153E",
"0x473a0be988f9100Dcc4Fe6520AB1FBa2F169A78c",
"0x281AAc9e7B2d948253F42Be186a6b6CEF4bDa8C5",
"0x1e2f7c50d36324467FF98Bf29507752f5cC8be5b",
"0x2944FaE3228DBA6a7D10145287893Fd89c5eF1A7",
"0x1cff9d7fF0d554500550C97d0d3399bC8D0e7be1",
"0x155A85F50b57f865a90832f65d8f416A5B1674C6",
"0xA9aB1B64E076211fC4C9f5E46Ad943E7A2B6907E",
"0xaac657A49C0d69a8b7CA3A4b9836A55E53d0D596",
"0x262da5f1a2E3Ce21d4c328808d5dcca4DBb08dc0",
"0x1465014386d7174885807A89617B3B0f3772EB39",
//"0xC4C08F1345FbCA0948f23e26Cd79c01A86256FBe"

]
/*
const textGameHashTagList=
"brasil #eua #policy #politica #melhorjornal #globolixo #compromissocomaverdade #noticias #familia #foco"+
"#stayhome #ficaemcasaum #joelma #babados #famosos #news #fotografia #world #music #fofocas"+
"#women #strong #photoshoot #singer #brazil #pic #focusjoelmaaquenolosabias_ #sabiasque #curiosidades #curioso"+
"#interesante #sabíasque #datocurioso #conocimiento #datoscuriosos #curiosidad #datos #impresionante #salud #ciencia"+
"#mexico #sabelotodo #asombroso #dato #libros #noticia #datointeresante #humor #argentinao #ficaemcasaas"+
"#orlando #orlandobloom #enceinte #pregnant #felicitations #congratulations #soon #babySo #lauramercier #products"+
"#highend #ulta #ultabeauty #explorepage #beautiful #flatlaystyle #makeupideas #makeupjunkies #beautyinfluencer #beautytips"+
"#beautygirl #slave2beauty #slave2makeup #blushes #primers #foundations #loosepowder #lipsticks #eyepencils #highlighter"+
"#news #brush #mascaraI’m #wedding #party #weddingparty #toptags #celebration #bride #groom"+
"#bridesmaids #happy #happiness #unforgettable #love #forever #weddingdress #weddinggown #weddingcake #family"+
"#smiles #together #ceremony #romance #marriage #weddingday #flowers #celebrate #instawed #instawedding"+
"#baggerlife #baggerlovers #motorcycle #bike #michelinmotorcycle #bikelife #harley #harleynation #harleylovers #harleylife"+
"#cats_of_instagram #cats_of_day #catsoninstagram #catstagram #cat_features #catsofday #catsgram #catworld #catsoftheworld #catloversclub"+
"#seriesdetv #seriedetv #serie #series #melhorserie #viciadosemseries #amoseries #tvshow #tvshogeliw #maratonadeseries"+
"#Spotify seeing green over recent logo change? #SpotifyGreen"+
"Microsoft Holds A Live Event To Showcase The New Ideas Of #Windows10"+
"Justin Bieber Gets Social Media Back On His Side With His #MyCalvins Advertisement"+
"Microsoft's #WindowsPhone Remains In Constant Flux Among Hashtag Trends"+
"Fans Have Long Awaited For The Release of #Halo - The Master Chief Collection"+
"The #MarvelEvent So Big It Has Fans Writhing In Unbearable Excitement"+
"What Won't Change About the #iPhone - It's Trending Popularity"+
"Apple Announces New Products at #AppleLive"+
"#ShakeItOff Proves That Music Promotion is Alive and Well on Twitter"+
"Burger King's #TBT: The #SubservientChicken Returns for 10-year Anniversary"+
"#AmazingBabyRescueMe: How Evian Used The Hashtag To Its Advantage"+
"Tyson Knows How Hashtags Trend. See Why #TysonGlutenFree Worked"+
"#ActionTags Bring Consumers Closer To Brands"+
"#SharkWeek 2013 Presents #Sharkpocalypse"
*/

// const textGameHashTagList2={
//     "current":"type1",
//     "type1": [
//         "Blockchain",
//         "BxCAT2022",
//         "Ethereum",
//         "Crypto",
//         "NFT",
//         "Girona",
//         "Catalunya",
//         "Metavers",
//         "Catvers",
//         "D4A2022",
//         "Bitcoin",
//         "GotocryptoGroup",
//         "CBCAT",
//         "GreenDataChain",
//         "Freeverse",
//         "TheBreadStudios",
//         "BigOnion",
//         "Venca",
//         "MediaPro",
//         "Invelon",
//         "Inlea",
//         "BlockChainxODS",
//         "Plastiks",
//         "HermetzNetworks",
//         "HP"

//     ],
//     "type2":[
//         "none"
//     ]
// }

//const textGameHashTagList2=importHashtags;
const textGameHashTagList2=dataJsonFromAWS;
console.log("JSON PARA REACT=",textGameHashTagList2);

function textGameHashTagList(){
    
    let list=textGameHashTagList2[textGameHashTagList2.current];
    let txtGame="";
    for(var t of list){
        txtGame+="#"+t+" "
    }

    return txtGame;
}




const Locationlist=
    "Paris, France#"+
    "Barcelona, Spain#"+
    "Rome, Italy#"+
    "London, UK#"+
    "New Dheli, India#"+
    "Buenos Aires, Argentina#"+
    "Chicago, USA#"+
    "Toronto, Canada#"+
    "Berlin, Germany#"+
    "Essen, Germany#"+
    "Springfield, USA#"+
    "Cairo, Egypt#"+
    "Being, China#"+
    "Sydney, Australia#"+
    "Hawai, USA#"+
    "Lima, Peru#"+
    "Chiclayo, Peru#"+
    "New York, USA#"+
    "Kiev, Ukraine"

export function getLocation(){
    var locationl=Locationlist.split("#");
    var rand=Math.round(Math.random()*(locationl.length-1));
    var loc=locationl[rand]
    console.log("GET_LOCATION=",loc);
    return loc;

}

export function getgameText(){
    var numOfHastTags=Math.round((Math.random()*8.0)+1.0);
    var txtgame="";
    var textGameHashTag=textGameHashTagList.split("#");
    console.log("HASHTAGLIST",textGameHashTag);
    console.log("NUMOFHASHTAGS=",numOfHastTags);
    for(var i=0;i<numOfHastTags;i++){
        var rand=Math.round(Math.random()*(textGameHashTag.length-1));
        console.log("RAND=",rand);
        var txt=textGameHashTag[rand].split(" ")[0].split(",")[0].trim();
        console.log("txt:{"+txt+"}");
        var htg="#"+txt+" ";

        if(htg!="# "){
            txtgame+=htg; 
        }else{
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
        }
    }
    console.log("textGame=",txtgame);
    return txtgame;
}

export function getuserAddressId(){
    var rand=Math.round(Math.random()*(userAdressidList.length-1));
    console.log("USERADDRESSID RAND=",userAdressidList[rand]);
    return userAdressidList[rand];
}
export function get18HashTags2(){
    var hashtagList=[];
    var numOfHastTags= mode.NumHashtags;
    //var txtgame="";
    var txtAux=textGameHashTagList();
    console.log("--------------------> TXT AUX :",txtAux);
    var textGameHashTag=txtAux.split("#");
    console.log("HASHTAGLIST",textGameHashTag);
    console.log("NUMOFHASHTAGS=",numOfHastTags);
    for(var i = 0 ; i < numOfHastTags ; i++){
        let select = false;
        while( !select ){
            var rand = Math.round( Math.random() * (textGameHashTag.length - 1) );
            console.log("RAND=",rand);
            var txt = textGameHashTag[rand].split(" ")[0].split(",")[0].trim();
            
            console.log("hashtagList.indexOf(\"#"+txt+" \")=",hashtagList.indexOf("#"+txt+" "));
            if(hashtagList.indexOf("#"+txt+" ") === -1){
                select = true;
                console.log("NO EXISTE!",select);
            }
        }
        console.log("txt:{"+txt+"}");
        var htg="#"+txt+" ";

        if(htg!=="# "){
            //txtgame+=htg;
            hashtagList.push(htg) 
        }else{
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
        }
    }
    console.log("hashTagListArray=",hashtagList);
    return hashtagList;
}

export function get18HashTags(){
    var hashtagList=[];
    var numOfHastTags = mode.NumHashtags;
    
    var textGameHashTag=textGameHashTagList2[textGameHashTagList2.current];
   
   
    console.log("HASHTAGLIST",textGameHashTag);
    console.log("NUMOFHASHTAGS=",numOfHastTags);
    console.log("------------------------EL JSON ES",JSON.stringify(textGameHashTagList2));
   
    for(var i = 0 ; i < numOfHastTags ; i++){
        let select = false;
        while( !select ){
            var rand = Math.round( Math.random() * (textGameHashTag.length-1) );
            //console.log("RAND=",rand);
            var txt = textGameHashTag[rand];
            
            var htg="#"+txt+" ";
            
            //console.log("hashtagList.indexOf("+htg+")=",hashtagList.indexOf(htg));
            if(hashtagList.indexOf(htg) === -1){
                select = true;
                //console.log("NO EXISTE!",select);
            }
        }
        //console.log("htg:{"+htg+"}");
        

        if(htg!=="# "){
            //txtgame+=htg;
            hashtagList.push(htg) 
        }else{
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
            console.log("ALEERT!!! HASHTAG BOMBA!!!!");
        }
    }
    //console.log("hashTagListArray=",hashtagList);
    return hashtagList;
}






