import React from "react";
import { Container, Grid } from "semantic-ui-react";
import TotalsStatisticsBox from "./MetricsCards/TotalsStatisticsBox";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";

import { getConnectionDatainWallet,getUserDatainWallet} from "../../../services/userWallet";



const TotalsStatistics = () => {

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
 

  

 const[totalPlanets,setTotalPlanets] = useState(0);
  //var totalPlanets = metricsState.metricsFilters.planetids.lenght;
 
  const[percentagePositive,setPercentagePositive] = useState(0);
  
  const[percentagePositiveString,setPercentagePositiveString] = useState("0 %");
  const[totalPublic,setTotalPublic] = useState(0);
  //var totalPublic = 0;
  
  const[totalMinted,setTotalMinted] = useState(0);
  //var totalMinted = 0;
 
   

  useEffect(()=>{
    let userData=getUserDatainWallet();
    
    if(metricsState.metricResults && userData.contractEmotionyAsset){
      
      if(userData && userData.contractEmotionyAsset){
      console.log("TOTALSTATISCTICS: GETUSERDATA-in-WALLET",userData)
      // console.log(" >>>>>>>>>> GET TOKENS FROM CONTRACT CALL >>>>>>>>> ");
      // GET TOKENS OF OWNER (MAINACCOUNT) (CONTRACT CALL)
      userData.contractEmotionyAsset().methods.getTokensOfOwner(userData.userAddressId).call
        ((err, arrayResult) => {
          if (err) { console.log("ERROR!!!", err); }
          // console.log("GET TOKENS OF OWNER: ", userData.userAddressId, "IS:", arrayResult);

          // SET TO BLOCKCHAIN ARRAYTOKENS
          userData.tokensOfOwner.owner = userData.userAddressId;

          // GET INDIVIDUAL VALUES OF ARRAYLIST TOKENS OF OWNER (MAINACCOUNT)
          if (arrayResult) {
            //console.log("GET TOKENS OF OWNER:",userData.userAddressId," IS arrayResult:",arrayResult);
            setTotalMinted(arrayResult.length);

          }
        });
      }
      console.log("TOTALSTATISCTICS: METRICS RESULT IS CHANGING",metricsState.metricResults);
      setTotalPlanets(metricsState.metricResults.TotalGames);
      setPercentagePositive(Math.floor(metricsState.metricResults.Polarity_Positive));
      setPercentagePositiveString(Math.floor(metricsState.metricResults.Polarity_Positive).toString() + " %");
      setTotalPublic(0);
      
    }
},[metricsState.metricResults]);
 
 

  

  return (
    <>
      <Container
        style={{
          paddingLeft: "20px"
        }}
      >
        <Grid columns={4}>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <TotalsStatisticsBox
              content="total games played"
              value={totalPlanets}
              colorRibbon="purple"
              dataRibbon="PLANETS"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <TotalsStatisticsBox
              content="positive emotions over total"
              value={percentagePositiveString}
              colorRibbon="black"
              dataRibbon="POSITIVE"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <TotalsStatisticsBox
              content="total planets minted"
              value={totalMinted}
              colorRibbon="grey"
              dataRibbon="PLANETS MINTED"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <TotalsStatisticsBox
              content="total games on public"
              value={totalPublic}
              colorRibbon="blue"
              dataRibbon="ON PUBLIC"
            />
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
};
export default TotalsStatistics;
