import { useEffect, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { Button, Input } from 'semantic-ui-react';
import ToggleButton from 'react-toggle-button'

import {LinearProgress} from '@mui/material';
import { Image } from "semantic-ui-react";

var count=0;
var timeDeciSeconds=80; // NUM COMPROBATIONS CATCH IMAGE
var lapseTimeTop=4; // LAPSE IN DECISECONDS FOR NEW CATCH COMPROBATION
var iSetNotFoundImage;

var iwengine;
var percentageLoadImage=0;
var iSetPercentageLoaded;
var iSetFinishGame;
var srcWithoutQuote;
var srcWithQuote;
var iSetSrcImage;


console.log("IMAGE-COMPONENT-MODULE-INIT");

export const IMAGE = ({
  src,
  alt_txt,
  width,
  notFoundImage,
  setNotFoundImage,
  wengine,
  finishGame,
  setFinishGame
}) => {

  console.log("---IMAGE-COMPONENT-INIT---");

  const [loaded, setLoaded] = useState(false);
  const [percentageLoaded,setpercentageLoaded] = useState(0);
  const [toogle,setToogle]=useState(false);
  const [srcImage,setSrcImage]=useState(src);

  iSetNotFoundImage=setNotFoundImage;
  iSetPercentageLoaded=setpercentageLoaded;
  iSetFinishGame=setFinishGame;
  iSetSrcImage=setSrcImage;
    
  iwengine=wengine;
      
  console.log("IMAGE SRC=",src);

  // const toogleFunction=(event)=>{ 
  //   setToogle(!toogle);
  //   if(toogle===false) setSrcImage(srcWithoutQuote)
  //   else setSrcImage(srcWithQuote)
  // }

  useEffect(()=>{
    console.log("CHANGE NOTFOUND IMAGE",notFoundImage)
    console.log("LOADED IMAGE=",loaded);
  })

  return (
    <>
      {
        notFoundImage?
          <div>
            <p>Oops, something wrong has happen :(</p>
          </div>
          :
          <>
            {(percentageLoaded>0 && percentageLoaded<100)?
              <> 
                <LinearProgress 
                  variant="determinate" 
                  value={percentageLoaded} 
                />
                {true &&
                  <div>
                    <p>Discovering your planet...</p>
                  </div>
                }
              </>
              :null
            }
              
            <Image 
              fluid
              src={srcImage}
              alt={alt_txt}
              onLoad={(data,err) => {
                if(data) {
                  console.log("ONLOAD DATA",data);
                  console.log("LOAD IMAGE = TRUE");
                  iSetNotFoundImage(false)
                  setLoaded(true);
                }
                if(err) {
                  console.log("ONLOAD ERROR",err);
                  iSetNotFoundImage(true);
                  setLoaded(false);
                }
              }
            }/>
            {/* {  loaded && !(percentageLoaded>0 && percentageLoaded<100)? 
            <div>
            <ToggleButton
              inactiveLabel={'Quot'}
              activeLabel={''}
              colors={{
                activeThumb: {
                  base: 'rgb(50,50,50)',
                },
                inactiveThumb: {
                  base: 'rgb(62,30,77)',
                },
                active: {
                  base: 'rgb(62,30,77)',
                  hover: 'rgb(92,70,127)',
                },
                inactive: {
                  base: 'rgb(65,66,68)',
                  hover: 'rgb(95,96,98)',
                }
              }}
              value={toogle}
              onToggle={toogleFunction} />
            </div>:null
          } */}
        </>
      }
    </>
  );
};

export function requestImgCatchComprobation(url,dataImg){
    
  var request;
  var notfound=true;
    
  request = new XMLHttpRequest();
  request.open('GET', url, false);
  request.send();

  if (request.status === 404) {
    notfound=true;
    count++;
    console.log(count,timeDeciSeconds/lapseTimeTop,"NOT FOUND IMAGE");
    console.log("DATA",request)
    if(count>=timeDeciSeconds/lapseTimeTop){
      count=0;
      
      console.log("SERVER PROBABLY IS DOWN!");
      iSetNotFoundImage(true);
      iwengine.w.close(); 
      notfound=true;
    }    
                
  } else{
    if(count>=timeDeciSeconds/lapseTimeTop){
      count=0;
      
      console.log("SERVER PROBABLY IS DOWN!");
      iSetNotFoundImage(true);
      iwengine.w.close(); 
      notfound=true;
    } else{
      notfound=false;
      count=0;
    }      
  }

  return notfound;         
}

export function ImgCatchImage(setImgPlanet,imgurlaux){
  var imgurl=imgurlaux.split(".png")[0]+"p.png";
  srcWithoutQuote=imgurlaux;
  srcWithQuote=imgurl;
    
  iSetNotFoundImage(false);
    
  console.log("INIT CATCHING IMAGE ImgCatchImage from URL=",imgurl,"AWAITING "+timeDeciSeconds/10+" SECONDS");
    
  let interval=null;
  let deciSeconds=0;
    
  let lapseTimeCount=0;
  let requestComprobation=false
  
  interval = setInterval(function() {
    deciSeconds++;
    iSetPercentageLoaded(deciSeconds/timeDeciSeconds*100);

    lapseTimeCount++;
    console.log("DECISECONDS INCREMENT",deciSeconds);
   
    if(deciSeconds===timeDeciSeconds){
      clearInterval(interval);
      console.log(deciSeconds/10+" SECONDS");
    }

    if(lapseTimeCount===lapseTimeTop){
      lapseTimeCount=0;
      console.log("REQUEST CATCH IMAGE");
      var imgrequest;
      requestComprobation=requestComprobation=requestImgCatchComprobation(imgurl,imgrequest);

      if(!requestComprobation) {
        console.log(deciSeconds/10+" SECONDS");
        console.log("CATCHING TRUE!!!!!!! = ",imgurl);
        deciSeconds=0;
        clearInterval(interval);
        setImgPlanet(imgurl);
        //iSetSrcImage(srcWithQuote);
        iSetSrcImage(srcWithoutQuote);
        
        // SET PERCENTAGE LOADED TO 0
        iSetPercentageLoaded(99);
        iSetPercentageLoaded(100);

        iwengine.w.close();
      }
    }
  }, 100);
}