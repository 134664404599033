import "./Marketplace.scss";
import MarketPlaceHeader from "../../components/MarketPlace/MarketPlaceHeader";
import { userData, userService } from "../../services/userParams";
import React, { useEffect, useState, useContext } from "react";
import MarketplaceComponent from "../../components/MarketPlace/MarketPlaceComponent";
import { EmoContext } from "../../services/globalContext";
import { Grid, Segment } from "semantic-ui-react";
import { AllFiltersComponent } from "../../components/Filters/allFiltersComponent";


console.log("MARKETPLACE-PAGE-MODULE-INIT");

const Marketplace = () => {
  console.log("---MARKETPLACE PAGE INIT---");
  const [userDataArray, setUser] = useState(userData);
  const [globalState, dispatchGlobal] = useContext(EmoContext);

  var [dataToFilters,setDataToFilters] = useState([]);
  var [submitFilter,setSubmitFilter] = useState(null)

   

  console.log("MARKETPLACE PAGE,GLOBALSTATE", globalState);
  //console.log("MARKETPLACE PAGE,DISPATCHGLOBAL",dispatchGlobal);
  console.log("MARKETPLACE PAGE, USERDATA", userDataArray);


  useEffect(() => {
    window.scrollTo(0, 0);

    if (sessionStorage.getItem("address")) {
      userService.setUser(null);
      userService.setUser(sessionStorage.getItem("address"));
      setUser(userData);
    }
  }, []);

  useEffect(()=>{
    if(submitFilter){
      console.log("MARKETPLACE PAGE: SUBMIT FILTER:");
      console.log(submitFilter);
    }
  },[submitFilter]);

  return (
    <div className="marketplace">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <MarketPlaceHeader userData={userDataArray} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="left">
              < AllFiltersComponent 
                titleHeader = "MarketPlace Filters" 
                filterData = {dataToFilters}
                submitFilter = {submitFilter}
                setSubmitFilter = {setSubmitFilter}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MarketplaceComponent userData={userDataArray} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Marketplace;
