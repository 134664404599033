import { React, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import { map } from "lodash";
import Web3 from "web3";

import { metamask, setReducer } from "../services/userWallet";
//import { setUser, userDefault } from "../services/userParams";
import { UserContext} from "../services/userContext";
import {userDataDefault} from "../services/userDefault";
import { showVars } from "../core/initVarsAndFunctions";
//import { blockchainManager } from "../core/blockchain/blockchainManager";

console.log("NAVIGATION-MODULE-INIT");

//blockchainManager();


const Navigation = () => {
  console.log("------------NAVIGATION INIT----------");
  //let userId = undefined;
  let _userId = undefined;
  const [userState, dispatchUser] = useContext(UserContext);

  
  //console.log("NAVIGATION USERSTATE",userState);
  //console.log("NAVIGATION GETUSERSTATEFROMSTORAGE",userState.getUserStateFromStorage());
   
  // SET REDUCER IN USERWALLET
  const _reducer={userState:userState,dispatchUser:dispatchUser}
  setReducer(_reducer);

  // CONNECT TO WEB3
  const connect = async () => {
    var web3 = window.web3; // BlockChainConnection
    return await web3.eth.getAccounts(); // get accounts from the blockchain provider
  };
  const goToLogin = (props, route) => {
    //GET ADDRESS FROM STORAGE
    console.log("----GOTOLOGIN----");
    if(userState && userState.getUserStateFromStorage){
      console.log("userStateFromStorage",userState.getUserStateFromStorage());
    }else{
      console.log("NOT USER STATE FROM STORAGE");
    }
    
   
    const _address = userState.userData.userAddressId;
     //const _address = userState.getUserStateFromStorage().userData.userAddressId;
    
    //const address = sessionStorage.getItem('address');
    //IF EXISTS, CLEAN TO INITIAL STATE
    if (_address) {
      //setUser(userDefault);
      dispatchUser({type:"SET_INITIAL_STATE", payload:userDataDefault()});
    }
    // REMOVE ADDRESS FROM THE STORAGE
    //sessionStorage.removeItem("address");
    dispatchUser({type:"SET_USER_ADDRESS_ID", payload:null});
    if (route.path !== "/login" && route.AuthRequired === true) {
      console.log("AUTH=TRUE, REQUIRE LOGIN")
      props.history.push("/login");
    }
  };

  // don't allow correct logout
  // conflict with logout mechanism

  //window.location.reloadGame = false;
  const render = (props, route) => {

    console.log("\n\n\n\n---------Navigation ROUTING---------");
    console.log("\n\n<<<<--ROUTING-->>>>", props, route);
    console.log(showVars());
    console.log("<<<<--ROUTING-->>>>\n\n");
    // if (route.path === "/" && window.location.reloadGame === true) {
    //   window.location.reload();
    //   window.location.reloadGame = false;
    // } else {
    //   window.location.reloadGame = true;
    // }

    //userId = sessionStorage.getItem('address');
    //_userId = userState.getUserStateFromStorage().userData.userAddressId;
    _userId = userState.userData.userAddressId;
    console.log("USER ID=",_userId)

    if (window.ethereum && _userId) {
      window.web3 = new Web3(window.ethereum);
      connect().then((e) => {
        console.log("NAVIGATION: CONNECT().then(e)=>", e);

        if (e.length > 0) {
         
          
          metamask(props,_reducer);
        } else {
          //userId = undefined;
          _userId = undefined;
          console.log("USERID=UNDEFINED");
          goToLogin(props, route);
        }
      });
    } else {
      console.log("NOT window.ethereum && _userId");
      goToLogin(props, route);
    }

    return route.AuthRequired === false ||
      //(route.AuthRequired === true && userId) ||
      (route.AuthRequired === true && _userId) ||
      route.path === "/login" ||
      route.path === "/" ? (
      <route.layout>
        <route.component {...props} />
      </route.layout>
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: props.location,
          },
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        {map(routes, (route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => render(props, route)}
          />
        ))}
      </Switch>
    </Router>
  );
};
export default Navigation;