const axios = require("axios");
const FormData = require("form-data");
const pinataApiKey = "88a562986c180faf2121";
const pinataSecretApiKey = "4b9c6b116e107f73770c49b80f1d41d8e9620579aec7e9e2983afef7b43a4970";
const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
const urlJson = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
var blob; //Blob whith PNG; 

console.log("CHARGEIMAGETPOIPFSWITHAXIOS-MODULE-INIT");

export async function chargeAvatartoIPFS(formData) {//,name){

  console.log("---CHARGEAVATARTPOIPFS-INIT---");
  
  var urlResponse = "";
  /*await axios({
    url: file,
    method: 'GET',
    responseType: "blob",
  }).then((response)=>{
    blob=new Blob([response.data],{type:"image/png"});
    let data = new FormData();
    data.append('file',blob,name);    
    //console.log(data);
    */
  await axios.post(url, formData, {
    maxContentLength: "Infinity",
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      'pinata_api_key': pinataApiKey,
      'pinata_secret_api_key': pinataSecretApiKey,
    }
  }
  ).then(function (response) {
    urlResponse = 'https://ipfs.io/ipfs/' + response.data.IpfsHash
    //console.log("AVATAR TO IPFS-------->", response.data);
    //console.log("AVATAR TO IPFS--------> URL=", urlResponse);

  }).catch(function (error) {
    console.log(error);

    // });

  });
  return urlResponse;

}

export function chargeImagetoIpfsPinataAccountWithAxios(uri) {
  console.log("---CHARGEIMAGETOIPFSPINATACCOUNTWITHAXIOS-INIT---");

  axios({
    url: uri,
    method: 'GET',
    responseType: "blob",
  }).then((response) => {
    blob = new Blob([response.data], { type: "image/png" });
    //console.log("BLOB",blob);
    pinPNGToIPFS(uri, createJSONtoPIN);
  })

}



const pinPNGToIPFS = async (name) => {
  let data = new FormData();
  //data.append('file', fs.createReadStream('exampleNFT.png'));
  //data.append('file', chargeImage("exampleNFT.png"));
  //data.append('file',{uri: "exampleNFT.png",type: "image/png"});
  data.append('file', blob, name);
  //console.log(data);
  return axios.post(url, data, {
    maxContentLength: "Infinity",
    headers: {
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      'pinata_api_key': pinataApiKey,
      'pinata_secret_api_key': pinataSecretApiKey,
    }
  }
  ).then(function (response) {
    //console.log(response.data);
    createJSONtoPIN(name, response.data);

  }).catch(function (error) {
    console.log(error);

  });


};

function createJSONtoPIN(nameJson, dataToCreateJson) {
  //console.log(dataToCreateJson.IpfsHash);
  var jsonMaker = {
    "name": "Emotiony NFT" + nameJson,
    "hash": dataToCreateJson.IpfsHash,
    "by": "Emotiony"
  }

  var jsonMetaMaker = {
    "pinataMetadata": {
      "name": nameJson + ".json"
    },
    "pinataContent": jsonMaker
  }

  //console.log("JSON WITH METAMAKER", jsonMetaMaker);
  pinJSONtoIPFS(jsonMetaMaker);

}



const pinJSONtoIPFS = (json) => {
  return axios.post(urlJson, json, {
    headers: {
      'pinata_api_key': pinataApiKey,
      'pinata_secret_api_key': pinataSecretApiKey,
    }
  }).then(function (response) {
    //console.log("JSON", response.data);


  }).catch(function (error) {
    console.log(error);

  });
}