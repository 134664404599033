import { React, useReducer } from "react";
import Navigation from "./routes/Navigation";

import { EmoContext, emoInitialState } from "./services/globalContext";
import { emoReducer } from "./services/globalReducer.js";
import { userReducer } from "./services/userReducer";
import { UserContext, userInitialState } from "./services/userContext";
import { showVars } from "./core/initVarsAndFunctions";



/**
 * App main function.
 *
 * @param {void} none
 * @return {void} none
 */
console.log("APP-MODULE-INIT");

export default function App() {
  console.log("\n\n-----App----");
  console.log(showVars());
  console.log("-----App----\n\n");

  var emoinitial = emoInitialState;
  var userinitial = userInitialState;

  if(sessionStorage.getItem("globalState"))
  {
    var storageGlobalState=JSON.parse(sessionStorage.getItem("globalState"));
    console.log("GLOBAL STATE PRESENT",storageGlobalState);
    emoinitial=storageGlobalState;
    
  }

  if(sessionStorage.getItem("userState"))
  {
    var storageUserState=JSON.parse(sessionStorage.getItem("userState"));  
    console.log("USER STATE PRESENT",storageUserState);
    userinitial=storageUserState;
  }
  

  const [globalState, dispatchGlobal] = useReducer(emoReducer, 
    emoinitial);
  const [userState, dispatchUser] = useReducer(userReducer, 
    userinitial);

  return (
    <EmoContext.Provider value={[globalState, dispatchGlobal]}>
      <UserContext.Provider value={[userState, dispatchUser]}>
        <Navigation />
      </UserContext.Provider>
    </EmoContext.Provider>
  );
}

