

console.log("USERDEFAULT-MODULE-INIT");

export const userDataDefault= () =>{return({
    noAvatar: "https://seekicon.com/free-icon-download/header-user_1.svg",
    items: [],
    nextTokensHeader:[],
    nextTokens:[],
    pages:[],
    isSetItemsHeaders : false,
    payxdata: false,
    hashxmatic: false,
    balance: 0,
    isConfigNetWorkId: false,
    connectionData: {
      config: {
        networkId: undefined,
        contract_address: undefined,    
        tokenImage: undefined,
        pinataApiKey : undefined,
        pinataSecretApiKey : undefined,
        url : undefined,
        urlJson : undefined,
        emotionyApp1S3Planets : undefined
      }, 
      chainNetWorkInfoJson: undefined,
      configNetWork: undefined,
      connectedBlockchain : false
    },
    tokensOfOwner: {
      owner: "",
      tokens: [],
    },
    contractEmotionyAsset: undefined,
    contractCowSail:undefined,
    estimateGasCost: undefined,
    userAddressId: undefined,
    user: undefined,
    joined: "1/Jan/2021",
    avatarLink: "",
    backgroundImageLink: "",
    userDescription: "",
    publicProfile: false,
    myGalaxyName: "",
    hasWindowEthereum: false,
    accounts: [],
    logging: undefined,
   
  })};