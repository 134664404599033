/**
 * 
 * This function gets Geolocation with params using XMLHttpRequest
 * from the ip-api endpoint URL
 *  XX http://ip-api.com/batch?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,reverse,mobile,proxy,query XX CORS PROBLEM
 * http://ip-api.com/json/{ip}?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,reverse,mobile,proxy,query
 * 
 */


import jsonp from 'jsonp'

console.log("GEOLOCATION-COMPONENT-MODULE-INIT");

export function getGeolocation(queryIp){

    console.log("---GEOLOCATION-COMPONENT-INIT---");

    var request;
    var geolocation={};
    
    //var endpoint="http://ip-api.com/batch?";
    var endpoint="http://ip-api.com/json/";
    var fields="status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,mobile,proxy,hosting,query";
    //var lang="en";
    //var IPs=[{"query":queryIp,"fields":fields,"lang":lang}];
    //var data=JSON.stringify(IPs);
    var queryGet=endpoint+queryIp+"?fields="+fields;
    request = new XMLHttpRequest();
    //request.setRequestHeader('Access-Control-Allow-Origin', '*');
    //request.open('POST', endpoint, false);
    //request.send(data);
    request.open('GET', queryGet, false);
    
    console.log("GET GEOLOCATION: QUERY:",queryGet);
    request.send();
    if(request.status===200){
        geolocation=JSON.parse(request.responseText);
    }else{
        console.log("ERROR GETTING GEOLOCATION",request);
    }
    
    console.log("GET GEOLOCATION RESULT",geolocation);
    
    return JSON.stringify(geolocation);
}

export function getGeolocation_jsonp(geolocation_jsonp){

    console.log("---GEOLOCATION-JSONP-COMPONENT-INIT---");
   
    var endpoint="http://ip-api.com/json/";
    var fields="status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,mobile,proxy,hosting,query";
    var uri=endpoint+"?fields="+fields;//+queryIp;//+"?fields="+fields;
    if(!geolocation_jsonp.setGeoloc){
    jsonp(  uri,
            function(err,data){
                if(err){
                    console.log("GEOLOCATION CALLBACK ERR",err)
                    geolocation_jsonp.err=err;
                }else{ 
                    console.log("GEOLOCATION CALLBACK DATA",data)
                    geolocation_jsonp.data=JSON.stringify(data);
                    geolocation_jsonp.setGeoloc=true;
                    console.log("GEOLOCATION CALLBACK DATA",geolocation_jsonp.data)
 
                }
            },
            function(err,data){
                if(err){
                    console.log("ERROR GETTING GEOLOCATION",err);
                }else{
                    console.log("GET GEOLOCATION RESULT",data);
                }
            }
        )
    }
    
    
}