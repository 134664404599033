import { Segment, Grid } from "semantic-ui-react";
import "./planetDetail.scss";

import { useEffect, useState, useContext } from 'react';
import { EmoContext } from "../../services/globalContext";

import DataPlanetDetail from "../../components/PlanetDetail/DataPlanetDetail";
import HeaderPlanetDetail from '../../components/PlanetDetail/HeaderPlanetDetail';

import PlanetImage from "../../components/PlanetDetail/ImagePlanetDetail";
import { getGameById } from '../../services/Graphql/Game/queryGame.js';
import Tokenize from '../../components/PlanetDetail/UserPlanetDetail/TokenizePlanet';

import Publish from '../../components/PlanetDetail/UserPlanetDetail/PublishPlanet';
import EList from "../../components/PlanetDetail/UserPlanetDetail/EmotionList";
import { userData } from '../../services/userParams';
console.log("PLANET-DETAIL-PAGE-MODULE-INIT");

const PlanetDetail = (props) => {

  console.log("--PLANET DETAIL PAGE INIT--");
  
  const [globalState, dispatchGlobal] = useContext(EmoContext);
  var [planetId, setPlanetId]=useState(null);
  var [owner, setOwner] = useState(false);
  const setOwnerState = (data) => {
    console.log("SETOWNERSTATE:",data);
    if (userData.accounts && data && data.userAddressId === userData.accounts[0]) {
      setOwner(true);
    }
  }

  var [gameData, setGameData] = useState(null);
  planetId = props.match.params.planetDetail;

  const getGame = async (gameId) => {
    const total = await getGameById(gameId);
    console.log("PLANET DETAIL: GLOBALSTATE", globalState);
    console.log("PLANET DETAIL: TOTAL", total);

    const likes = total.data.getMasterGameById.favorites ? total.data.getMasterGameById.favorites.length : 0;
    total.data.getMasterGameById.likes = likes;
    setGameData(total.data.getMasterGameById);
    setOwnerState(total.data.getMasterGameById);
  }

  const image = `https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/${planetId}.png`;

  useEffect(() => {
    if (props) {
      console.log("PLANET DETAIL PROPS", props);
    }
    
    setPlanetId(props.planetId);
  }, [props])

  useEffect(() => {
    window.scrollTo(0, 0);
    getGame(planetId);
  }, [])

  return (
    <div className="planetDetail">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <PlanetImage image={image} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <HeaderPlanetDetail gameData={gameData} planetId={planetId} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="left" className="noborder">
              <Tokenize planetId={planetId} owner={owner} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DataPlanetDetail gameData={gameData} />
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center">
              <Publish disabled='true' owner={null} />
            </Segment>
          </Grid.Column>
        </Grid.Row> */}

        {/* <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center">
              <EList disabled='true' owner={null} />
            </Segment>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </div>
  )
}
export default PlanetDetail;