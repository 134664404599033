import { Header, Icon, Input } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

console.log("DESCRIPTION-COMPONENT-MODULE-INIT");
const Description = (props) => {
  console.log("---DESCRIPTION-COMPONENT-INIT---");
  var [gameId, setGameId] = useState(null);
  var [planetOperation, setPlanetOperation] = useState(null);
  var [description, setDescription] = useState("");
  var [edit, setEdit] = useState(false);

  useEffect(() => {
    if (props.gameData) {
      // console.log(props.gameData);
      setGameId(props.gameData.idGame);
      setPlanetOperation(props.gameData.planetOperation);
      setDescription(props.gameData.description || "");
    }
  }, [props]);

  const editDesc = () => {
    setEdit(true);
  };
  const setDesc = (e, { value }) => {
    setDescription(value);
  };
  const updateDesc = async () => {
    props.updateDesc(gameId, planetOperation, description);
    setEdit(false);
  };
  // const clearDesc = () => {
  //   setDescription("");
  // };

  return (
    <>
      <Header as="h2">Description</Header>
      {edit ? (
        <>
          <Input
            as="div"
            icon={<Icon name="check" circular link onClick={updateDesc} />}
            placeholder='Your description...'
            onChange={setDesc}
            value={description}
          />

          {/* <Icon
            name="close"
            onClick={clearDesc}
            className="pointer"
            disabled={description === "" ? true : false}
          ></Icon> */}
        </>
      ) : (
        <>
          <Input
            as="div"
            icon={<Icon name="edit" circular link onClick={editDesc} />}
            placeholder='Your description...'
            onChange={setDesc}
            value={description}
            disabled
          />
        </>
      )}
    </>
  );
};

export default Description;
