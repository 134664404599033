import { Accordion, Header, Icon, List } from "semantic-ui-react";
import { useState } from "react";

console.log("HASHTAGS-DETAIL-COMPONENT-MODULE-INIT");

export const HashtagsDetail = (props) => {

  console.log("---HASHTAGS-DETAIL-COMPONENT-INIT---");
  const { hashtag } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Header as="h2">Hashtags <Icon name="dropdown" /></Header>
      </Accordion.Title>

      <Accordion.Content active={activeIndex === 0}>
        {hashtag && (
          <List>
            {hashtag.split(",").map((index) => (
              <List.Item>#{index}</List.Item>
            ))}
          </List>
        )}
      </Accordion.Content>
    </Accordion>
  );
};
