import React from "react";
import { Dropdown, Label, Icon, Segment, Menu } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {PayPerDataContext} from "../../../services/payperDataContext.js";

var opt = [
  { key: "angular", text: "Angular", value: "angular" },
  { key: "css", text: "CSS", value: "css" },
  { key: "design", text: "Graphic Design", value: "design" },
  { key: "ember", text: "Ember", value: "ember" },
  { key: "html", text: "HTML", value: "html" },
  { key: "ia", text: "Information Architecture", value: "ia" },
  { key: "javascript", text: "Javascript", value: "javascript" },
  { key: "mech", text: "Mechanical Engineering", value: "mech" },
  { key: "meteor", text: "Meteor", value: "meteor" },
  { key: "node", text: "NodeJS", value: "node" },
  { key: "plumbing", text: "Plumbing", value: "plumbing" },
  { key: "python", text: "Python", value: "python" },
  { key: "rails", text: "Rails", value: "rails" },
  { key: "react", text: "React", value: "react" },
  { key: "repair", text: "Kitchen Repair", value: "repair" },
  { key: "ruby", text: "Ruby", value: "ruby" },
  { key: "ui", text: "UI Design", value: "ui" },
  { key: "ux", text: "User Experience", value: "ux" }
];



const PayPerDataHashtags = () => {

  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [options,setOptions]= useState(opt);
  const renderLabel = (label) => ({
    color: "purple",
    content: `${label.text}`,
    icon: "check"
  });

  const saveOptionsToContext=(e,{value})=>{
   
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_HASHTAG_FILTERS",payload:value})

  }

  

  useEffect(()=>{
  if(metricsState.metricFilters && metricsState.metricFilters.hashtags)
  {
    let newOpt=[];
    for(let i=0;i<metricsState.metricFilters.hashtags.length;i++){
      let h=metricsState.metricFilters.hashtags[i]
      newOpt.push({key:h,text:h,value:h})
    }
    console.log("HASTAGS-METRICS OPTIONS",newOpt);
    setOptions(newOpt);
  }
    
  },[metricsState.metricFilters]);


 

 

  return (
    <>
      <Menu fluid inverted stackable>
        <Menu.Item style={{ fontSize: "18px", width: "190px" }}>
          Hashtags
          <Label color="purple" size="large">
            0
          </Label>
        </Menu.Item>

        <Dropdown
          fluid
          openOnFocus
          search
          clearable
          style={{ background: "#34495E" }}
          placeholder="Search hashtags or select them from the list"
          multiple
          selection
          options={options}
          renderLabel={renderLabel}
          onChange={saveOptionsToContext}
        />
      </Menu>
    </>
  );
};

export default PayPerDataHashtags;
