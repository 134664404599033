import { emotionyLanguage } from "./emotionyLanguage.js";
import { mode } from "../../../mode.js";

var numPositives = 0;
var numNegatives = 0;
var numEmotionys = mode.NumEmotions;
var emolang = new emotionyLanguage();
//var language="català";
//var language = "castellano";
var language = "english";

emolang.setlanguage(language);
var emotions = [];

console.log("GETEMOTIONYMAME-COMPONENT-MODULE-INIT");

export function getEmoLanguage() {
    return language;
}

export function getEmotionyName(num, dataGame) {

    console.log("---GETEMOTIONYMAME-COMPONENT-INIT---");
    
    let name;
    let select = false;
    let nameInEmotion;
    let l = 0;
    let grDefinitive = 0;
    let eDefinitive = 0;
    if (num === -1) {
        //console.log("RESET EMOTIONYS: EMOTIONS[].length=0, numPositives=0, numNegatives=0");
        numPositives = 0;
        numNegatives = 0;
        emotions.length = 0;
        dataGame.emotions.length = 0;
    } else {

        if (num >= 0 && num < numEmotionys) {

            while (!select  && numPositives+numNegatives<numEmotionys){

                let gr = Math.floor(Math.random() * 9);
                let e = Math.floor(Math.random() * 6);
                let posNeg = Math.random() * 20;
 
                if (posNeg < (mode.NumEmotions/2+2)) {
                    //POSITIVES
                    if (numPositives < mode.NumEmotions/2) {
                        name = emolang.getEmotion(gr, e);
                        numPositives++;
                        grDefinitive = gr;
                        eDefinitive = e;
                        //console.log(grDefinitive,e,name," positives:",numPositives);
                        select = true;
                    }
                } else {
                    //NEGATIVES
                    if (numNegatives < mode.NumEmotions/2) {
                        name = emolang.getEmotion(gr + 9, e);
                        numNegatives++;
                        grDefinitive = gr + 9;
                        eDefinitive = e;
                        //console.log(grDefinitive,e,name," negatives:",numNegatives);
                        select = true;
                    }
                }
                if (!select) {
                    console.log("UNDEFINED:",name," REPEATING SELECTION");
                } else {
                    for (l = 0; l < emotions.length; l++) {

                        nameInEmotion = emotions[l];
                        //console.log("[",l,"] COMPARING:",name, "WITH ",nameInEmotion,":","CDOMPARE=:",(name==nameInEmotion));                

                        if (name === nameInEmotion) {
                            select = false;
                            if (posNeg < 11) {
                                numPositives--;
                            } else {
                                numNegatives--;
                            }
                            //console.log("REPEATED,", name," = ",nameInEmotion, " REPEATING SELECTION");
                            break;
                        }
                    }
                    
                }
            }
        }
        //let name="Emotiony "+num;
        let eEmo = "EMO" + (grDefinitive + 1) + "_" + (eDefinitive + 1);
        dataGame.emotions.push([eEmo, 0]);

        //console.log(">>>>>>>>>>>>>>>>>>>>>> PUSH EMOTION: >>>>>>>>>>>>: [ ",name," ]:[",eEmo,"]");
        emotions.push(name);
        return name;
    }
}

export function getEmotionyNames(dataGame){
    console.log("---GETEMOTIONYMAMES-COMPONENT-MODULE-INIT---");
    //reset emotions
    getEmotionyName(-1,dataGame);
    // set emotions
    for (var i = 0 ; i < numEmotionys ; i++){
        getEmotionyName(i,dataGame);
    
    }
  
    return emotions;
}