import { Button } from "semantic-ui-react";
import CardPlanet from "../Card/CardPlanet";
import { useEffect, useState } from "react";

//var listkey = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
///var columns;
const COLUMNSLIMIT = 4;

console.log("GRID-PLANET-COMPONENT-MODULE-INIT");

const GridPlanet = (props) => {

  console.log("---GRID-PLANET-COMPONENT-INIT---");

  const [cards, setCards] = useState(null);
  const [columns, setColumns] = useState(null);

 
  console.log("GP:", props.list);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
  
    console.log("handlenext", currentIndex, COLUMNSLIMIT, cards.length);
    if (currentIndex + COLUMNSLIMIT + 1 <= cards.length) {
      setCurrentIndex((currentIndex + 1) % cards.length);
    }
  };

  const handleBack = () => {
    
    console.log("handleback", currentIndex, COLUMNSLIMIT, cards.length);
    if (currentIndex - 1 >= 0) {
      setCurrentIndex((currentIndex - 1 + cards.length) % cards.length);
    }
  };
  // console.log("RENDERING GRIDPLANET");
  // console.log("GRID PLANET LENGTH", props.list);
  /*
  columns = listkey.map((i, j) => {
    if (props.list[i]) {
      return (
        <Grid.Column width={2} key={props.list[i].idGame + '-column'}>
          <CardPlanet key={j} item={props.list[i]} />
        </Grid.Column>
      )
    } else return null
  });
  */

  useEffect(() => {
    if (props.list) {
      setCards(props.list);
      setColumns(
        props.list
          .slice(currentIndex, currentIndex + COLUMNSLIMIT)
          .map((card, index) => {
            return (
              <CardPlanet key={index} item={card} />
            );
          })
      );
    }
  }, [props, currentIndex]);

  return (
    <>
      {columns}

      <Button floated='left' onClick={handleBack} className="filled">Back</Button>
      <Button floated='right' onClick={handleNext} className="filled">Next</Button>
    </>
  );
};
export default GridPlanet;
