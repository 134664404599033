import { Accordion, Header, Icon } from "semantic-ui-react";
import { useState } from "react";

console.log("EMOTIONSDETAIL-COMPONENT-MODULE-INIT");
export const EmotionsDetail = (props) => {

  console.log("---EMOTIONSDETAIL-COMPONENT-INIT---");

  const { emotions } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Header as="h2">Emotions Detail <Icon name="dropdown" /></Header>
      </Accordion.Title>

      <Accordion.Content active={activeIndex === 0}>
        {emotions}
      </Accordion.Content>
    </Accordion>
  );
};
