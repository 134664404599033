import React from "react";
import Hashtags from "./Filters/FilterHashTags";
import SuperEmos from "./Filters/FilterSuperEmos";
import Drivers from "./Filters/FilterDrivers";
import LocationsGames from "./Filters/FilterLocationsGames";
import DatesGames from "./Filters/FilterDatesGames";
import HoursGames from "./Filters/FilterHoursGames";
import { Divider, Button, Grid, Container } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {MetricsContext} from "../../services/metricsContext.js";
import { prepareFilters,prepareMetricsQuery,getMetrics } from "../../services/Graphql/Game/queryMetrics";
import { userService } from '../../services/userParams';

console.log("METRICS-FILTERS-COMPONENT-MODULE-INIT");

function Filters() {
  
  console.log("---METRICS-FILTERS-COMPONENT-INIT----");
  
  var [user, setUser] = useState(null);
  var userDataSubs = undefined;
  var MD5 = require('md5.js');

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const submitFilters= async()=>{
    if(user && user.userAddressId)
    {
        console.log("FILTER: EFFECTIVE_FILTERS:",metricsState.effectiveFilters);
        var filter=prepareFilters(
                    metricsState.effectiveFilters.dateRange.init,
                    metricsState.effectiveFilters.dateRange.end,
                    metricsState.effectiveFilters.drivers,
                    metricsState.effectiveFilters.hashtags,
                    metricsState.effectiveFilters.locations,
                    metricsState.effectiveFilters.superEmos,
                    metricsState.effectiveFilters.hoursRange
                  )
        console.log("FILTER: FILTER",filter);
        
        let queryResp=prepareMetricsQuery(user.userAddressId,10,filter);
        console.log("FILTER: QUERYRESP",queryResp);

        var md5stream = new MD5()
        md5stream.end(queryResp);
        var hash=md5stream.read(queryResp).toString('hex');
  
        var comprobationHash=await comprobateHash(hash)
        console.log("FILTER: COMPROBATIONHASH",comprobationHash);

       var rmetrics=null;
       var metrics=null;

       if(comprobationHash.result===null)
       {
            rmetrics=await getMetrics(queryResp);
            console.log("FILTER: METRICS RESULT=",rmetrics);
            metrics=JSON.parse(rmetrics.data.getGameMetrics.metricsGame);
            console.log("FILTER: METRICS FROMJSON",metrics);
            dispatchMetrics({type:"SET_METRIC_DATAHASH",
              payload:{
                hash:hash,
                query:queryResp,
                metricFilters:metricsState.metricFilters,
                metricResults:metrics
              }});
       }
       else
       {
        metrics=comprobationHash.result;
        console.log(" FILTER: >>>>>> DIRECT >>>>>> COMPROBATIONHASH HMETRICS=",metrics);
       }

        
        
        
        
      
        dispatchMetrics({type:"SET_METRIC_RESULTS",payload:metrics});

    }
  }

  
  const comprobateHash=async (hash)=>{

    console.log("COMPROBATEHASH: metricState=",metricsState," metricState.metricDataHash=",metricsState.metricDataHash," metricsState.metricDataHash.length   =",metricsState.metricDataHash.length   );
     if(metricsState.metricDataHash)
     {
       for(let i=0;i<metricsState.metricDataHash.length;i++)
       {
         console.log("COMPROBATE HASH:"+hash+"METRICSTATE["+i+"]="+metricsState.metricDataHash[i].hash);
         if(metricsState.metricDataHash[i].hash===hash)
         {
           return ({result:metricsState.metricDataHash[i].metricResults});
         }
       }
     }
     return ({result:null});
     
   }

  const assignUser = async () => {
    userDataSubs = userService.getUserData().subscribe(u => {
        if (u && u.userAddressId) {
            setUser(u);
            
        } else {
            // res de res
            setUser(null);
        }
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    assignUser();
    
    return (() => {
        userDataSubs.unsubscribe();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  return (
    <>
      <Container
        style={{
          paddingLeft: "20px"
        }}
      >
        <p style={{ color: "grey" }}>
          Filter your planets and games by the next selectetions:
        </p>
        <br />
        <Grid columns={16}>
          <Grid.Row mobile={16} tablet={16} computer={16}>
            <Hashtags />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <SuperEmos />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <Drivers />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <DatesGames />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <HoursGames />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <LocationsGames />
          </Grid.Row>
        </Grid>

        <br />
        <br />
        <Button inverted color="purple" size="big" fluid onClick={submitFilters}>
          Submit
        </Button>
        <br />
        <Divider />
      </Container>
    </>
  );
}

export default Filters;
