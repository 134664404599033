
import * as THREE from 'three'
import { useRef,  useMemo , useEffect, useState, Suspend } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {
  Text,
  TrackballControls,
  MeshDistortMaterial,
  Sphere,
  Stars,
  Html
  
} from '@react-three/drei'
import { Clock, Object3D, OctahedronGeometry, Vector3 } from 'three'
import { useControls } from 'leva'
import { getUserDevice } from '../../../core/device.js';
import { appParams } from '../../../services/appParams.js'
import { ConsoleLogger } from '@aws-amplify/core'
import { getDataGame } from './dataGame.js'
import { input } from 'aws-amplify'
import { AiFillBoxPlot } from 'react-icons/ai'
import { mode } from '../../../mode.js';


console.log("CLOUDEMOTIONS-COMPONENT-MODULE-INIT");

export function Cloud({ 
  
  
  setImgPlanet, 
  emotionList, 
  listValues, 
  setlistValues, 
  type, 
  setTypeCloud, 
  setFinishGame,
  inputValue, 
  radius

  }) {

    console.log("---CLOUD-COMPONENT-INIT---");

    console.log("TYPE:",type);
    if(type==="emotions"){
      console.log("input Value",inputValue)
    }
 
    //console.log("listValues",listValues)
    //console.log("SET setlistValues",setlistValues)
    
    const count = Math.ceil(Math.sqrt(emotionList.length))
    const sphericalRef = useRef(new THREE.Spherical())
    const cloudRef=useRef();
    
    var emotions = useMemo(() => {
      let idx = 0
      const temp = []
      const phiSpan = Math.PI / (count + 1)
      const thetaSpan = (Math.PI * 2) / count
      for (let i = 1; i < count + 1; i++) {
        // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
        for (let j = 0; j < count; j++) {
          const v3 = new THREE.Vector3().setFromSpherical(
            sphericalRef.current.set(radius, phiSpan * i, thetaSpan * j)
          )
          if (idx < emotionList.length) temp.push([v3, emotionList[idx][0]])
          idx++
        }
      }
        
      return temp.filter((w) => Boolean(w[1]));
    }, [count, radius, emotionList, type])

    console.log("USEMEMO emotions:",emotions);
    
    return( 
    
        <group ref={cloudRef}>
        {emotions.map(([pos, emotion]) => (
        
        <Emotion

          setImgPlanet = {setImgPlanet} 
          emotionList = {emotionList} 
          listValues = {listValues} 
          setlistValues = {setlistValues} 
          type = {type} 
          setTypeCloud = {setTypeCloud}
          setFinishGame = {setFinishGame}
          key = {emotion} 
          position = {pos} 
          children = {emotion} 

        />

        
      ))}
      </group>

    );

  }

  function Emotion({

    setImgPlanet, 
    emotionList, 
    listValues, 
    setlistValues, 
    type, 
    setTypeCloud,
    setFinishGame,
    children, 
    onClick, 
    controlsRef, 
    sphericalRef, 
    ...props 
  
    }) {
    
    
  
    console.log("---EMOTION-COMPONENT-INIT---");
    
    const color = new THREE.Color()
    
  
    const [fontProps,setFontProps] = useState({

      font: '/assets/Candide-Extra-Bold.woff',
      fontSize: 1.1,
      letterSpacing: -0.05,
      lineHeight: 2,
      'material-toneMapped': false

    })

    var index1 = -1;
    var ix1 = 0;
    emotionList.map((element) => {
        
        //console.log("INDEX:",ix,"ELEMENT[0]:",element[0],"ELEMENT[1]:",element[1]);
        if(element[0] === children) {
        //console.log("ix",ix);
        index1 = ix1;
        }
        ix1++;
    })


    //console.log("TEXT PROPS",props);
    //console.log("FONTPROPS",fontProps);
    console.log("EMOTEXT: CHILDREN",children);
    
    const ref = useRef();
    const refpng=useRef();
    const reftext=useRef();

    const [ hovered, setHovered ] = useState(false)
    const [ clicked, setClicked ] = useState(emotionList[index1][1]>0)
    const [ pointerDown, setPointerDown ] = useState(false);
    const [ outOfGame, setOutOfGame ] = useState(false);
    const [ emoCount, setEmocount ] = useState(emotionList[index1][1]);
    const [ initialPosition, setInitialPosition ] = useState(new Vector3(0,0,0));
    const [ eposition, seteposition ] = useState(new Vector3(0,0,0));
    const [ newPosition, setNewPosition ] = useState(new Vector3(0,0,0));
    const [ upPosition, setUpPosition ] = useState(new Vector3(0,0,0));
    const [ directionOut, setDirectionOut ] = useState(new Vector3(0,0,0));
  
    const [ returnToInitialPosition, setReturnToInitialPosition ] = useState(false);
    // pngVisibility IMAGE
    const [pngVisibility,setPngVisibility]=useState(true);
    const [pngSize,setPngSize]=useState(1);

    //const [timeOver,setTimeOver] = useState(0)

    const over = (e) => {
      //var timestart = Date.now();
      //console.log("OVER",timestart);
      //setTimeOver(timestart);
      e.stopPropagation()
      
      setHovered(true)
      if(!clicked){

      setFontProps({

        font: '/assets/Candide-Extra-Bold.woff',
        fontSize: 1.3,
        letterSpacing: -0.05,
        lineHeight: 2,
        'material-toneMapped': false

      })
    } else {

      setFontProps({

        font: '/assets/Candide-Extra-Bold.woff',
        fontSize: 1.4,
        letterSpacing: -0.05,
        lineHeight: 2,
        'material-toneMapped': false

      })
    }
    
  }

    const out = (e) => {

      setHovered(false);
      
      if(!clicked){

        setFontProps({

          font: '/assets/Candide-Extra-Bold.woff',
          fontSize: 1.1,
          letterSpacing: -0.05,
          lineHeight: 2,
          'material-toneMapped': true

        })
      }else{

        setFontProps({

          font: '/assets/Candide-Extra-Bold.woff',
          fontSize: 1.3,
          letterSpacing: -0.05,
          lineHeight: 2,
          'material-toneMapped': false

        })
      }
      //var timestop = Date.now();
      //var timeOverOut = timestop - timeOver;
      //console.log("OUT",timestop)
      //console.log("TIME OVEROUT",timeOverOut);
      //if(( timeOverOut > 500 && appParams.device === "mobile" )){
      //  console.log("DEVICE",appParams.device)
      //  console.log("OVEROUT CLICKKK");
      //  emoClick(e)
      //}

    }

   
    const emoClick = (e) => {
      
        console.log("OBJECT",e.object)
        seteposition(e.object.parent.position);
        e.stopPropagation();
        setClicked(true);
       
        //var emoText = e.object._private_text.split(" ")[0];
        //e.object._private_text=emoText+" "+(emoCount+1);

        var emoText = e.object._private_text;
        console.log("EMO TEXT:",emoText);

        if( type === "hashtag") {

          var aux;

          if(listValues.length === 0){

            aux = emoText;

          }else{

             aux = emoText + listValues[0] ;

          }

          


          var auxListValues=[];
          setlistValues([]);
          auxListValues.push(aux)
          setlistValues(auxListValues);
          
          console.log("LISTVALUES=",listValues);
        }

      setEmocount(emoCount + 1);
        
      if(emoText === "[END GAME]"){
        
        console.log("EMO FINISH GAME",emoText)
        
        setFinishGame(true);
        setTypeCloud("image");
       
      }  
        
      var index = -1;
      var ix = 0;
      emotionList.map((element) => {
        
        //console.log("INDEX:",ix,"ELEMENT[0]:",element[0],"ELEMENT[1]:",element[1]);
        if(element[0] === emoText) {
          //console.log("ix",ix);
          index = ix;
        }
        ix++;
      })
      
      console.log("Emotion Index",index);
      //console.log(emoCount, emoText, 'index', index)
      emotionList[index][1] = emoCount + 1 ;

      if (type === "emotions") {

        if(index !== -1 && index !== mode.NumEmotions){

          getDataGame().emotions[index][1] = ( emoCount + 1 ) * 20 ;

        }else{

          if(index !== mode.NumEmotions ) getDataGame().emotions[index][1] = index;

        }
      }
      //console.log(emotionList[index][1])
      showEmotions(emotionList);
    
    }

    const pdown = ( e ) => {

      setPointerDown(true);
      setInitialPosition(e.eventObject.position);
      seteposition(e.eventObject.position);
      setNewPosition(e.eventObject.position);
      
      console.log("PDOWN:",e)
      emoClick(e);
      emoOver(e);
      console.log("PDOWN POSITION",e.eventObject.position);
      
    }

    const  emoOver = ( e ) =>{

      setFontProps({

        font: '/assets/Candide-Extra-Bold.woff',
        fontSize: 0.9,
        letterSpacing: -0.05,
        lineHeight: 2,
        'material-toneMapped': true

      })

      setTimeout(() => {
        setFontProps({

          font: '/assets/Candide-Extra-Bold.woff',
          fontSize: 1.4,
          letterSpacing: -0.05,
          lineHeight: 2,
          'material-toneMapped': false

        })
      }, 90);

    }
    
    const pmove = ( e ) => {
        //if(pointerDown) {
          seteposition(e.point);
          //console.log("PMOVE",e.point,e);
        //}
      
    }

    const pup = ( e ) => {

        setPointerDown(false);
        console.log("POINTER UP",e,e.point)
        setUpPosition(e.point);
        setOutOfGame(true);

        console.log("SET OUT OF GAME",initialPosition,newPosition,upPosition);
        console.log("DISTANCE TO FINAL",upPosition.distanceTo(newPosition));
        console.log("DISTANCE TO INITIAL:",upPosition.distanceTo(initialPosition));

        var direction=(new Vector3(

          upPosition.x - initialPosition.x,
          upPosition.y - initialPosition.y,
          upPosition.z - initialPosition.z)).multiplyScalar( 10 );

        console.log("DIRECTION :",direction);
        setDirectionOut(direction);
          
        
    }
   
  
    // Change the mouse cursor on hover
    useEffect(() => {
      
      if (hovered) document.body.style.cursor = 'pointer'
      return () => (document.body.style.cursor = 'auto')

    }, [hovered])



    

   function markEmotionOut(ref){

    console.log("REFERENCE OBJECT",ref);
    console.log("CURRENT PRIVATE TEXT",ref.current._private_text);

    var emoText=ref.current._private_text;
    setEmocount( - 1)

    var index = -1;
    var ix = 0;

    emotionList.map((element) => {
      
      //console.log("INDEX:",ix,"ELEMENT[0]:",element[0],"ELEMENT[1]:",element[1]);
      if(element[0] === emoText) {
        //console.log("ix",ix);
        index = ix;
      }

      ix++;

    })
    
    console.log("Emotion Index",index);
    //console.log(emoCount, emoText, 'index', index)
    emotionList[index][1] = -1 ;
    //console.log(emotionList[index][1])
    showEmotions(emotionList);
    
   }
  
    // Tie component to the render-loop
    useFrame(({ camera }) => {
      // Make text face the camera
      ref.current.quaternion.copy(camera.quaternion)

     
      

      if(type === "hashtag" && clicked && emoCount === 1 && ref.current.visible) {

        ref.current.position.lerp(new Vector3(0,0,0),0.1);
        
        if(Math.abs(ref.current.position.x) < 0.1 &&
           Math.abs(ref.current.position.y) < 0.1 &&
           Math.abs(ref.current.position.z) < 0.1 ){

          ref.current.visible = false;
          setPngVisibility(false);

        }
      }


      if(type === "emotions" && emoCount <= 5 && clicked && ref.current.visible){
        
        if(pointerDown && newPosition !== eposition){
          setNewPosition(eposition);
          console.log("USEFRAME POSITION",eposition);
          ref.current.position.lerp(eposition,0.8);
        }
       
      }
      
      if(type === "emotions" && emoCount <= 5 && clicked && outOfGame && ref.current.visible){

        let origin = new Vector3(0,0,0);
        
        console.log("OUTOFGAME(distance)",upPosition.distanceTo(origin));
        var outOfGameDistance = 12.5;
        
        if(upPosition.distanceTo(origin) > outOfGameDistance){

          console.log("OUTOFGAME DISTANCE > " + outOfGameDistance + " : ",upPosition.distanceTo(origin));

          reftext.current.material.color.lerp('black',0.1);
          

        //ref.current.position.lerp(directionOut,0.1)
        //}else{
        //  setNewPosition(upPosition);
        //  setOutOfGame(false);
        //}
        //if(Math.abs(ref.current.position.x-directionOut.x)<0.3 &&
        //   Math.abs(ref.current.position.y-directionOut.y)<0.3 &&
        //   Math.abs(ref.current.position.z-directionOut.z)<0.3
        //   ){

         
          ref.current.visible = false;
          setPngVisibility(false);
         

          setOutOfGame(false);
          markEmotionOut(ref);

        } else {

          setNewPosition(upPosition);
          setOutOfGame(false);
          
        }

        
      }

      if(type === "emotions" && emoCount <= 5 && outOfGame){

        if(Math.abs(ref.current.position.x-directionOut.x) < 0.7 &&
            Math.abs(ref.current.position.y-directionOut.y) < 0.7 &&
            Math.abs(ref.current.position.z-directionOut.z) < 0.7){

                console.log("YAAAAAA type===emotions && emoCount<=5 && outOfGame");
                ref.current.visible = false;
                setPngVisibility(false);
                
                setOutOfGame(false);
                markEmotionOut(ref);
        }
      }
     

      if(type==="emotions" && emoCount>5 && clicked && ref.current.visible) {

        ref.current.position.lerp(new Vector3(0,0,0),0.1);
        setPngSize(lerp(pngSize,0.01,0.2));

        if(Math.abs(ref.current.position.x) < 0.1 &&
           Math.abs(ref.current.position.y) < 0.1 &&
           Math.abs(ref.current.position.z) < 0.1 ){

          ref.current.visible = false;
          setPngVisibility(false);

        }
      }

      // Animate Size PNG
      
      //if(emoCount<=5) setPngSize(lerp(pngSize,(emoCount*0.9+1),0.05));
       
      // Animate opacity
      /*
      if(children!=='[END GAME]'){
        var auxopacity = (1+ref.current.position.z/10);
        if(auxopacity>1) auxopacity=1;
        
        reftext.current.fillOpacity=(emoCount===0)?auxopacity:1
        
        
      }  
    */
      // Animate font color
      
      reftext.current.material.color.lerp(

        color.set(
          //children==='[END GAME]'?'white':

          clicked ?
           
          ((emoCount === 1 ) ? (type==="emotions"?'blue':'white') :
          ((emoCount === 2 ) ? 'red' :
          ((emoCount === 3 ) ? 'green' :
          ((emoCount === 4 ) ? '#FF8C00' :
          ((emoCount === 5 ) ? 'purple' :
          'black'))))) :

           hovered ? '#E1E446' : 'white' //(type==="emotions"?'black':'white'),

        ),0.1
      )
      
    })

    function showEmotions(emotionList){

      console.log("EMOTIONS:");
      emotionList.map((element) => console.log(element))
      console.log("DATAGAME EMOTIONS:");

      if(type === "emotions") getDataGame().emotions.map( e => console.log(e))

    }

    
    
  /*
    return(
    
    
      
      <Text
        ref = {ref}
        onPointerMove = {pmove}
        onPointerDown = {pdown}
        onPointerUp = {pup}
        onPointerOver = {over}
        onPointerOut = {out}
        {...props}
        {...fontProps}
        children = {children}
        
      />
      */
     return (
     
    
     
          
        
        <group 
            position={[props.position.x,(props.position.y),props.position.z]}
            ref = {ref}
            //onPointerOver = {over}
            //onPointerMove = {pmove}
            onDoubleClick = {pdown}
            //onPointerUp = {pup}
            //onPointerOut = {out}
            _private_text = {children}
            children = {children}
        >
        <Text
          
         
          ref={reftext}
          {...[-20,1,0]}
          {...fontProps}
          children = {children}
        />
       
       
        </group>

      

    
      
  
    )
  }




 function Box(...props) {
    const box = useRef()
  
    console.log("BOX PROPS",props);

    useFrame(() => {
      box.current && void (box.current.rotation.x += 0.01, box.current.rotation.y += 0.01);
   
    })

    return (
     <mesh ref={box}>
      <boxGeometry  attach="geometry" args={[0.5, 0.5, 0.5]} />
      <meshStandardMaterial attach="material" color={0x0ff000} />
     </mesh>
    )
   }

  function lerp (start, end, amt){
    return (1-amt)*start+amt*end
  }
  
/*

 <Html distanceFactor={2}>
            <img class="imgPNG" ref={refpng} width={((pngVisibility?100:1)*pngSize*18)+'px'} src='./assets/images/particle8.png' alt="jajaja"/>
        </Html>
       
       
*/