import {React,useContext, useEffect, useState} from "react";
import { Grid, Divider, Header, Icon } from "semantic-ui-react";
import DriversPolarityWindRose from "./Charts/DriversPolarityWindRose";
import DriversDetailWindRose from "./Charts/DriversDetailWindRose";
import TopEmotionsBar from "./Charts/TopEmotionsBar";
import LocationsMap from "./Charts/LocationsMap";
import PolarityByDayArea from "./Charts/PolarityByDayArea";
import PolarityBar from "./Charts/PolarityBar";
import PieTime from "./Charts/PieTime";
import SuperEmotionsBar from "./Charts/SuperEmotionsBar";
import TotalDateHourBox from "./Totals/TotalDateHourBox";
import EmotionsCloud from "./Charts/EmotionsCloud";
import Filters from "./Filters";
import TotalsStatistics from "./Totals/TotalsStatistics";
import TotalsTop from "./Totals/TotalsTop";
import {MetricsContext} from "../../services/metricsContext.js";
import {getMetrics, prepareMetricsQuery} from "../../services/Graphql/Game/queryMetrics.js"
import { userService } from '../../services/userParams';


console.log("METRICS-DASHBOARD-COMPONENT-MODULE-INIT");

function Dashboard() {

  console.log("----METRICS-DASHBOARD--COMPONENT-INIT---");
  
  var [user, setUser] = useState(null);
  var userDataSubs = undefined;

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const [dashboardState,setDashboardState] = useState("initial");

 
  var MD5 = require('md5.js');

  const setDash =  async (user) => {
    if(dashboardState === "initial"){
      let query=prepareMetricsQuery(user.userAddressId,-1,false);
      console.log("DASHBOARD QUERY",query);
      let result=await getMetrics(query);
      result.data.getGameMetrics.hashtags=result.data.getGameMetrics.hashtags.split(",");
      console.log("DASHBOARD RESULT=",result.data.getGameMetrics);
      setDashboardState("getDashboard");
   

      dispatchMetrics({type:"SET_METRIC_FILTERS",payload:result.data.getGameMetrics});

      let queryResp=prepareMetricsQuery(user.userAddressId,10,"{}");
      console.log("DAHSBOARD QUERYRESP",queryResp);

      var md5stream = new MD5()
      md5stream.end(queryResp);
      var hash=md5stream.read(queryResp).toString('hex');

      var comprobationHash=await comprobateHash(hash)
      console.log("COMPROBATIONHASH",comprobationHash);

       var rmetrics=null;
       var metrics=null;

       if(comprobationHash.result===null)
       {
          rmetrics=await getMetrics(queryResp);
          console.log("DASHBOARD METRICS RESULT=",rmetrics);
          metrics=JSON.parse(rmetrics.data.getGameMetrics.metricsGame);
          console.log("DASHBOARD METRICS FROMJSON",metrics);
          dispatchMetrics({type:"SET_METRIC_DATAHASH",
          payload:{
            hash:hash,
            query:queryResp,
            metricFilters:metricsState.metricFilters,
            metricResults:metrics
        }});
       }
       else
       {
          metrics=comprobationHash.result;
          console.log(" DASHBOARD >>>>>> DIRECT >>>>>> COMPROBATIONHASH HMETRICS=",metrics);
      }
      
      dispatchMetrics({type:"SET_METRIC_RESULTS",payload:metrics});
      
      setDashboardState("initialResultsDashboard");
    }
  }

  const comprobateHash=async (hash)=>{

   console.log("COMPROBATEHASH: metricState=",metricsState," metricState.metricDataHash=",metricsState.metricDataHash," metricsState.metricDataHash.length   =",metricsState.metricDataHash.length   );
    if(metricsState.metricDataHash)
    {
      for(let i=0;i<metricsState.metricDataHash.length;i++)
      {
        console.log("COMPROBATE HASH:"+hash+"METRICSTATE["+i+"]="+metricsState.metricDataHash[i].hash);
        if(metricsState.metricDataHash[i].hash===hash)
        {
          return ({result:metricsState.metricDataHash[i].metricResults});
        }
      }
    }
    return ({result:null});
    
  }

  const assignUser = async () => {
    userDataSubs = userService.getUserData().subscribe(u => {
        if (u && u.userAddressId) {
            setUser(u);
            console.log("DASHBOARD: user =",u);
            setDash(u);
            
        } else {
            // res de res
            setUser(null);
        }
    });
  }

  

 

  useEffect(() => {
    window.scrollTo(0, 0);
    assignUser();
    
    return (() => {
        userDataSubs.unsubscribe();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Header as="h1" icon textAlign="center" style={{ color: "#F8F9F9" }}>
          <Icon name="chart bar outline" inverted circular />
          <Header.Content>
            
            <div>
                        <br/>
                        {user && user.user ? user.user +" at": ""} MyMetrics
                        <br/>
                        <div>
                        <div>
                            {user && user.userAddressId &&
                                `${user.userAddressId.slice(0, 6)}...${user.userAddressId.slice(
                                    user.userAddressId.length - 4,
                                    user.userAddressId.length
                                )}`}
                        </div>
                       
                    </div>
                   
            </div>
          </Header.Content>
        </Header>
      </Grid.Row>

      <Divider />
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <TotalsStatistics />
        </Grid.Column>
      </Grid.Row>

      <Divider />
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Filters />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <TotalDateHourBox />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <PolarityByDayArea />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <TotalsTop />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <PieTime />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <PolarityBar />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <DriversDetailWindRose />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <DriversPolarityWindRose />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <SuperEmotionsBar />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <LocationsMap />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <TopEmotionsBar />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <EmotionsCloud />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Dashboard;
