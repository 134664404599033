import React from "react";
import { Dropdown, Label, Menu } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {PayPerDataContext} from "../../../services/payperDataContext.js";

const opt = [
  {
    key: "Nothing",
    text: "Nothing",
    value: "Nothing",
  }
];

const PayPerDataLocationsGames = () => {
  const [options,setOptions]=useState(opt);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const renderLabel = (label) => ({
    color: "purple",
    content: `${label.text}`,
    icon: "check"
  });
  const saveOptionsToContext=(e,{value})=>{
   
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_LOCATION_FILTERS",payload:value})

  }

  useEffect(()=>{
    console.log("METRICSTATE",metricsState); 
    
    let newOpt=[];
    if(metricsState.metricFilters && metricsState.metricFilters.locations)
    {
      for(let i=0;i<metricsState.metricFilters.locations.length;i++){
        let v=metricsState.metricFilters.locations[i]
        if(v.length>1){
          newOpt.push({key:v,text:v,value:v})
        }
      }
      //console.log("OPTIONS",newOpt);
      setOptions(newOpt);
    }    
  },[metricsState.metricFilters]);

  return (
    <>
      <Menu fluid inverted stackable>
        <Menu.Item style={{ fontSize: "18px", width: "190px" }}>
          Locations
          <Label color="purple" size="large">
            0
          </Label>
        </Menu.Item>

        <Dropdown
          fluid
          openOnFocus
          search
          clearable
          style={{ background: "#34495E" }}
          placeholder="Search locations or select them from the list"
          multiple
          selection
          options={options}
          renderLabel={renderLabel}
          onChange={saveOptionsToContext}
        />
      </Menu>
    </>
  );
};

export default PayPerDataLocationsGames;
