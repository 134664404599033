import { useEffect, useRef, useState, useContext } from "react";
import { Button, Container, Icon, Segment, Sidebar } from "semantic-ui-react";
import { appParams } from "../../services/appParams";
import GridPlanet from "../Grid/GridPlanet";
import "./Galaxy.scss";
import { gamesList } from "../../services/Graphql/Game/queryLists";
import { resetNextTokenList, userService } from "../../services/userParams";
import { AllFiltersComponent } from "../Filters/allFiltersComponent";
import { UserContext } from "../../services/userContext";
import { getGalaxyList } from "../../services/Graphql/Game/queryGalaxy";
let count = 0;

console.log("GALAXY-COMPONENT-MODULE-INIT");

const Galaxy = (props) => {
  console.log("---GALAXY-COMPONENT-INIT---");

  console.log("GALAXY", props);

  var [page, setPage] = useState(0);
  var [stateList, setStateList] = useState(null);
  var [filter, setFilter] = useState(null);
  var [next, setNext] = useState(0);

  var [rcount, setRcount] = useState(0);

  var [dataToFilters, setDataToFilters] = useState([]);
  var [submitFilter, setSubmitFilter] = useState(null);
  const [userState, dispatchUser] = useContext(UserContext);
  var [filterHeaders,setFilterHeaders] = useState(false);
  const gridRef = useRef(null);

  let setData = (data, value, filter) => {
    console.log("setData");
    if (filter && props.userData) {
      console.log("3");
      filter[data] = value;
    } else if (props.userData) {
      console.log("4");
      filter = { [data]: value };
    }
    return filter;
  };
  const getList = async (page, filter) => {
    console.log("getList");
    if (props.userData && props.userData.userAddressId) {
      console.log("5");
      filter = setData("user", props.userData.userAddressId, filter);
    }
    if (filter && sessionStorage.getItem("type") === "user") {
      console.log("6");
      delete filter.favorites;
    } else if (sessionStorage.getItem("type") === "favorites") {
      console.log("7");
      filter = setData("favorites", true, filter);
      filter.favGroup = sessionStorage.getItem("favGroup");
    }
    setFilter(filter);
    try {
      const params = {
        page: page,
        filter: filter,
      };
      console.log("8");
      const gameList = await gamesList(params);
      // console.log(gameList);
      if (gameList) {
        console.log("9");
        setNext(gameList.nextCount);
        setStateList(gameList.list);
      }
    } catch (error) {
      console.log("10");
    }
  };

  const gridScroll = (ref) => {
    console.log("gridScroll");
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const element = ref.current;
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + top - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handlerPreviousClick = () => {
    console.log("handlerPreviousClick");
    document.getElementById("prv").blur();
    setPage(--page);
    gridScroll(gridRef);
  };

  const handlerNextClick = () => {
    console.log("handlerNextClick");
    document.getElementById("nxt").blur();
    setPage(++page);
    gridScroll(gridRef);
  };

  const getGames = async () => {
    console.log("get Games");
    const params = {
      page: page,
      filter: filter,
    };
    const gameList = await gamesList(params);
    if (gameList) {
      console.log("11");
      setNext(gameList.nextCount);
      setStateList(gameList.list);
      console.log("GAMELIST-LIST", gameList.list);
    }
    //setStateList(await gamesList(page));
    //setGameData(total.data.getMasterGameTable);
  };

  const getHeaders = async () => {
    let filters = {
      getheaders: true,
    };

    let _reducer = { userState: userState, dispatchUser: dispatchUser };
    let result = await getGalaxyList(0, 0, 0, filters, _reducer);
    console.log("HEADERS TO FILTERS=",result)
    setFilterHeaders(true);
    
  };

  const getGames2 = async () => {
    let filters = {
      getgames: true,
    };

    let _reducer = { userState: userState, dispatchUser: dispatchUser };
    let page2=0
    let result = await getGalaxyList(page2, 0, 0, filters, _reducer);
    console.log("GETGAMES PAGE=",page," : RESULT=",result)
  };

  getHeaders();
  

  console.log("CONTADOR DE RECARGAS", rcount,count);

  useEffect(() => {
    console.log("effect1");
    setRcount(rcount + 1);
    count = ++count;
    getList(page, filter);
    console.log("GALAXY:USERDATA FROM REDUCER:", userState.userData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData]);

  useEffect(()=>{
    if(filterHeaders){
      console.log("SET FILTERM HEADERS USE EFFECT")
      getGames2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterHeaders]);

  return (
    <>
      <AllFiltersComponent
        titleHeader="My Galaxy Filters"
        filterData={dataToFilters}
        submitFilter={submitFilter}
        setSubmitFilter={setSubmitFilter}
      />

      {stateList ? <GridPlanet list={stateList} /> : <div> Loading...</div>}

      <div>
        <Button
          id="prv"
          onClick={handlerPreviousClick}
          style={{
            visibility: page !== 0 ? "visible" : "hidden",
          }}
          animated
          size="large"
          color="purple"
        >
          <Button.Content visible>Previous</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow left" />
          </Button.Content>
        </Button>
        <Button
          id="nxt"
          onClick={handlerNextClick}
          style={{
            float: "right",
          }}
          animated
          size="large"
          color="purple"
          className={next > 0 ? "" : "hide"}
        >
          <Button.Content visible>Next</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow right" />
          </Button.Content>
        </Button>
      </div>
    </>
  );
};

export default Galaxy;
