import {React,useReducer} from "react";
import Dashboard from "../../components/Metrics/Dashboard";
import {MetricsContext,metricsInitialState} from "../../services/metricsContext";
import {metricsReducer} from "../../services/metricsReducer";

import { Container, Segment } from "semantic-ui-react";

console.log("METRICS-PAGE-MODULE-INIT");

const Metrics = () => {
    console.log("---METRICS-PAGE-INIT---");
    
    const [metricsState,dispatchMetrics]= useReducer(metricsReducer,metricsInitialState);
    sessionStorage.setItem("metricsState",JSON.stringify(metricsState));

    return <MetricsContext.Provider value={[metricsState,dispatchMetrics]}>
                <Container style={{ color: "#F8F9F9", padding: "60px", backgroundColor: "#000000" }}>
                    <Segment style={{ backgroundColor: "black" }}>
                        <br/><br/><br/>
                        <Dashboard />
                    </Segment>
                </Container>
           </MetricsContext.Provider>
}

export default Metrics;

/*

 <div className="ui container metrics-loading">
                    <br/><br/><br/>
                    <Dashboard />
                </div>

*/