//import { useEffect,useState } from 'react';
import { Card, Icon, Image, List, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./cardPlanet.scss";
import { useEffect, useState } from "react";
import LikeHeart from "../commons/likeHeart";
import { favCheck } from "../../services/Graphql/Game/updateGame.js";
import { appParams } from "../../services/appParams";
import { format } from "date-fns";
import { emotionyLanguage } from "../../services/Game/emotionyLanguage";
import { userData, userId } from "../../services/userParams";
import { FrontSide } from "three";

var imageBack = "/assets/images/imageDummybn.png";

console.log("CARD-PLANET-COMPONENT-MODULE-INIT");

const CardPlanet = (props) => {

  console.log("-------CARD-PLANET-COMPONENT-INIT------");
  
  console.log("CARD PLANET PROPS", props);
  console.log("CARD PLANET USERDATA", userData);

  console.log("CARD PLANET FAVORITES", `"${props.item.favorites}"`);
  console.log("CARD PLANET FAVORITES.length", props.item.favorites.length);
  if (props.item.favorites.length > 0 && !Array.isArray(props.item.favorites)) {
    props.item.favorites = props.item.favorites.split(",");
  } else {
    props.item.favorites = [];
  }
  console.log("MODIFIED CARD PLANET FAVORITES", `"${props.item.favorites}"`);

  const gameId = props.item.idGame;
  const generalData = props.item.generalData;
  let image;
  let to = "/planetdetail/";
  if (imageBack !== image) {
    image = imageBack;
  }
  image = props.item.url;
  to = to + props.item.idGame;

  imageBack = image;

  const user = generalData.username;

  const hashtagsList = props.item.hashtags;

  const cardheader = props.item.textGame || hashtagsList;

  //let dateParse = props.item.timestampEpoch;
  let dateParse = props.item.generalData.date;
  //let dateParse=props.item.requesttime;
  //if (props.item.timestampEpoch === null) { dateParse = new Date().getTime() / 1000; }
  if (dateParse === null) {
    dateParse = new Date().getTime() / 1000;
  }
  //const date = format(new Date(dateParse * 1000), 'Pp');
  const date = format(new Date(dateParse), "Pp");

  const city = "Barcelona";

  const emoLang = new emotionyLanguage();
  const emolist = [];

  props.item.emotions
    .substring(0, props.item.emotions.length - 2)
    .split("],")
    .map((elem) => {
      var emobi, em1, em2, value;
      [emobi, value] = elem
        .substring(5)
        .split(",")
        .map((el) => el);
      [em1, em2] = emobi.split("_").map((el) => el);

      return emolist.push([
        emoLang.getEmotion(em1 - 1, em2 - 1),
        parseInt(value),
      ]);
    });

  var EMOLISTCARDNUM = 5;
  var emolistCard = "",
    emolistValues = [],
    MAXEMOVALUE = 120,
    listCount = 0;
  for (
    let emovaluemaxcount = MAXEMOVALUE;
    emovaluemaxcount >= 0;
    emovaluemaxcount = emovaluemaxcount - 20
  ) {
    for (var count = 0; count < emolist.length; count++) {
      if (listCount === EMOLISTCARDNUM) break;
      var emo, value;
      [emo, value] = emolist[count];
      if (value === emovaluemaxcount) {
        emolistCard = emolistCard + emo + ", ";
        emolistValues.push(value);
        listCount++;
      }
    }
  }
  emolistCard = emolistCard.substring(0, emolistCard.length - 2);

  var principalemotions = emolistCard;

  var [tags] = useState(props.item.tags);
  var [favorite, setFavorite] = useState(props.item.favorite);
  var [likes, setLikes] = useState(0);
  var [listTags, setListTags] = useState(null);

  const likeCheck = async (liked, favorites, planetOperation) => {
    favCheck(
      liked,
      gameId,
      userId,
      userData.currentFavoritePlanetIds,
      userData.currentFavoritePlanetIdsCount,
      favorites,
      planetOperation
    );
    var lks = likes;
    if (liked) {
      lks = ++lks;
    } else {
      lks = --lks;
    }
    setLikes(lks);
    setFavorite(liked);
  };

  const tagList = () => {
    if (tags) {
      const listTags = tags.map((t, i) => {
        return (
          <>
            <Icon name="tag" key={t} />
            <span>{t}</span>
          </>
        );
      });
      setListTags(listTags);
    }
  };

  const setLks = () => {
    setLikes(props.item.favorites.length > 0 ? props.item.favorites.length : 0);
  };

  const setFav = () => {
    if (props.item.favorites && props.item.favorites.indexOf(userId) !== -1) {
      likeCheck(true, props.item.favorites, props.item.planetOperation);
    } else {
      likeCheck(false, props.item.favorites, props.item.planetOperation);
    }
  };

  window.onscroll = () => {
    const html = document.documentElement;
    const elements = document.getElementsByClassName("card-column");
    if (appParams.device === "mobile") {
      for (let i = 0; i < elements.length; i++) {
        var rect = elements[i].getBoundingClientRect();
        if (rect.bottom < html.clientHeight + 300 && rect.y > -300) {
          elements[i].classList.add("show");
        } else {
          elements[i].classList.remove("show");
        }
      }
    }
  };

  useEffect(() => {
    if (props.item) {
      tagList();
      setFav();
      setLks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <Card fluid key={gameId + "-card"}>
        <Card.Content>
          <List>
            <List.Item>
              <List.Content floated='left'>
                <LikeHeart
                  favorite={favorite}
                  likeCheck={() =>
                    likeCheck(
                      !favorite,
                      props.item.favorites,
                      props.item.planetOperation
                    )
                  }
                  carousel={props.carousel}
                />
                {likes}
              </List.Content>

              <List.Content floated='right'>
                <Icon
                  name="share alternate"
                  className={`${props.carousel ? "hide" : ""}`}
                />
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>

        <Link to={to} item={props.item.idGame}>
          <Image src={image} size="fluid" />
        </Link>

        <Card.Content>
          <Card.Header><Header as="h2">{cardheader}</Header></Card.Header>
          <Card.Meta>
            <span className='date'>by {user}</span>
          </Card.Meta>
          <Card.Description>
            <List>
              <List.Item> {date} </List.Item>
              <List.Item> {city} </List.Item>
              <List.Item> {principalemotions} </List.Item>
              <List.Item className={`tags ${props.carousel ? "hide" : ""}`}>
                {listTags}
              </List.Item>
            </List>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <List divided verticalAlign='middle'>
            <List.Item>
              <List.Content floated='left'>
                <Icon name="btc" /> To sell
              </List.Content>

              <List.Content floated='right'>
                <Icon name="cart arrow down" /> Tokenize
              </List.Content>
            </List.Item>            
          </List>
        </Card.Content>
      </Card>
    </>
  );
};

export default CardPlanet;
