import { Image } from "semantic-ui-react";

console.log("PLANETIMAGE-COMPONENT-MODULE-INIT");

const PlanetImage = (props) => {
  console.log("---PLANETIMAGE-COMPONENT-INIT---");
  return (
    <>
      <Image src={props.image} wrapped ui={true} />
    </>
  );
};

export default PlanetImage;
