import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { setGalaxyData } from '../../../services/userParams';

console.log("USER-MENU-COMPONENT-MODULE-INIT");

const UserMenu = (props) => {
  
  console.log("---USER-MENU-COMPONENT-INIT---");

  const setGalaxy = (value) => {
    sessionStorage.setItem('type', value);
    if (value === 'user') {
      sessionStorage.removeItem('favGroup');
    }
    setGalaxyData(value);
  }

  return (
    <>
      <Menu fluid vertical className={`${props.userVisible ? '' : 'hide'}`}>        
        <Menu.Item
          as={Link}
          name='myprofile'
          to={props.route1}
        />

        <Menu.Item
          as={Link}
          name='mygalaxy'
          to={props.route2}
          onClick={() => setGalaxy('user')}
        />  

        <Menu.Item
          as={Link}
          name='myfavorites'
          to={props.route2}
          onClick={() => setGalaxy('favorites')}
        />

        <Menu.Item
          as={Link}
          name='mymetrics'
          to='/metrics'
        />

        <Menu.Item
          as={Link}
          name='mypayperdata'
          to='/payperdata'
        />
        
      </Menu>
    </>
  )
}
export default UserMenu;