import React from "react";
import { useState, useContext, useEffect } from "react";
import { PayPerDataContext } from "../../services/payperDataContext";
import { Grid, Divider, Header, Icon, Dropdown } from "semantic-ui-react";
import { userService } from '../../services/userParams';
import PayPerDataFilters from './PayPerDataFilters.jsx'
import { wsSubscriptionQuery } from "../../services/WebSockets/wsSubscriptionQuery";
import { createHashOperation } from "../../core/blockchain/blockchainSigner";
import { Label, Form, Card, Button, Input, Segment, Message } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import SubscriptionComponent from "./SubscriptionComponent";


console.log("PAYPERDATA-COMPONENT-MODULE-INIT");

function PayperDataComponent() {


    console.log("---PAYPERDATA-COMPONENT-INIT---");

    const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
    const [payperDataState, setpayperDataState] = useState("initial");
    var [user, setUser] = useState(null);

    var userDataSubs = undefined;

    const assignUser = async () => {
        userDataSubs = userService.getUserData().subscribe(u => {
            if (u && u.userAddressId) {
                setUser(u);
                console.log("PAYPERDATA: user =", u);
                setPayPerDataFilters();

            } else {
                // res de res
                setUser(null);
            }
        });
    }
    var setPayPerDataFilters = async (user) => {
        console.log("setpayperDataFilters")
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        assignUser();

        return (() => {
            userDataSubs.unsubscribe();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (

        <Grid columns={2}>
            <Grid.Row>
                <br />
                <Header as="h1" icon textAlign="center" style={{ color: "#F8F9F9" }}>
                    <Icon name="building outline" inverted circular />
                    <Header.Content>

                        <div>
                            <br />
                            {user && user.user ? user.user + " at" : ""} My Pay x Data
                            <br />

                            <div>
                                {user && user.userAddressId &&
                                    `${user.userAddressId.slice(0, 6)}...${user.userAddressId.slice(
                                        user.userAddressId.length - 4,
                                        user.userAddressId.length
                                    )}`}
                            </div>



                        </div>
                    </Header.Content>
                </Header>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <PayPerDataFilters />
                    <SubscriptionComponent user={user} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}


export default PayperDataComponent

