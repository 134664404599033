import React from "react";
import { Dropdown, Label, Menu } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";

const options = [
  {
    key: "earlymorning",
    text: "Early Morning",
    value: "earlymorning",
    description: "From 06:00 AM to 09:00 AM"
  },
  {
    key: "latemorning",
    text: "Late Morning",
    value: "latemorning",
    description: "From 9:00 AM to 12:00 AM (Noon)"
  },
  {
    key: "earlyafternoon",
    text: "Early Atternoon",
    value: "earlyafternoon",
    description: "From 12:00 (Noon) AM to 15:00 PM"
  },
  {
    key: "lateafternoon",
    text: "Late Atternoon",
    value: "lateyafternoon",
    description: "From 15:00 PM to 18:00 PM"
  },
  {
    key: "earlyevening",
    text: "Early Evening",
    value: "earlyevening",
    description: "From 18:00 PM to 21:00 PM"
  },
  {
    key: "lateevening",
    text: "Late Evening",
    value: "lateevening",
    description: "From 21:00 PM to 24:00 PM (MidNight, 0:00)"
  },
  {
    key: "earlynight",
    text: "Early Night",
    value: "earlynight",
    description: "From 24:00 PM (MidNight, 0:00) to 03:00 AM"
  },
  {
    key: "latenight",
    text: "Late Night",
    value: "latenight",
    description: "From 03:00 AM to 06:00 AM"
  }
];

const HoursGames = () => {
  const renderLabel = (label) => ({
    color: "purple",
    content: `${label.text}`,
    icon: "check"
  });
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const saveOptionsToContext=(e,{value})=>{
   
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_HOURS_RANGE",payload:value})

  }


  return (
    <>
      <Menu fluid inverted stackable>
        <Menu.Item style={{ fontSize: "18px", width: "190px" }}>
          Hours Range
          <Label color="purple" size="large">
          { 
              0
          }
          </Label>
        </Menu.Item>

        <Dropdown
          fluid
          openOnFocus
          search
          clearable
          style={{ background: "#34495E" }}
          placeholder="Search hours range or select them from the list"
          multiple
          selection
          options={options}
          renderLabel={renderLabel}
          onChange={saveOptionsToContext}
        />
      </Menu>
    </>
  );
};

export default HoursGames;
