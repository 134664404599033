import { API, graphqlOperation } from "aws-amplify";
import { nextTokenList } from "../../userParams";

console.log("QUERYMARKETPLACE-MODULE-INIT");

const items = `
    idGame
    planetOperation
    userAddressId
    description
    emotions
    emotionsFilter
    favorites
    gameDesign
    generalData {
      date
      deviceId
      email
      emoViewConnectionID
      gameId
      geoloc {
        accuracy
        latitude
        longitude
      }
      pngId
      posnegPlanet
      username
    }
    hashtags
    likes
    quote
    requesttime
    sourceip
    status
    tags
    textGame
    timestampEpoch
    
  `;

//query Model:
//   query MyQuery {
//     listeMaster20GamesPartiQL(filter: {status: {contains: "published"}}, limit: 20) {
//       items {
//         idGame
//         status
//       }
//       nextToken
//     }
//   }

const prepareParams = (nextToken, limit, status) => {
  var params = null;
  if (nextToken) {
    params = `(filter: {status: {contains: "${status}"}}, limit: ${limit},nextToken: ${nextToken})`;
  } else {
    params = `(filter: {status: {contains: "${status}"}}, limit: ${limit})`;
  }
  return params;
};

export const prepareMarketPlaceQuery = (
  nextToken,
  limit,
  onlyIdGame,
  status
) => {
  let params = prepareParams(nextToken, limit, status);
  let result = onlyIdGame ? `items{idGame}` : `items{${items}}`;
  let retQuery = `
        query MyQuery {
            listeMaster20GamesPartiQL ${params} {
                nextToken
                ${result}
            }
        }
       `;
  return retQuery;
};

export const getMarketPlacePlanetList = async (query, setStateList) => {
  const pathToAwsLowResolutionPlanets =
    "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";

  console.log("MARKETPLACEQUERY", query);
  let listaux = await API.graphql(graphqlOperation(query));
  var tempList = {
    data: {
      listMasterGames: listaux.data.listeMaster20GamesPartiQL,
    },
  };

  for (let listElement of tempList.data.listMasterGames.items) {
    listElement.url =
      pathToAwsLowResolutionPlanets + listElement.idGame + ".png";
    listElement.queryMarketplace = true;
  }

  console.log("MARKETPLACE QUERYRESULTLIST ", tempList);

  setStateList(tempList);
};
