import { Icon } from 'semantic-ui-react'

console.log("LIKE-HEART-COMPONENT-MODULE-INIT");

const LikeHeart = (props) => {

  console.log("----LIKE-HEART-COMPONENT-INIT---");
  
  return (
    <div className={`toggle-heart ${props.favorite ? 'checked' : ''}`}>
      <div className={`${props.carousel ? 'hide' : ''}`}>
        <Icon name={props.favorite ? 'heart' : 'heart outline'} onClick={props.likeCheck} />
      </div>
      <div className={`${props.carousel ? '' : 'hide'}`}>
        <Icon name='heart'/>
      </div>
    </div>
  )
}
export default LikeHeart;