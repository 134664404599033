import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../../core/a-cf';
import { getUserAWS } from './queryUser';
Amplify.configure(awsconfig);

console.log("UPDATEUSER-MODULE-INIT");

const updateRealName = (userId, realName) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile",
                            userAddressId: "${userId}",
                            user: "${realName}"}) {
        userAddressId
        user
        }
    }
    `
    //user=realName
}
export const updateRealUserName = async (userId, realName) => {
    var userMutation = await API.graphql(
        graphqlOperation(
            updateRealName(userId, realName)
        ));
    const user={
        "data": {
            "updateUser" : {
                "userAddressid" : userMutation.data.updateUser.userAddressId,
                "realName": userMutation.data.updateUser.user
            }
        }
    }
    return user;
}

const updateDesc = (userId, description) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile",
                            userAddressId: "${userId}", 
                            userDescription: "${description}"}) {
        userAddressId
        userDescription
        }
    }
    `
}
export const updateUserDescription = async (userId, description) => {

    const user = await API.graphql(
        graphqlOperation(
            updateDesc(userId, description)
        ));

    return user;
}

const updateEmail = (userId, email) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile", 
                            userAddressId: "${userId}", 
                            email: "${email}"}) {
        userAddressId
        email
        }
    }
    `
}
export const updateUserEmail = async (userId, email) => {

    const user = await API.graphql(
        graphqlOperation(
            updateEmail(userId, email)
        ));

    return user;
}

const updateTwitter = (userId, account) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile", 
                            userAddressId: "${userId}", 
                            twitterAccount: "${account}"}) {
        userAddressId
        twitterAccount
        }
    }
    `
}
export const updateUserTwitter = async (userId, account) => {

    const user = await API.graphql(
        graphqlOperation(
            updateTwitter(userId, account)
        ));

    return user;
}

const updateInstagram = (userId, account) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile",
                            userAddressId: "${userId}", 
                            instagramAccount: "${account}"}) {
        userAddressId
        instagramAccount
        }
    }
    `
}
export const updateUserInstagram = async (userId, account) => {

    const user = await API.graphql(
        graphqlOperation(
            updateInstagram(userId, account)
        ));

    return user;
}

const updateReddit = (userId, account) => {
    return `
    mutation MyMutation {
        updateUser(input: {
                           userOperation: "userProfile",
                           userAddressId: "${userId}", 
                           redditAccount: "${account}"}) {
        userAddressId
        redditAccount
        }
    }
    `
}
export const updateUserReddit = async (userId, account) => {

    const user = await API.graphql(
        graphqlOperation(
            updateReddit(userId, account)
        ));

    return user;
}

const updateDiscord = (userId, account) => {
    return `
    mutation MyMutation {
        updateUser(input: { userOperation: "userProfile", 
                            userAddressId: "${userId}", 
                            discordAccount: "${account}"}) {
        userAddressId
        discordAccount
        }
    }
    `
}
export const updateUserDiscord = async (userId, account) => {

    const user = await API.graphql(
        graphqlOperation(
            updateDiscord(userId, account)
        ));

    return user;
}

const updateAvatar = (userId, avatar) => {
    return `
    mutation MyMutation {
        updateUser(input: {userOperation: "userProfile",
                           userAddressId: "${userId}", 
                           avatarLink: "${avatar}"}) {
        userAddressId
        avatarLink
        }
    }
    `
}
export const updateUserAvatar = async (userId, avatar) => {

    const user = await API.graphql(
        graphqlOperation(
            updateAvatar(userId, avatar)
        ));

    return user;
}

const updateSessions = (userId, ses) => {
    return `
    mutation MyMutation {
        updateUser(input: {userOperation: "userSummary",
                           userAddressId: "${userId}",
                           currentSessionIds: ["${ses[0]}","${ses[1]}","${ses[2]}","${ses[3]}","${ses[4]}"]}) {
        userAddressId
        currentSessionIds 
        }
    }
    `
    //sessions=currentSessionIds
}
export const updateUserSessions = async (userId, sessions) => {
    var userMutation = await API.graphql(
        graphqlOperation(
            updateSessions(userId, sessions)
        ));
        console.log("userMutation",userMutation);
        console.log("sessions",sessions);

    const user={
        "data": {
            "updateUser": {
                "sessions" : userMutation.data.updateUser.currentSessionIds,
                "userAddressId" : userMutation.data.updateUser.userAddressId
            }
        }
    }
    console.log(user);
    return user;
}

//  MUTATION MODEL TO CREATION TO INSERT NEW SUBSCRIPTIONID, AND SUBSCRIPTIONKEY OR INSERT NEW QUERYHASH
//  IN USERSUMMARY 
/*
`
mutation MyMutation {
  updateUser(input: {userAddressId: "0x1465014386d7174885807A89617B3B0f3772EB39", userOperation: "userSummary", subscriptionIds: ["124140","453334"]}) {
    userAddressId
    userOperation
    subscriptionIds
  }
}
`

    subscriptionId: String
	isSubscripiton: Boolean
	subscriptionState: String
	subscriptionType: String
	subscriptionExpiration: AWSTimestamp
	subscriptionCreation: AWSTimestamp
	subscriptionDuration: Int

	queryOperationsCounter: Int
	hashQuery: String
	graphQLQuery: String
	metricFilters: String
	metricResults: String

    
////////////////////////////////////////////////////////

SUBSCRIPTION MODEL:
===================
react ---> appSync ---> lambda query --> masterUser --> {subscription: , query:} -> react 

masterUser: 
             subscription#hash  subcription: true , state: active / inactive / with_debt / blocked / paused  
             {query:query#hash , data:{ hash, query, metricFilters, metricResults } , date: xxx, location: yyy }

////////////////////////////////////////////////////////

userSubscription#hashOperation		
    hashOperation	
    timestamp	
    subscriptionId	
    isSubscripiton	
    subscriptionState	
    subscriptionType	
    subscriptionExpiration	
    subscriptionCreation	
    subscriptionDuration	
    queryOperationsCounter
					
userQueryData#hashOperation		
    hashOperation	
    timestamp	
    subscriptionId	
    hashQuery	
    graphQLQuery	
    metricFilters	
    metricResults			
								
*/

const createSubscription= (userId,dataSubscription) => {
    return `
        mutation MyMutation {
            updateUser(input: { userOperation: "userSubscription#${dataSubscription.hashOperation}", 
                                userAddressId: "${userId}", 
                                hashOperation : "${dataSubscription.hashOperation}"	
                                timestamp : ${dataSubscription.timestamp}	
                                subscriptionId : "${dataSubscription.subscriptionId}"	
                                isSubscripiton : "${dataSubscription.isSubscripiton}"	
                                subscriptionState : "${dataSubscription.subscriptionState}"	
                                subscriptionType : "${dataSubscription.subscriptionType}"	
                                subscriptionExpiration : ${dataSubscription.subscriptionExpiration}	
                                subscriptionCreation : ${dataSubscription.subscriptionCreation}	
                                subscriptionDuration : ${dataSubscription.subscriptionDuration}	
                                queryOperationsCounter : 0
                            }) {
            userAddressId
            userOperation
            subscriptionId
            }
    `
}

export const updateUserSubscription = async (userId, subscriptionIds,subscriptionKeys) => {
    var userMutation = await API.graphql(
        graphqlOperation(
            updateSubscription(userId, subscriptionIds,subscriptionKeys)
        ));
        console.log("userMutation",userMutation);
        console.log("subscriptionIds:",subscriptionIds);
        console.log("subscriptionKeys:",subscriptionKeys);
        

    const user={
        "data": {
            "updateUser": {
                "subscriptionIds" : userMutation.data.updateUser.subscriptionIds,
                "subscriptionKeys" : userMutation.data.updateUser.subscriptionKeys,
                "userAddressId" : userMutation.data.updateUser.userAddressId
            }
        }
    }
    console.log(user);
    return user;
}

const updateSubscription= (userId,subscriptionIds,subscriptionKeys) => {
    return `
        mutation MyMutation {
            updateUser(input: { userOperation: "userSummary", 
                                userAddressId: "${userId}", 
                                subscriptionIds: "${subscriptionIds}",
                                subscriptionKeys: "${subscriptionKeys}"
                            }) {
            userAddressId
            userOperation
            subscriptionIds
            subscriptionKeys
            }
    `
}

export const updateUserQueryHashes = async (userId, queryHashes) => {
    var userMutation = await API.graphql(
        graphqlOperation(
            updateQueryHashes(userId, queryHashes)
        ));
        console.log("userMutation",userMutation);
        console.log("queryHashes:",queryHashes);
        
        

    const user={
        "data": {
            "updateUser": {
                "queryHashes" : userMutation.data.updateUser.queryHashes,
                "userAddressId" : userMutation.data.updateUser.userAddressId
            }
        }
    }
    console.log(user);
    return user;
}

const updateQueryHashes= (userId,queryHashes) => {
    return `
        mutation MyMutation {
            updateUser(input: { userOperation: "userSummary", 
                                userAddressId: "${userId}", 
                                queryHashes: "${queryHashes}"                             
                            }) {
            userAddressId
            userOperation
            queryHashes
            }
    `
}


const updateTags = (userId, tags) => {
    let tag = '';
    tags.map(t => {
        if (t !== '') {
            return tag = tag.concat(`${t}`);
        } else return null;
    });
    let tagsFilter = '';
    tags.map((t, i) => {
        let coma = '';
        if (i > 0) {
            coma = ',';
        }
        return tagsFilter = tagsFilter + (`${coma}${t}`);
    });
    return `
    mutation MyMutation {
        updateUser(input: {userOperation: "userSummary",
                           userAddressId: "${userId}", 
                           currentTags: "${tagsFilter}"}) {
        userAddressId
        currentSessionIds
        }
    }
    `
    //sessions=currentSessionIds
}
export const updateUserTags = async (userId, tag, remove) => {
    const user = await getUserAWS(userId);
    console.log("USER",user);

    //let tags = user.data.getMasterUserTable.tags;
    let tags = user.data.listMasterUsers.items[0].tags;
    const pos = tags.indexOf(tag);
    if (pos > -1 && remove) {
        tags.splice(pos, 1);
    } else if (pos < 0 && !remove) {
        tags.push(tag);
    }
    console.log("UPDATE TAGS",updateTags(userId, tags));
    var userUpdateMutation = await API.graphql(
        graphqlOperation(
            updateTags(userId, tags)
        ));

    const userUpdate={
        "data":{
            "updateUser": {
                "userAddressId" : userUpdateMutation.data.updateUser.userAddressId,
                "sessions" : userUpdateMutation.data.updateUser.currentSessionIds
            }
        }
    }    
    return userUpdate;
}
