import { List } from "semantic-ui-react";
import React, { useEffect, useState } from "react";


console.log("LOCATIONDETAIL-COMPONENT-MODULE-INIT");

export const LocationDetail = (props) => {
  console.log("---LOCATIONDETAIL-COMPONENT-INIT---");
  const { date, location } = props;
  return (
    <List>
      <List.Item>
        <List.Content floated='right'>
          {date}
        </List.Content>
        <List.Content className="emotion__name">Date</List.Content>
      </List.Item>

      <List.Item>
        <List.Content floated='right'>
          <strong>{location}</strong>
        </List.Content>
        <List.Content className="emotion__name">Location</List.Content>
      </List.Item>
    </List>
  );
};
