import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../../core/a-cf";
import { resetNextTokenList } from "../../userParams";

export const getGalaxyList = async (
  page,
  minPlanet,
  maxPlanet,
  filters,
  _userReducer
) => {
  var userData = _userReducer.userState.userData;
  /*
   * {userState,dispatchUser}=userReducer;
   * page = 10 planets
   * List Structure:
   * const resultList= data:{
   *      listMasterGames:{
   *          items:[
   *              0: {game1},
   *              1: {game2},
   *              2: {game3}
   *              ...
   *          ]
   *      }
   * }
   * filters.getheaders = true = getlist whit plahetids hashtags, locations, emos, tags
   */
  if (filters) {
    if (filters.getheaders || filters.getgames) {
      // CREATE REQUEST TO GET ALL FILTER DATA
      var nextToken = -1;
      var countQueries = 0;
      var resultPartial = undefined;

      

      while (nextToken !== null) {
        
        let comprobateNextToken = false;
        // NEXT TOKEN COMPROBATION
        let nextTokensFromReducer=undefined;
        // CASE HEADERS
        if(filters.getheaders){
          console.log("QUERYGALAXY: NEXT TOKEN COMPROBATION : GETHEADERS");
          nextTokensFromReducer=_userReducer.userState.userData.nextTokensHeader;
        }
        console.log("QUERYGALAXY: NEXT TOKEN=",nextTokensFromReducer);
        // CASE GAMES
        if(filters.getgames){
          console.log("QUERYGALAXY: NEXT TOKEN COMPROBATION : GETGAMES");
          nextTokensFromReducer=_userReducer.userState.userData.nextTokens;
        }
        // FIRST EXECUTION CASE
        if (nextTokensFromReducer.length === 0) {
          console.log("QUERYGALAXY: FIRST EXECUTION, NOT COMPROBATE NEXTOKEN");
        }
        // SET PAGES
        if(filters.getgames){
          console.log("QUERYGALAXY: SET PAGES (GETGAMES)");
          if(page > _userReducer.userState.userData.nextTokensHeader.length){
            console.log("QUERYGALAXY: SET PAGES: PAGE HIGHER:",page, "CHANGE!!!");
            page =  _userReducer.userState.userData.nextTokensHeader.length - 1 ;
            console.log("QUERYGALAXY: SET PAGES: DOWN TO",page);
          }
          if(page < 0){
            console.log("QUERYGALAXY: SET PAGES: PAGE LOWER:",page, "CHANGE!!!");
            page = 0;
            console.log("QUERYGALAXY: SET PAGES: UP TO",page);
          }
          // IF PAGE NOT IN REDUCER, SET PAGE:
          let isPageInReducer=false
          for(let i=0;i<_userReducer.userData.pages.length;i++){
            let pageInReducer=_userReducer.userData.pages[i];
            if(page===pageInReducer){
              isPageInReducer=true;
              break;
            }
          }

          if(!isPageInReducer){
            await _userReducer.dispatchUser({
              type: "SET_PAGE",
              payload: page
            });
          }
          nextToken=_userReducer.userState.userData.nextTokensHeader[page];
          console.log("QUERYGALAXY: SET PAGES : PAGE=",page," NEXTOKEN=",nextToken); 
        }

        // NEXTOKEN COMPROBATION LOOP
        for (
          let i = 0;
          i < nextTokensFromReducer.length;
          i++
        ) {
          let ntq = nextTokensFromReducer[i];
          console.log(
            "QUERYGALAXY: COMPROBATE NEXTOKEN",
            comprobateNextToken,
            " NT= ",
            ntq,
            " nextToken=",
            nextToken
          );
          if (ntq === nextToken) {
            comprobateNextToken = true;
            console.log("QUERYGALAXY: NT=nextToken");
            break;
          }
        }

        // CONTROL NEXTOKEN IN REDUCER
        // CASE HEADERS
        if (!comprobateNextToken){
          console.log("QUERYGALAXY: CONTROL NEXTTOKEN IN REDUCER: !comprobateNextToken=TRUE"); 
          if(filters.getheaders){
            console.log("QUERYGALAXY: GETHEADERS SET_NEXTTOKENHEADER"); 
            await _userReducer.dispatchUser({
              type: "SET_NEXTTOKENHEADER",
              payload: nextToken,
            });
          }
          // CASE GAMES
          if(filters.getgames){
            console.log("QUERYGALAXY: GETGAMES SET_NEXTTOKEN"); 
            await _userReducer.dispatchUser({
              type: "SET_NEXTTOKEN",
              payload: nextToken,
            });
          }
        }else{
          console.log("QUERYGALAXY: CONTROL NEXTTOKEN IN REDUCER: !comprobateNextToken=FALSE"); 
        }

        // CORRECTING NEXT NEXT TOKEN (LAMBDA FUNCTION WITH NEXTOKEN-1)
        console.log("QUERYGALAXY: CORRECTING NEXT NEXT TOKEN (LAMBDA FUNCTION WITH NEXTOKEN-1)"); 
        if (nextToken === -1) {
          console.log("QUERYGALAXY: NEXTOKEN=NULL");
          nextToken = null;
        } else {
          console.log("QUERYGALAXY: NEXTOKEN= -1");
          nextToken -= 1;
        }
        
        // IF COMPROBATION, PROCEED:
        if (!comprobateNextToken) {
          console.log("QUERYGALAXY: !COMPROBATE NEXT TOKEN");
          countQueries++;
          console.log("QUERYGALAXY: COUNTQUERIES",countQueries);

          // PREPARE QUERY
          var query = undefined;
          console.log("QUERYGALAXY: PREPARE QUERY");

          if(filters.getheaders){
            console.log("QUERYGALAXY: QUERY ITEM HEADERS");
            query = queryItemHeaders(userData.userAddressId, nextToken);
          }
          if(filters.getgames){
            console.log("QUERYGALAXY: QUERY ITEM GAMES");
            query = queryItemGames(userData.userAddressId, nextToken);
          }
          console.log("QUERYGALAXY: QUERY=",query);
          // EXECUTE QUERY
          resultPartial = await API.graphql(graphqlOperation(query));
          nextToken = resultPartial.data.listeMaster20GamesPartiQL.nextToken;

          if(filters.getgames){
            console.log("QUERYGALAXY: GETGAMES . NEXTTOKEN=NULL");
             nextToken=null; 
          }
          console.log("QUERYGALAXY: NEXT-TOKEN=", nextToken);

          console.log(
            "QUERYGALAXY:COUNT QUERIES",
            countQueries,
            " resultPartial:",
            resultPartial
          );

          // COMPROBATE ITEMS IN REDUCER:
          let comprobator = false;
         
          console.log(
            "QUERYGALAXY: COMPROBATE ITEMS IN RESULTPARTIAL",
            resultPartial.data.listeMaster20GamesPartiQL.items
          );
          for (
            let i = 0;
            i < resultPartial.data.listeMaster20GamesPartiQL.items.length;
            i++
          ) {
            var itemPartial =
              resultPartial.data.listeMaster20GamesPartiQL.items[i];
            for (
              let j = 0;
              j < _userReducer.userState.userData.items.length;
              j++
            ) {
              var item = _userReducer.userState.userData.items[j];
              // CASE HEADERS
              if (filters.getheaders && item.idGame === itemPartial.idGame) {
                console.log("QUERYGALAXY: CASE HEADERS: EQUAL ITEM.IDGAME=",item.idGame);
                comprobator = true;
                break;
              }
              // CASE GAME
              if (filters.getgames && item.userAddressId === itemPartial.userAddressId) {
                console.log("QUERYGALAXY: CASE GAME: EQUAL ITEM.USERADDRESSID=",item.userAddressId);
                comprobator = true;
                break;
              }

            }
          }

          // IF NOT, SET THIS!
          if (!comprobator) {
            console.log("QUERYGALAXY: !COMPROBATOR");
            if(filters.getheaders){
              console.log("QUERYGALAXY: !COMPROBATOR : SET_ITEMS_HEADER ");
              await _userReducer.dispatchUser({
                type: "SET_ITEMS_HEADER",
                payload: resultPartial.data.listeMaster20GamesPartiQL,
              });
            }
            if(filters.getgames){
              console.log("QUERYGALAXY: !COMPROBATOR : SET_ITEMS_GAMES ");
              await _userReducer.dispatchUser({
                type: "SET_ITEMS_GAMES",
                payload: resultPartial.data.listeMaster20GamesPartiQL,
              });
            }
          }

          // console.log(
          // "QUERYGALAXY: COUNT QUERIES",
          // countQueries,
          // " RESULTTOTAL=",
          // _userReducer.userState.userData.items
          // );
        }
        // }else{
        //     console.log("QUERYGALAXY: NEXT TOKEN EXITS, COMPROBATE=TRUE, RESULTTOTAL=",
        //     _userReducer.userState.userData.items);
        // }
      }
      if(filters.getheaders){
        console.log("QUERYGALAXY: GETHEADERS: RETURN_userReducer.userState.userData.items",_userReducer.userState.userData.items);
         return (_userReducer.userState.userData.items);
      }
      if(filters.getgames){
        console.log("QUERYGALAXY: GETGAMES:");
        if(resultPartial){
          console.log("QUERYGALAXY: GETGAMES: RESULTPARTIAL=resultPartial.data.listeMaster20GamesPartiQL",resultPartial.data.listeMaster20GamesPartiQL);
          return (resultPartial.data.listeMaster20GamesPartiQL);
        }else{
          console.log("QUERYGALAXY: GETGAMES: NOT RESULTPARTIAL:");
          console.log("----QUERYGALAXY: OBTAIN FROM REDUCER")
          return (null);
        }
      }
    }
  }
};
/*
 *  GAMEHEADERS =
 *   Locations,hashtags,tags,dates
 */

const queryItemHeaders = (userAdressId, nextToken) => {
  var filterNextToken = "";
  if (nextToken) {
    filterNextToken = `nextToken: "${nextToken}",`;
  }
  return `query MyQuery {
            listeMaster20GamesPartiQL (${filterNextToken} userAddressId: "${userAdressId}") {
              items {
                idGame
                generalData {
                  date
                }
                emotionsFilter
              }
              nextToken
            }
          }
          `;
};

const queryItemGames = (userAdressId, nextToken) => {
    var filterNextToken = "";
    if (nextToken) {
      filterNextToken = `nextToken: "${nextToken}",`;
    }
    return `query MyQuery {
              listeMaster20GamesPartiQL (${filterNextToken} userAddressId: "${userAdressId}") {
                items {
                  idGame
                  planetOperation
                  userAddressId
                  connectionid
                  connectionData
                  sourceip
                  requesttime
                  emotions
                  
                  description
                  gameDesign
                  generalData{
                  
                    gameId
                    emoViewConnectionID
                    posnegPlanet
                    geoloc {
                      accuracy
                      latitude
                      longitude
                    }
                    pngId
                    deviceId
                    email
                    username
                  }
                  hashtags
                  tags
                  quote
                  status
                  textGame
                  timestampEpoch
                  favorites
                  location
                  locationFilter
                  locationPretty
                  likes
                  
                  
                  
                  
                }
                nextToken
              }
            }
            `;
  };
