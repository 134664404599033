import { createContext } from "react";

console.log("GLOBALCONTEXT-MODULE-INIT");

var marketPlaceGrapQlParams={
    status: "published",
    limit:10,
    nextToken: "",
    page:0
}

var marketPlacePlanetResults={
    list: null
}

var userWallet = null;


export const EmoContext = createContext();


export const emoInitialState={
    marketPlaceGrapQlParams:marketPlaceGrapQlParams,
    marketPlacePlanetResults:marketPlacePlanetResults,
    userWallet
}