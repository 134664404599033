import { FilterFrame } from "./FilterFrame";
import { useState, useEffect } from "react";
import { Header, Segment, Divider, Button, Grid } from "semantic-ui-react";
import { FilterComponentBase } from "./FilterComponentBase";

export const AllFiltersComponent = (props) => {
  // dataHashtags
  const { filterData, titleHeader, submitFilter, setSubmitFilter } = props;

  const dataNumbers = [
    "uno",
    "dos",
    "tres",
    "cuatro",
    "cinco",
    "seis",
    "siete",
    "ocho",
    "nueve",
    "diez",
    "once",
    "doce",
    "trece",
    "catorce",
    "quince",
    "dieciséis",
    "diecisiete",
    "dieciocho",
    "diecinueve",
    "veinte",
    "veintiuno",
    "veintidós",
    "veintitrés",
    "veinticuatro",
    "veinticinco",
    "veintiséis",
    "veintisiete",
    "veintiocho",
    "veintinueve",
    "treinta",
  ];

  const hashtagList = [
    { key: "angular", text: "Angular", value: "angular" },
    { key: "css", text: "CSS", value: "css" },
    { key: "design", text: "Graphic Design", value: "design" },
    { key: "ember", text: "Ember", value: "ember" },
    { key: "html", text: "HTML", value: "html" },
    { key: "ia", text: "Information Architecture", value: "ia" },
    { key: "javascript", text: "Javascript", value: "javascript" },
    { key: "mech", text: "Mechanical Engineering", value: "mech" },
    { key: "meteor", text: "Meteor", value: "meteor" },
    { key: "node", text: "NodeJS", value: "node" },
    { key: "plumbing", text: "Plumbing", value: "plumbing" },
    { key: "python", text: "Python", value: "python" },
    { key: "rails", text: "Rails", value: "rails" },
    { key: "react", text: "React", value: "react" },
    { key: "repair", text: "Kitchen Repair", value: "repair" },
    { key: "ruby", text: "Ruby", value: "ruby" },
    { key: "ui", text: "UI Design", value: "ui" },
    { key: "ux", text: "User Experience", value: "ux" },
  ];

  const superEmos = [
    {
      key: "EMO1_0",
      text: "Open",
      value: "EMO1_0",
      description:
        "understanding, confident, friendly, connected, flexible, sympathetic",
    },
    {
      key: "EMO2_0",
      text: "Loving",
      value: "EMO2_0",
      description:
        "considerate, affectionate, sensitive, tender, warm, attracted",
    },
    {
      key: "EMO3_0",
      text: "Happy",
      value: "EMO3_0",
      description: "blissful, joyous, delighted, overjoyed, glad, thankful",
    },
    {
      key: "EMO4_0",
      text: "Interested",
      value: "EMO4_0",
      description:
        "fascinated, intrigued, absorbed, amazed, engrossed, curious",
    },
    {
      key: "EMO5_0",
      text: "Alive",
      value: "EMO5_0",
      description:
        "playful, courageous, energetic, liberated, optimistic, animated",
    },
    {
      key: "EMO6_0",
      text: "Positive",
      value: "EMO6_0",
      description:
        "motivated, constructive, creative, inspired, enthusiastic, brave",
    },
    {
      key: "EMO7_0",
      text: "Peaceful",
      value: "EMO7_0",
      description: "calm, good, at ease, comfortable, pleased, quiet",
    },
    {
      key: "EMO8_0",
      text: "Strong",
      value: "EMO8_0",
      description: "honest, sure, unique, complete, energized, hardy",
    },
    {
      key: "EMO9_0",
      text: "Relaxed",
      value: "EMO9_0",
      description: "glowing, radiant, unhurried, fluid, meditative, centered",
    },

    {
      key: "EMO10_0",
      text: "Angry",
      value: "EMO10_0",
      description: "irritated, enraged, hostile, insulting, frustrate, upset",
    },
    {
      key: "EMO11_0",
      text: "Depressed",
      value: "EMO11_0",
      description:
        "disappointed, discouraged, ashamed, powerless, diminished, guilty",
    },
    {
      key: "EMO12_0",
      text: "Confused",
      value: "EMO12_0",
      description:
        "doubtful, shy, indecisive, perplexed, embarrassed, hesitant",
    },
    {
      key: "EMO13_0",
      text: "Helpless",
      value: "EMO13_0",
      description: "incapable, alone, paralyzed, fatigued, useless, inferior",
    },
    {
      key: "EMO14_0",
      text: "Indiferent",
      value: "EMO14_0",
      description: "insensitive, dull, robotic, tired, cold, preoccupied",
    },
    {
      key: "EMO15_0",
      text: "Afraid",
      value: "EMO15_0",
      description: "fearful, terrified, nervous, anxious, alarmed, panicke",
    },
    {
      key: "EMO16_0",
      text: "Hurt",
      value: "EMO16_0",
      description: "crushed, tormented, victimized, pained, tortured, rejected",
    },
    {
      key: "EMO17_0",
      text: "Sad",
      value: "EMO17_0",
      description: "tearful, sorrowful, anguished, desolate, unhappy, lonely",
    },
    {
      key: "EMO18_",
      text: "Judmental",
      value: "EMO18_0",
      description: "stony, serious, blunt, hard, brutal, disgusted",
    },
  ];

  const hourRanges = [
    {
      key: "earlymorning",
      text: "Early Morning",
      value: "earlymorning",
      description: "From 06:00 AM to 09:00 AM",
    },
    {
      key: "latemorning",
      text: "Late Morning",
      value: "latemorning",
      description: "From 9:00 AM to 12:00 AM (Noon)",
    },
    {
      key: "earlyafternoon",
      text: "Early Atternoon",
      value: "earlyafternoon",
      description: "From 12:00 (Noon) AM to 15:00 PM",
    },
    {
      key: "lateafternoon",
      text: "Late Atternoon",
      value: "lateyafternoon",
      description: "From 15:00 PM to 18:00 PM",
    },
    {
      key: "earlyevening",
      text: "Early Evening",
      value: "earlyevening",
      description: "From 18:00 PM to 21:00 PM",
    },
    {
      key: "lateevening",
      text: "Late Evening",
      value: "lateevening",
      description: "From 21:00 PM to 24:00 PM (MidNight, 0:00)",
    },
    {
      key: "earlynight",
      text: "Early Night",
      value: "earlynight",
      description: "From 24:00 PM (MidNight, 0:00) to 03:00 AM",
    },
    {
      key: "latenight",
      text: "Late Night",
      value: "latenight",
      description: "From 03:00 AM to 06:00 AM",
    },
  ];

  const drivers = [
    {
      key: "DRI6",
      text: "Scarcity",
      value: "DRI6",
      description:
        "There is not enough of it for the people who need it or want it.",
    },
    {
      key: "DRI7",
      text: "Ownership",
      value: "DRI7",
      description: "The state or fact of being an owner.",
    },
    {
      key: "DRI5",
      text: "Avoidance",
      value: "DRI5",
      description: " The act of keeping away from or preventing from happening",
    },
    {
      key: "DRI4",
      text: "Unpredictability",
      value: "DRI4",
      description: "Something not predictable; not to be foreseen or foretold",
    },
    {
      key: "DRI3",
      text: "Social Influence",
      value: "DRI3",
      description:
        "A person's attitudes or behaviour are altered by some form of social communication.",
    },
    {
      key: "DRI2",
      text: "Empowerment",
      value: "DRI2",
      description:
        "The giving or delegation of power or authority; authorization",
    },
    {
      key: "DRI1",
      text: "Meaning",
      value: "DRI1",
      description:
        "The inner, symbolic, or true interpretation, value. Intending; having purpose",
    },
    {
      key: "DRI8",
      text: "Acomplishment",
      value: "DRI8",
      description: "The successful achievement of a task.",
    },
  ];

  // HOOKS DATA

  //   const [dataHashtags, setDataHashtags] = useState(
  //     dataNumbers.map((value) => ({
  //       value: "hashtag-" + value,
  //       key: "hashtag-" + value,
  //       text: "hashtag-" + value,
  //       description: "hashtag-" + value,
  //     }))
  //   );
  const [dataHashtags, setDataHashtags] = useState(hashtagList);
  const [dataHourRange, setDataHourRange] = useState(hourRanges);
  const [dataLocations, setDataLocations] = useState(
    dataNumbers.map((value) => ({
      value: "location-" + value,
      key: "location-" + value,
      text: "location-" + value,
      description: "location-" + value,
    }))
  );
  const [dataEmos, setDataEmos] = useState(
    dataNumbers.map((value) => ({
      value: "emos-" + value,
      key: "emos-" + value,
      text: "emos-" + value,
      description: "emos-" + value,
    }))
  );
  const [dataDateRange, setDataDateRange] = useState([
    "placeHolderDate1",
    "placeHolderDate2",
  ]);
  const [dataSuperEmos, setDataSuperEmos] = useState(superEmos);
  const [dataDrivers, setDataDrivers] = useState(drivers);
  const [dataTags, setDataTags] = useState(
    dataNumbers.map((value) => ({
      value: "tags-" + value,
      key: "tags-" + value,
      text: "tags-" + value,
      description: "tags-" + value,
    }))
  );

  // HOOKS SELECTION

  const [selectedDataHashtags, setSelectedDataHashtags] = useState([]);
  const [selectedDataHourRange, setSelectedDataHourRange] = useState([]);
  const [selectedDataLocations, setSelectedDataLocations] = useState([]);
  const [selectedDataEmos, setSelectedDataEmos] = useState([]);
  const [selectedDataDateRange, setSelectedDataDateRange] = useState([]);
  const [selectedDataSuperEmos, setSelectedDataSuperEmos] = useState([]);
  const [selectedDataDrivers, setSelectedDataDrivers] = useState([]);
  const [selectedDataTags, setSelectedDataTags] = useState([]);
  const [clearAll, setClearAll] = useState(false);

  const handleClearAll = (event) => {
    console.log("CLEAR ALL", event);
    setClearAll(true);
    setTimeout(() => {
      console.log("200 MILISECONDS");
      setClearAll(false);
    }, 200);
  };

  const handleSubmit = (event) => {
    console.log("SUBMIT", event);
    setSubmitFilter({
      selectedDataHashtags: selectedDataHashtags,
      selectedDataHourRange: selectedDataHourRange,
      selectedDataLocations: selectedDataLocations,
      selectedDataEmos: selectedDataEmos,
      selectedDataDateRange: selectedDataDateRange,
      selectedDataSuperEmos: selectedDataSuperEmos,
      selectedDataDrivers: selectedDataDrivers,
      selectedDataTags: selectedDataTags,
    });
  };
  // SET THE DATA FROM PROPS.FILTERDATA:

  useEffect(() => {
    if (filterData) {
      //setDataDateRange(filterData.dataDateRange);
      //setDataDrivers(filterData.dataDrivers);
      if (filterData.dataEmos) setDataEmos(filterData.dataEmos);
      if (filterData.dataHashtags) setDataHashtags(filterData.dataHashtags);
      //setDataHourRange(filterData.dataHourRange);
      if (filterData.dataLocations) setDataLocations(filterData.dataLocations);
      //setDataSuperEmos(filterData.dataSuperEmos);
      if (filterData.datatags) setDataTags(filterData.dataTags);
    }
  }, [filterData]);

  return (
    <>
      <div>
        <Header as="h2">{titleHeader}</Header>
      </div>
      <div>
       
          <FilterComponentBase
            setter={setSelectedDataHashtags}
            lista={dataHashtags}
            title={"HashTags:"}
            clearAll={clearAll}
          />
          <FilterComponentBase
            setter={setSelectedDataHourRange}
            lista={dataHourRange}
            title={"Hour Range:"}
            clearAll={clearAll}
          />

          <FilterComponentBase
            setter={setSelectedDataDateRange}
            lista={dataDateRange}
            title={"Date Range:"}
            clearAll={clearAll}
          />

          <FilterComponentBase
            setter={setSelectedDataLocations}
            lista={dataLocations}
            title={"Location:"}
            clearAll={clearAll}
          />

          <FilterComponentBase
            setter={setSelectedDataEmos}
            lista={dataEmos}
            title={"Emos:"}
            clearAll={clearAll}
          />

          <FilterComponentBase
            setter={setSelectedDataSuperEmos}
            lista={dataSuperEmos}
            title={"Super Emos:"}
            clearAll={clearAll}
          />
          <FilterComponentBase
            setter={setSelectedDataDrivers}
            lista={dataDrivers}
            title={"Drivers:"}
            clearAll={clearAll}
          />
          <FilterComponentBase
            setter={setSelectedDataTags}
            lista={dataTags}
            title={"Tags:"}
            clearAll={clearAll}
          />
     
        <Button
          onClick={handleClearAll}
          disabled={
            selectedDataHashtags.length === 0 &&
            selectedDataHourRange.length === 0 &&
            selectedDataLocations.length === 0 &&
            selectedDataEmos.length === 0 &&
            selectedDataDateRange.length === 0 &&
            selectedDataSuperEmos.length === 0 &&
            selectedDataDrivers.length === 0 &&
            selectedDataTags.length === 0
          }
        >
          Clear All
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            selectedDataHashtags.length === 0 &&
            selectedDataHourRange.length === 0 &&
            selectedDataLocations.length === 0 &&
            selectedDataEmos.length === 0 &&
            selectedDataDateRange.length === 0 &&
            selectedDataSuperEmos.length === 0 &&
            selectedDataDrivers.length === 0 &&
            selectedDataTags.length === 0
          }
        >
          Submit
        </Button>
      </div>
    </>
  );
};
