// PAYPERDATA METRICS REDUCER PARTS:

// metricsAction.type
// metricsAction.payload
// mertricsAction.error
// metricsAction.meta
// metricsAction.OTHER <= ( all other things! )

console.log("PAYPERDATAREDUCER-MODULE-INIT");

export const payperDataReducer= (metricsState,metricsAction) =>{
    var ret=null;
    
    switch (metricsAction.type) {
        case "SET_METRIC_FILTERS":
            ret= {
                ...metricsState,
                metricFilters: {
                    hashtags: metricsAction.payload.hashtags,
                    drivers:[],
                    dateRange:{init:null,end:null},
                    hoursRange:[],
                    locations:metricsAction.payload.locations,
                    planetids:metricsAction.payload.planetids                            
                }
            };
            console.log("PAYPERDATAREDUCER:SET_METRIC_FILTERS",ret.metricFilters);
            break;
        case "SET_METRIC_RESULTS":
            ret= {
                ...metricsState,
                metricResults: metricsAction.payload
            };
            console.log("PAYPERDATAREDUCER:SET_METRIC_RESULTS",metricsAction.payload);
            break;
        case "SET_PAYPERDATA_STATE":
            ret= {
               ...metricsState,
               payperDataState: metricsAction.payload
            };
            console.log("PAYPERDATAREDUCER:SET_PAYPERDATA_STATE",metricsAction.payload); 
            break;
        case "SET_HASHTAG_FILTERS":
            ret=metricsState;
            ret.effectiveFilters.hashtags=metricsAction.payload;  
            console.log("PAYPERDATAREDUCER:SET_HASHTAG_FILTERS",metricsAction.payload);           
            break;
        case "SET_SUPEREMOS_FILTERS":
            ret=metricsState;
            ret.effectiveFilters.superEmos=metricsAction.payload;  
            console.log("PAYPERDATAREDUCER:SET_SUPEREMOS_FILTERS",metricsAction.payload);           
            break;
        case "SET_SUPEREMOS_DRIVERS":
                ret=metricsState;
                ret.effectiveFilters.drivers=metricsAction.payload;  
                console.log("PAYPERDATAREDUCER:SET_SUPEREMOS_DRIVERS",metricsAction.payload);           
            break;
        case "SET_DATE_RANGE":
                ret=metricsState;
                ret.effectiveFilters.dateRange=metricsAction.payload;  
                console.log("PAYPERDATAREDUCER:SET_DATE_RANGE",metricsAction.payload);           
            break;
        case "SET_HOURS_RANGE":
                ret=metricsState;
                ret.effectiveFilters.hoursRange=metricsAction.payload;  
                console.log("PAYPERDATAREDUCER:SET_HOURS_RANGE",metricsAction.payload);           
            break;
        case "SET_LOCATION_FILTERS":
                ret=metricsState;
                ret.effectiveFilters.locations=metricsAction.payload;  
                console.log("PAYPERDATAREDUCER:SET_LOCATION_FILTERS",metricsAction.payload);           
            break;
        case "SET_METRIC_DATAHASH":
            ret=metricsState;
            let comprobation=0;
            let hash=metricsAction.payload.hash;
            if(ret.metricDataHash)
            {
                    for(let i=0;i<ret.metricDataHash.length;i++)
                    {
                        console.log("PAYPERDATAREDUCER: SET_METRIC_DATAHASH: ??? HASH:"+hash+" === METRICSTATE["+i+"]:"+metricsState.metricDataHash[i].hash);
                        if(metricsState.metricDataHash[i].hash===hash)
                        {
                            comprobation=1;
                        }
                    }
            }
            
            if(comprobation===0) {
                ret.metricDataHash.push(metricsAction.payload);
                console.log("PAYPERDATAREDUCER:SET_METRIC_DATAHASH",metricsAction.payload);
                console.log("PAYPERDATAREDUCER:Setting in metricDataHashArray:",ret.metricDataHash);  
            }else{
                console.log("PAYPERDATAREDUCER:EXISTS HASH:",hash," !!!! (NOT SETTED):",ret.metricDataHash);   
            }
                     
            break;
        default:
            ret = metricsState;

    }
    sessionStorage.setItem("payperDataState",JSON.stringify(ret));
    return ret;

}