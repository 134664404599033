import React from "react";
import { Card, Label } from "semantic-ui-react";

const TotalsTopBox = (props) => {
  //console.log(props.value);
  const list = props.value.map((item) => <li> {item}</li>);

  return (
    <>
      <Card
        fluid
        style={{
          backgroundColor: "#000000",
          borderColor: "#ffffff",
          borderWidth: "20px",
          borderRadius: 15,
          fontFamily: "Tahoma"
        }}
      >
        <Card.Content>
          <Card.Description
            textAlign="center"
            style={{
              color: "#ABB2B9",
              fontSize: "15px"
            }}
          >
            {props.content}
          </Card.Description>
          <Card.Header
            textAlign="left"
            style={{
              color: "#EBF1F4",
              fontSize: "17px"
            }}
          >
            <ul style={{ listStyleType: "none" }}> {list}</ul>
          </Card.Header>
          <Label ribbon color={props.colorRibbon}>
            {props.dataRibbon}
          </Label>
        </Card.Content>
      </Card>
    </>
  );
};

export default TotalsTopBox;
