import React from "react";
import { useState, useContext, useEffect } from "react";
import { PayPerDataContext } from "../../services/payperDataContext";
import { Grid, Divider, Header, Icon, Dropdown, Label, Form, Card, Button, Input, Segment, Message } from "semantic-ui-react";
import { userService } from '../../services/userParams';
import PayPerDataFilters from './PayPerDataFilters.jsx'
import { wsSubscriptionQuery } from "../../services/WebSockets/wsSubscriptionQuery";
import { createHashOperation } from "../../core/blockchain/blockchainSigner";
import SemanticDatepicker from "react-semantic-ui-datepickers";

console.log("SUBSCRIPTION-COMPONENT-MODULE-INIT");

function SubscriptionComponent(user) {
    
    console.log("---SUBSCRIPTION-COMPONENT-INIT---");
    // USER is at user.user
    const subscriptionTypeOptions = [
        { key: 'standard', text: 'standard', value: 'standard' },
        { key: 'queryCounterDown', text: 'queryCounterDown', value: 'queryCounterDown' }
    ]

    var [payImport, setPayImport] = useState(0);
    var [subscriptionHidden, setSubscriptionHidden] = useState(true);
    var [subscriptionType, setSubscriptionType] = useState('standard');
    var [subscriptionDuration, setSubscriptionDuration] = useState(0);
    var [subscriptionCreationDate, setSubscriptionCreationDate] = useState(Date.now());
    var [queryResponse, setQueryResponse] = useState(null);
    var [subscriptionActive, setSubscriptionActive] = useState(null);

    var [subscriptionResultSuccesfully, setSubscriptionResultSuccesfully] = useState(null);


    const onChangeDatePicker = (event, data) => {
        console.log("CHANGE DATE PICKER=", data.value);
        setSubscriptionCreationDate(data.value);
    }
    const onchangeSubscriptionType = (event, data) => {
        console.log("CHANGE SUBSCRIPTION TYPE=", data.value);
        setSubscriptionType(data.value);

    }
    const onchangeSubscriptionDuration = (event, data) => {
        console.log("CHANGE SUBSCRIPTION DURATION=", data.value);
        setSubscriptionDuration(data.value);
    }

    const onchangeSubscriptionPayImport = (event, data) => {
        console.log("CHANGE SUBSCRIPTION PAY IMPORT=", data.value);
        setPayImport(data.value);
    }


    var subscriptionOperation = (
        wsQuery,
        functionLambda,
        subscriptionData
    ) => {
        // SUBRCRIPTION STATUS IS CALCULATED BY LAMBDA
        subscriptionData.operation = "subscription";
        subscriptionData.functionLambda = functionLambda;
        subscriptionData.userAddressId = user.user.userAddressId;
        subscriptionData.hashOperation = (createHashOperation(user.user.userAddressId));

        if (functionLambda === "create_subscription") {
            subscriptionData.subscriptionCreation = ((new Date(subscriptionCreationDate)).getTime());
        }
        console.log("subscriptionOperation SEND:", subscriptionData)
        wsQuery.send(JSON.stringify(subscriptionData));

    }

    var subscriptionAction = () => {
        console.log("SUBSCRIPTION ACTION");
        if (subscriptionCreationDate && Date.now() >= subscriptionCreationDate) {
            console.log("Date pass=", subscriptionCreationDate);
            if (subscriptionDuration > 0) {
                console.log("Duration pass>0=", subscriptionDuration);
                if (payImport >= 200) {
                    console.log("pay Import >=200 pass=", payImport);

                    var wsQuery = new wsSubscriptionQuery(setQueryResponse);


                    /*
                      codeFromBlockChain=(
                                        contract operation(
                                            userAddressId,payImport,subscriptionType,
                                            subscriptionCreationDate,subscriptionDuration
                                            ));
                    */
                    subscriptionOperation(
                        wsQuery,
                        "create_subscription",
                        // SUBSCRIPTIONDATA:
                        {
                            payCodeConfirmation: "codeFromBlockChain",
                            payImport: payImport,
                            subscriptionType: subscriptionType,
                            subscriptionDuration: subscriptionDuration
                        }
                    );




                } else {
                    console.log("FAIL: pay Import >=200 NO pass=", payImport);
                }
            } else {
                console.log("FAIL: Duration NO pass>0=", subscriptionDuration);
            }
        } else {
            console.log("FAIL: Date NO pass=", subscriptionCreationDate);
        }

    }

    var getActiveSubscription = () => {
        var wsQueryGA = new wsSubscriptionQuery(setQueryResponse);
        subscriptionOperation(
            wsQueryGA,
            "get_active_subscription",
            // SUBSCRIPTIONDATA:
            {}
        );

    }



    useEffect(() => {
        console.log("SUBSCRIPTIONCOMPONENT QUERY RESPONSE=", queryResponse);

        if (queryResponse &&
            queryResponse.subscriptionResult) {
            if (!queryResponse.subscriptionResult.isActive) {
                setSubscriptionHidden(false);

            } else {

                //var [subscriptionActive,setSubscriptionActive]=useState(null);
                setSubscriptionActive(queryResponse.subscriptionResult);
                console.log("SUBSCRIPTION RESULT", queryResponse.subscriptionResult);

            }
            if (queryResponse.subscriptionResult.subscriptionStatus === "active") {
                console.log("SUBSCRIPTION SUCCESSFULLY: ", queryResponse.subscriptionResult.subscriptionStatus);
                console.log("setSubscriptionResultSuccesfully(queryResponse.subscriptionResult)=", queryResponse.subscriptionResult);
                setSubscriptionResultSuccesfully(queryResponse.subscriptionResult);
                /*
                subscriptionResult: 
                                    hashOperation: "-207800590116017597941-15232803001-48374777"
                                    payCodeConfirmation: "codeFromBlockChain"
                                    payImport: "200"
                                    subscriptionDuration: "10"
                                    subscriptionExpiration: 1669038447905
                                    subscriptionId: "SID-207800590%06B6D440681596314898-1900747682-1731104104"
                                    subscriptionKey: "userSubscription#-207800590116017597941-15232803001-48374777"
                                    subscriptionStatus: "active"
                                    subscriptionType: "standard"
                                    [[Prototype]]: Object
                                    userAddressId: "0x7B896e74E2282516346e8893DE734949C7453D55"
                */
            }


        }


    }, [queryResponse]);

    useEffect(() => {
        if (user && user.user && user.user.userAddressId) {
            console.log("USERADDRESSID=", user.user.userAddressId);
            getActiveSubscription();
        }
    }, [user]);

    return (
        <>
            <Message hidden={subscriptionHidden} floating color="black">
                <Segment inverted>
                    <Form inverted id="subscriptionForm" className="ui container black bordered"
                        style={{
                            borderStyle: "solid",
                            borderColor: "purple",
                            paddingTop: "50px",
                            paddingRight: "30px",
                            paddingLeft: "30px",
                            paddingBottom: "50px"

                        }}
                    >
                        <h1> Subscription Form </h1>
                        <Form.Field>
                            <Label className="ui container black bordered"> Pay Import: </Label>
                            <Input onChange={onchangeSubscriptionPayImport} placeholder='Matic Payment minimal=200' />
                        </Form.Field>
                        <Form.Field>
                            <Label className="ui container black bordered"> Duration </Label>
                            <Input onChange={onchangeSubscriptionDuration} placeholder='Duration in days or in QueriesNum' />
                        </Form.Field>
                        <Form.Field>
                            <Label className="ui container black bordered"> Type </Label>
                            <Dropdown onChange={onchangeSubscriptionType} className="ui gray" options={subscriptionTypeOptions} defaultValue='standard' />
                        </Form.Field>
                        <Form.Field>
                            <Label className="ui container black bordered"> Subscription Creation </Label>
                            <SemanticDatepicker size="small"
                                format="MM-DD-YYYY"
                                icon="calendar outline"
                                backgroundColor="#34495E"
                                showToday
                                inverted
                                minDate={new Date(Date.now() - 86400000)}
                                value={subscriptionCreationDate}
                                onChange={onChangeDatePicker}>

                            </SemanticDatepicker>
                            {
                                (subscriptionResultSuccesfully) ?
                                    <div className="ui container black" style={{

                                        textAlign: "center",
                                        paddingTop: "5px",
                                        paddingRight: "0px",
                                        paddingLeft: "20px",
                                        paddingBottom: "5px"

                                    }}>
                                        <h2> SUCCESFULLY CREATING SUBSCRIPTION!!!!! </h2>
                                        <Message
                                            floating
                                            color="black"
                                            icon="bell outline"
                                            success
                                            header=
                                            {
                                                "Active Subscription : " +
                                                subscriptionResultSuccesfully.subscriptionId
                                            }
                                            content=
                                            {
                                                "Date of creation: " +
                                                (new Date(subscriptionResultSuccesfully.subscriptionCreation)).toDateString() +
                                                ", Duration : " + subscriptionResultSuccesfully.subscriptionDuration +
                                                ((subscriptionResultSuccesfully.subscriptionType === "standard") ?
                                                    ((subscriptionResultSuccesfully.subscriptionDuration === "1") ? " day" : " days") :
                                                    ((subscriptionResultSuccesfully.subscriptionDuration === "1") ? " querie" : " queries")) +
                                                ", Type: " + subscriptionResultSuccesfully.subscriptionType
                                            }
                                        />

                                    </div>


                                    : <><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></>
                            }


                        </Form.Field>

                        <Button onClick={subscriptionAction}>
                            Subscribe
                            <Icon name="arrow right"></Icon>

                        </Button>
                    </Form>
                </Segment>
            </Message>
            <div className="ui container black" style={{

                textAlign: "center",
                paddingTop: "5px",
                paddingRight: "0px",
                paddingLeft: "20px",
                paddingBottom: "5px"

            }}>
                {
                    (subscriptionActive && subscriptionActive.isActive) ?
                        <>
                            <Message


                                floating
                                color="black"
                                success
                                icon="id card outline"
                                header=
                                {"Active Subscription : " +
                                    subscriptionActive.subscriptionActive.subscriptionId
                                }
                                content=
                                {
                                    "Date of creation: " +
                                    (new Date(subscriptionActive.subscriptionActive.subscriptionCreation)).toDateString() +
                                    ", Duration : " + subscriptionActive.subscriptionActive.subscriptionDuration +
                                    ((subscriptionActive.subscriptionActive.subscriptionType === "standard") ?
                                        ((subscriptionActive.subscriptionActive.subscriptionDuration === "1") ? " day" : " days") :
                                        ((subscriptionActive.subscriptionActive.subscriptionDuration === "1") ? " querie" : " queries")) +
                                    ", Type: " + subscriptionActive.subscriptionActive.subscriptionType
                                }

                            /></> : <br />
                }
            </div>
        </>
    );
}

export default SubscriptionComponent