import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'semantic-ui-css/semantic.min.css'
import "./theme/scss/index.scss";
import { initApp } from './core/_index.js';

// get type of device in app params
console.log("\n");
console.log("------------------");
console.log("\n\n");
console.log("INDEX-MODULE-INIT");
console.log("\n\n");
console.log("------------------");
console.log("\n");


initApp();

ReactDOM.render(
  <App />,document.getElementById('root')
);

