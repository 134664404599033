import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../../core/a-cf';

// Considering you have an existing aws-exports.js configuration file 
Amplify.configure(awsconfig);

console.log("QUERYUSER-MODULE-INIT");

const itemsProfile = `
   userAddressId
   twitterAccount
   publicProfile
   userDescription
   user
   joined
   webAddress
   email
   avatarLink
   discordAccount
   instagramAccount
   redditAccount
   myGalaxyName
   `
  const itemsSummary=`
     currentHashtags
     currentSessionIds
     currentTags
     currentFavoritePlanetIds
     currentFavoritePlanetIdsCount
     subscriptionIds
     subscriptionKeys
     queryHashes
     currentPublicPlanetIds
     currentPublicPlanetsCount
     currentPlanetIds
     currentPlanetIdsCount
     currentHashtagsCount
     currentLocations 
  `


const getUserQuerySummary = (userId,sign)=>{
  let sessions = '';
  if (sign) {
    sessions = `sessions: {contains: "${sign}"},`;
  }
  return `
    query 
      getUserQuery {
        getMasterUser(userAddressId: "${userId}", userOperation: "userSummary")
         {
            ${itemsSummary}
         }
      }
        `
} 

const getUserQueryProfile = (userId,sign) => {
  let sessions = '';
  if (sign) {
    sessions = `sessions: {contains: "${sign}"},`;
  } 
  return `
    query 
      getUserQuery {
        getMasterUser(userAddressId: "${userId}", userOperation: "userProfile")
        {
            ${itemsProfile}
        }
      }
        `
}

export const getUserAWS = async (userId, sign = null) => {

  const userSummary = await API.graphql(
    graphqlOperation(
      getUserQuerySummary(userId, sign)
  ));

  const userProfile = await API.graphql(
    graphqlOperation(
      getUserQueryProfile(userId, sign)
  ));

  var currentFav=null;
  console.log("userSummmary",userSummary);
  console.log("userProfile",userProfile);
  if(userSummary.data.getMasterUser.currentFavoritePlanetIds && 
    userSummary.data.getMasterUser.currentFavoritePlanetIds.length>0){
      currentFav=userSummary.data.getMasterUser.currentFavoritePlanetIds.split(",");
  }else{
    currentFav=[];
  }
  var game={
    "data":{
      "listMasterUsers":{
        "items": [
          {
            "userAddressId" : userProfile.data.getMasterUser.userAddressId,
            "avatarLink" : userProfile.data.getMasterUser.avatarLink,
            "discordAccount": userProfile.data.getMasterUser.discordAccount,
            "email" : userProfile.data.getMasterUser.email,
            "hashtags" : ((userSummary.data.getMasterUser.currentHashtags)? userSummary.data.getMasterUser.currentHashtags.split(","): []),
            "instagramAccount" : userProfile.data.getMasterUser.instagramAccount,
            "joined" : userProfile.data.getMasterUser.joined,
            "myGalaxyName" : userProfile.data.getMasterUser.myGalaxyName,
            "publicProfile" : userProfile.data.getMasterUser.publicProfile,
            "realName" : userProfile.data.getMasterUser.user,
            "redditAccount" : userProfile.data.getMasterUser.redditAccount,
            "sessions" : userSummary.data.getMasterUser.currentSessionIds,
            "tags" : ((userSummary.data.getMasterUser.currentTags)? (userSummary.data.getMasterUser.currentTags).toString().split(","): []),
            "twitterAccount" : userProfile.data.getMasterUser.twitterAccount,
            "user" : userSummary.data.getMasterUser.user,
            "userDescription" : userProfile.data.getMasterUser.userDescription,
            "webAddress" : userProfile.data.getMasterUser.webAddress,
            "currentFavoritePlanetIds" : currentFav,
            "currentFavoritePlanetIdsCount" : userSummary.data.getMasterUser.currentFavoritePlanetIdsCount,
            "subscriptionIds" : userSummary.data.getMasterUser.subscriptionIds,
            "subscriptionKeys" : userSummary.data.getMasterUser.subscriptionKeys,
            "queryHashes" : userSummary.data.getMasterUser.queryHashes,
            "currentPublicPlanetIds" : userSummary.data.getMasterUser.currentPublicPlanetIds,
            "currentPublicPlanetsCount" : userSummary.data.getMasterUser.currentPublicPlanetsCount,
            "currentPlanetIds" : (userSummary.data.getMasterUser.currentPlanetIds)?
              userSummary.data.getMasterUser.currentPlanetIds.substring(
                1,userSummary.data.getMasterUser.currentPlanetIds.length-1
                ).split(', '):[],
            "currentPlanetIdsCount" : userSummary.data.getMasterUser.currentPlanetIdsCount,
            "currentHashtagsCount" : userSummary.data.getMasterUser.currentHashtagsCount,
            "currentLocations" : userSummary.data.getMasterUser.currentLocations  
          }
        ]
      }
    }
  }

 //console.log("GAMEQUERYUSER",game);

  return game;
}