import React, { useEffect, useState } from "react";
//import LinkShare from "./UserPlanetDetail/LinkShare";
import UserData from "./UserPlanetDetail/UserData";


console.log("HEADERPLANETDETAIL-COMPONENT-MODULE-INIT");

const HeaderPlanetDetail = (props) => {

  console.log("---HEADERPLANETDETAIL-COMPONENT-INIT---");
  
  var [gameData, setGameData] = useState(null);

  useEffect(() => {
    setGameData(props.gameData);
  }, [props]);

  return (
    <>
      {/* <LinkShare /> */}
      <UserData gameData={gameData} />
    </>
  );
};

export default HeaderPlanetDetail;
