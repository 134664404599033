import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Menu } from 'semantic-ui-react';
import { userData } from '../../../services/userParams';
import UserMenu from '../UserMenu/UserMenu';
import './MenuHeader.scss';

var route1 = '/login';
var route2 = '/login';
var route3 = '/login';
var route4 = '/metrics';
var route5 = '/payperdata'

console.log("MENU-HEADER-COMPONENT-MODULE-INIT");

const MenuHeader = () => {

  console.log("---MENU-HEADER-COMPONENT-INIT---");
  
  var [activeItem, setActiveItem] = useState('play');
  var [userVisible, setUserVisible] = useState(false);

  const setRoutes = () => {
    route1 = '/profile';
    route2 = `/mygalaxy/${userData.user}`;
    route4 = '/metrics';
    route5 = '/payperdata';
  }

  const removeRoutes = () => {
    route1 = '/login';
    route3 = route2 = route1;
    route4 = '/metrics';
    route5 = '/payperdata';
  }
    
  const handleItemClick = (e, { name }) => {
    // console.log(name);
    if (name === 'user') {
      setUserVisible(!userVisible);
    }
    setActiveItem(name);
  }

  // if (userData.user) {
  setRoutes();
  // } else {
  //   removeRoutes();
  // }

    return (
      <>
        <Menu fluid vertical>
          {/* <Menu.Item>
            <Input icon='search' placeholder='Search...' />
          </Menu.Item> */}
          
          <Menu.Item
            as={Link}
            name='play'
            active={activeItem === 'play'}
            onClick={handleItemClick}
            to='/'
          />
          
          <Menu.Item
            as={Link}
            name='marketplace'
            active={activeItem === 'marketplace'}
            onClick={handleItemClick}
            to='/marketplace'
          />
            

          <Menu.Item
            as={Link}
            name='about'
            active={activeItem === 'about'}
            onClick={handleItemClick}
            to='/about'
          />
            
          <Menu.Item
            name='user'
            active={activeItem === 'user'}
            onClick={handleItemClick}
          />

          <UserMenu userVisible={userVisible} route1={route1} route2={route2} route3={route3} route4={route4} route5={route5}/>
        </Menu>
      </>
    )
}
export default MenuHeader;