import React from "react";
import PayPerDataHashtags from "./Filters/PayPerDataFilterHashTags";
import PayPerDataSuperEmos from "./Filters/PayPerDataFilterSuperEmos";
import PayPerDataDrivers from "./Filters/PayPerDataFilterDrivers";
import PayPerDataLocationsGames from "./Filters/PayPerDataFilterLocationsGames";
import PayPerDataDatesGames from "./Filters/PayPerDataFilterDatesGames";
import PayPerDataHoursGames from "./Filters/PayPerDataFilterHoursGames";
import { Divider, Button, Grid, Container } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {PayPerDataContext} from "../../services/payperDataContext.js";

import { userService } from '../../services/userParams';

console.log("PAYPERDATAFILTERS-COMPONENT-MODULE-INIT");

function PayPerDataFilters() {

  console.log("---PAYPERDATAFILTERS-COMPONENT-INIT----");
  
  var [user, setUser] = useState(null);
  var userDataSubs = undefined;
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);

  const assignUser = async () => {
    userDataSubs = userService.getUserData().subscribe(u => {
        if (u && u.userAddressId) {
            setUser(u);
            
        } else {
            // res de res
            setUser(null);
        }
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    assignUser();
    
    return (() => {
        userDataSubs.unsubscribe();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  return (
    <>
      <Container
        style={{
          paddingLeft: "20px"
        }}
      >
        <p style={{ color: "grey" }}>
          Filter your planets and games by the next selections:
        </p>
        <br />
        <Grid columns={16}>
          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataHashtags />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataSuperEmos />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataDrivers />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataDatesGames />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataHoursGames />
          </Grid.Row>

          <Grid.Row mobile={16} tablet={16} computer={16}>
            <PayPerDataLocationsGames />
          </Grid.Row>
        </Grid>

        <br />
        <br />
        <Button inverted color="purple" size="big" fluid >
          Submit
        </Button>
        <br />
        <Divider />
      </Container>
    </>
  );
}

export default PayPerDataFilters;
