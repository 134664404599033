import { mode } from '../../../mode.js';
import { Label,Icon,Button } from "semantic-ui-react";
import { useEffect, useState } from 'react';
import { getDataGame } from './dataGame.js'
import { fontSize } from '@mui/system';
import { button } from 'leva';

console.log("BUTTONEMOTIONS-COMPONENT-MODULE-INIT");

export function ButtonEmotions(

  
  {
  setImgPlanet, 
  emotionList, 
  listValues, 
  setlistValues, 
  typeCloud, 
  setTypeCloud, 
  setFinishGame,
  inputValue
  
  }
){
  console.log("--BUTTONEMOTIONS-COMPONENT-INIT--");

  console.log("BUTTON EMOTION TYPE:",typeCloud);
  
  if(typeCloud==="emotions"){
    console.log("input Value", inputValue)
  }

  var emotions=[];
  for(let i=0;i<emotionList.length;i++){
    emotions.push({emo:emotionList[i],numClicks:0})
  }
  console.log("EMOTIONS",emotions);

  var columns=emotions.map((emotion,index)=>{
    return (
      <ButtEmo 
      key={index}
      emotion={emotion}
      setImgPlanet={setImgPlanet} 
      emotionList={emotionList} 
      listValues={listValues} 
      setlistValues={setlistValues} 
      typeCloud={typeCloud} 
      setTypeCloud={setTypeCloud} 
      setFinishGame={setTypeCloud}
      inputValue={inputValue}
      />
    )  
  });

  var column1=columns.filter((_,index,col)=>index<col.length/2);
  var column2=columns.filter((_,index,col)=>index>=col.length/2);

  console.log("COLUMNS",columns);
  console.log("COLUMN1",column1);
  console.log("COLUMN2",column2);

  return (
    <> 
      {columns}
    </>
  )
} 

function ButtEmo ({
  emotion,
  setImgPlanet, 
  emotionList, 
  listValues, 
  setlistValues, 
  typeCloud, 
  setTypeCloud, 
  setFinishGame,
  inputValue
}) {
  var index = -1;
  var ix = 0;
  emotionList.map((element) => {
    //console.log("INDEX:",ix,"ELEMENT[0]:",element[0],"ELEMENT[1]:",element[1]);
    if(element[0] === emotion.emo[0]) {
    //console.log("ix",ix);
    index = ix;
    }
    ix++;
  })

  const[emoCount,setEmoCount]=useState(emotionList[index][1]);

  var auxDragName=null;
  var auxDragbool=false;
   
    
  useEffect(()=>{
    console.log("EMOTION",emotion.emo[0]," IS CHANGED EMOCOUNT=",emoCount);
  },[emoCount]);

  useEffect(()=>{
    var indexEF = -1;
    var ixEF = 0;
    emotionList.map((element) => {
      //console.log("INDEX:",ix,"ELEMENT[0]:",element[0],"ELEMENT[1]:",element[1]);
      if(element[0] === emotion.emo[0]) {
      //console.log("ix",ix);
      indexEF = ixEF;
      }
      ixEF++;
    })
    setEmoCount(emotionList[indexEF][1]);
  },[emotionList]);

  const dragStartHandler=e=>{
    e.stopPropagation();
    console.log("DRAGSTARTHANDLER",e);
    console.log("E.TARGET",e.target);
    auxDragName=e.target.innerText;
    auxDragbool=true;
    console.log("AUXDRAGNAME=",auxDragName)
    e.target.innerText=auxDragName.toUpperCase();
  }

  const dragEndHandler=e=>{
    e.stopPropagation();

    console.log("DRAGS END HANDLER",e);
    console.log("E.TARGET",e.target)
    console.log("e.target.children[0].children[0].innerText=",e.target.innerText)
    console.log("TO AUXDRAGNAME",auxDragName);
    
    if(auxDragbool) {
      e.target.innerText=auxDragName;
      auxDragbool=false;
    }
  }
  
  const handleClick = e => {
    e.stopPropagation();
    console.log("CLICK ON EMOTION",emotion.emo[0])
    console.log("OBJECT",e.object)
    
    //var emoText = e.object._private_text.split(" ")[0];
    //e.object._private_text=emoText+" "+(emoCount+1);

    if(typeCloud === "hashtag") {
      var aux;
      if(listValues.length === 0){
        aux = emotion.emo[0];
      } else{
        aux = emotion.emo[0] + listValues[0] ;
      }

      var auxListValues=[];
      setlistValues([]);
      auxListValues.push(aux)
      setlistValues(auxListValues);
        
      console.log("LISTVALUES=",listValues);
    }

    setEmoCount(emoCount + 1);
    emotionList[index][1] = emoCount + 1;

    if (typeCloud === "emotions") {
      if(index !== -1 && index !== mode.NumEmotions){
        getDataGame().emotions[index][1] = ( emoCount + 1 ) * 20 ;
      } else{
        if(index !== mode.NumEmotions ) getDataGame().emotions[index][1] = index;
      }
    }
  }

  const handleClickAdd = e => {
    e.stopPropagation();
    console.log("CLICK ON EMOTION",emotion.emo[0])
    console.log("OBJECT",e.object)
    
    //var emoText = e.object._private_text.split(" ")[0];
    //e.object._private_text=emoText+" "+(emoCount+1);

    if(typeCloud === "hashtag") {
      var aux;
      if(listValues.length === 0){
        aux = emotion.emo[0];
      } else{
        aux = emotion.emo[0] + listValues[0] ;
      }

      var auxListValues=[];
      setlistValues([]);
      auxListValues.push(aux)
      setlistValues(auxListValues);
        
      console.log("LISTVALUES=",listValues);
    }

    if(emoCount < 6) {
      setEmoCount(emoCount + 1);
      emotionList[index][1] = emoCount + 1;

      if (typeCloud === "emotions") {
        if(index !== -1 && index !== mode.NumEmotions){
          getDataGame().emotions[index][1] = ( emoCount + 1 ) * 20 ;
        } else{
          if(index !== mode.NumEmotions ) getDataGame().emotions[index][1] = index;
        }
      }
    }
  }

  const handleClickSubstract = e => {
    e.stopPropagation();
    console.log("CLICK ON EMOTION",emotion.emo[0])
    console.log("OBJECT",e.object)
    
    //var emoText = e.object._private_text.split(" ")[0];
    //e.object._private_text=emoText+" "+(emoCount+1);

    if(typeCloud === "hashtag") {
      var aux;
      if(listValues.length === 0){
        aux = emotion.emo[0];
      } else{
        aux = emotion.emo[0] + listValues[0] ;
      }

      var auxListValues=[];
      setlistValues([]);
      auxListValues.push(aux)
      setlistValues(auxListValues);
        
      console.log("LISTVALUES=",listValues);
    }

    if(emoCount > 0) {
      setEmoCount(emoCount - 1);
      emotionList[index][1] = emoCount - 1;

      if (typeCloud === "emotions") {
        if(index !== -1 && index !== mode.NumEmotions){
          getDataGame().emotions[index][1] = ( emoCount - 1 ) * 20 ;
        } else{
          if(index !== mode.NumEmotions ) getDataGame().emotions[index][1] = index;
        }
      }
    }
  }

  return (
    <div className="emotionItem">
      <Label as={Button}
        // disabled={
        //   (typeCloud==="emotions" && emotionList[index][1]>5) ||
        //   (typeCloud==="hashtag" && emotionList[index][1]>0) ||
        //   (typeCloud==="emotions" && emotion.emo[0]==='[END GAME]')
        // }
      >
        {/* <Label.Detail className="emotionButtons">
          <Icon name="minus circle" onClick={handleClickSubstract} className="pointer" />
          <span className="emotionValue">{emotion.emo[0]}</span>
          <Icon name="plus circle" onClick={handleClickAdd} className="pointer" />
        </Label.Detail>
        <Label.Detail></Label.Detail> */}
        
        <Label.Detail>
          <span>{emotion.emo[0]}</span>
        </Label.Detail>

        <Label.Detail className="emotionButtons">
          <Icon name="minus circle" onClick={handleClickSubstract} className="pointer" />
          <span className="emotionValue">{(emotion.emo[0]!=='[END GAME]' && (typeCloud==="emotions")&& emoCount>0 )?" "+(emoCount):"0"}</span>
          <Icon name="plus circle" onClick={handleClickAdd} className="pointer" />
        </Label.Detail>

        {/* <Label color='red' floating>
          {(emotion.emo[0]!=='[END GAME]' && (typeCloud==="emotions")&& emoCount>0 )?" "+(emoCount):"0"}
        </Label> */}
      </Label>

      {/* <Button 
        size="small"
        disabled={
          (typeCloud==="emotions" && emotionList[index][1]>5) ||
          (typeCloud==="hashtag" && emotionList[index][1]>0) ||
          (typeCloud==="emotions" && emotion.emo[0]==='[END GAME]')
        }
        onClick={handleClick}
        draggable
        onDragStart={dragStartHandler}
        onDragEnd={dragEndHandler}
      >
        {emotion.emo[0]}
        {(emotion.emo[0]!=='[END GAME]' && (typeCloud==="emotions")&& emoCount>0 )?" "+(emoCount):""}
      </Button> */}
    </div>
  )
}