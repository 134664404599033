import { textAlign } from "@mui/system";
import React from "react";
import {useContext,useReducer} from "react";
import PayperDataComponent from './../../components/PayperData/PayperDataComponent'
import {PayPerDataContext,PayperDataInitialState} from "../../services/payperDataContext";
import { payperDataReducer } from "../../services/payperDataReducer";
import { Container, Segment } from "semantic-ui-react";

console.log("PAYPERDATA-PAGE-MODULE-INIT");

function PayperData() {
  console.log("--PAYPERDATA-PAGE-.INIT--");
  const [metricsState,dispatchMetrics]= useReducer(payperDataReducer,PayperDataInitialState);
  sessionStorage.setItem("payperDataState",metricsState);

  return <Container style={{ color: "#F8F9F9", padding: "60px", backgroundColor: "#000000" }}>
          <Segment style={{ backgroundColor: "black" }}>
            <PayPerDataContext.Provider value={[metricsState,dispatchMetrics]}>
             
                <PayperDataComponent/>
           
            </PayPerDataContext.Provider>
          </Segment>
          </Container>
}

export default PayperData;