import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import {MetricsContext} from "../../../services/metricsContext.js";
import { useContext,useEffect,useState } from "react";
import {Super_Emos} from "../../../core/Utils.js";

HcMore(Highcharts);

function createOptions(data1,data2)
{
  return({
    series: [
      {
        name: "Positive",
        data: data1,
        _colorIndex: 9
      },
      {
        name: "Negative",
        data: data2,
        _colorIndex: 5
      }
    ],

    chart: {
      polar: false,
      type: "column",
      backgroundColor: "#34495E",
      borderColor: "#ffffff",
      borderWidth: 0.5,
      borderRadius: 25
    },

    title: {
      text: "Total Super-Emotions"
    },

    subtitle: {
      text: "Bars by super-emotions"
    },

    pane: {
      size: "85%"
    },

    legend: {
      align: "center",
      horizontalAlign: "down",

      layout: "horizontal"
    },

    xAxis: {
      tickmarkPlacement: "on",
      type: "category"
    },

    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        }
      },
      reversedStacks: true
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>'
    },

    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on"
      }
    },
    credits: {
      enabled: false
    }
  });
}
/*
var data1=[
  ["EMO1", 200],
  ["EMO2", 1000],
  ["EMO3", 180],
  ["EMO4", 700],
  ["EMO5", 140],
  ["EMO6", 10],
  ["EMO7", 80],
  ["EMO8", 1120],
  ["EMO9", 200]
];

var data2=[
  ["EMO10", 10],
  ["EMO11", 180],
  ["EMO12", 80],
  ["EMO13", 140],
  ["EMO14", 60],
  ["EMO15", 120],
  ["EMO16", 120],
  ["EMO17", 200],
  ["EMO18", 200]
];
*/

function SuperEmotionsBar() {

 
  
 
  let data1=[
    ["EMO1", 0],
    ["EMO2", 0],
    ["EMO3", 0],
    ["EMO4", 0],
    ["EMO5", 0],
    ["EMO6", 0],
    ["EMO7", 0],
    ["EMO8", 0],
    ["EMO9", 0]
  ];

  let data2=[
    ["EMO10", 0],
    ["EMO11", 0],
    ["EMO12", 0],
    ["EMO13", 0],
    ["EMO14", 0],
    ["EMO15", 0],
    ["EMO16", 0],
    ["EMO17", 0],
    ["EMO18", 0]
  ];
  
 

  const[options,setOptions]=useState(createOptions(data1,data2));
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);

  useEffect(()=>{
    if(metricsState.metricResults && metricsState.metricResults.TotalGamesSuperEmo)
    {
      let newdata1=[];
      let newdata2=[];
      for(let i=0;i<data1.length;i++){
        let d=data1[i][0];
        
        for(let j=0;j< metricsState.metricResults.TotalGamesSuperEmo.length;j++){
          let m= metricsState.metricResults.TotalGamesSuperEmo[j];
          let em= m.semo.split("_")[0];
          //console.log("COMPARE em==D",em,d);
          if(em === d) {

             newdata1.push([em, m.sum_points]);
          }
        }
       
      }

      for(let i=0;i<data2.length;i++){
        let d=data2[i][0];
        
        for(let j=0;j< metricsState.metricResults.TotalGamesSuperEmo.length;j++){
          let m= metricsState.metricResults.TotalGamesSuperEmo[j];
          let em= m.semo.split("_")[0];
          //console.log("COMPARE em==D",em,d);
          if(em === d) {

             newdata2.push([em, m.sum_points]);
          }
        }
       
      }
      console.log("NEWDATA1",newdata1);
      console.log("NEWDATA2",newdata2);
  
      setOptions(createOptions(newdata1,newdata2));
    }
    

  },[metricsState.metricResults]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default SuperEmotionsBar;
