import {API, graphqlOperation} from 'aws-amplify';
/*
query MyQuery {
    getGameMetrics(userAddressId: "") {
      metricsGame
      hashtags
      locations
      planetids
    }
  }
  */

  console.log("QUERYMETRICS-MODULE-INIT"); 

export const prepareMetricsQuery=(userAddressId,limit,filters)=>{
    if(limit &&  limit>0){
       
        return `
        query MyQuery {
            getGameMetrics(userAddressId: "${userAddressId}",limit: ${limit},filter: ${filters}) {
                metricsGame
            }
        }
        `
    }else{
    return `
        query MyQuery {
            getGameMetrics(userAddressId: "${userAddressId}") {
                hashtags
                locations
                planetids
            }
        }
        `
    }
}

export const getMetrics = async (_query) =>
{
    let result =  await API.graphql(
        graphqlOperation(
            _query
        ));
    return result;
}

export function prepareFilters(dateInit,dateEnd,driver,hashtags,locations,superEmo,hoursRange){
/*
query MyQuery {
 getGameMetrics(userAddressId: "", 
                filter: {
                        dateEnd: "", 
                        dateInit: "", 
                        driver: "", 
                        hashtags: "",
                        locations: "", 
                        superEmo: ""
                }, 
                limit: 10) {
                        metricsGame
                        hashtags
                        locations
                        planetids
                }
}
*/
    var filters=`{`;
    
    if(dateInit){
        filters+=`dateInit: "${dateInit}",`;
    }   
    
    if(dateEnd){
        filters+=`dateEnd: "${dateEnd}",`;
    }   
    
    if(driver && driver.length>0){
        let driverfilter="";
       
        for(var i=0;i<driver.length;i++){
            driverfilter+=driver[i]+",";
        }
        if(driverfilter.length>0){
            driverfilter=driverfilter.substring(0,driverfilter.length-1);
        }
        filters+=`driver: "${driverfilter}",`;
    }   
    
    if(hashtags && hashtags.length>0){
        let hashtagfilter="";
       
        for(var i=0;i<hashtags.length;i++){
            hashtagfilter+=hashtags[i]+",";
        }   
        if(hashtagfilter.length>0){
            hashtagfilter=hashtagfilter.substring(0,hashtagfilter.length-1);
        }
        filters+=`hashtags: "${hashtagfilter}",`;
    }   
    
    if(locations && locations.length>0){
        let locationfilter="";
   

        for(var i=0;i<locations.length;i++){
            locationfilter+=locations[i]+";";
        }
        if(locationfilter.length>0){
            locationfilter=locationfilter.substring(0,locationfilter.length-1);
        }
        filters+=`locations: "${locationfilter}",`; 

    }   
    
    if(superEmo && superEmo.length>0){
        let superemoFilter="";
        for(var i=0;i<superEmo.length;i++){
            superemoFilter+=superEmo[i].split("_")[0]+",";
        }   
        if(superemoFilter.length>0){
            superemoFilter=superemoFilter.substring(0,superemoFilter.length-1);
        }
        filters+=`superEmo: "${superemoFilter}",`;
    }  
    
    if(hoursRange && hoursRange.length>0){
        let hoursRangeFilter="";
        for(var i=0;i<hoursRange.length;i++){
            hoursRangeFilter+=hoursRange[i]+","
        }
        if(hoursRangeFilter.length>0){
            hoursRangeFilter=hoursRangeFilter.substring(0,hoursRangeFilter.length-1);
        }
        filters+=`hoursRange: "${hoursRangeFilter}",`;
    }
    
    if(filters.length>1){
        filters=filters.substring(0,filters.length-1);
    }   filters+=`}`;

    return filters;
}