import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Label, Menu } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {PayPerDataContext} from "../../../services/payperDataContext.js";

function PayPerDataDatesGames() {
  const [dates, setDates] = useState([]);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const handleDatesChange = (event, data) => {
    setDates(data.value);
    var init=0;
    var finish=0;
    if(data && data.value && data.value.length>1){
      var dinit=new Date(data.value[0]);
      var dfinish=new Date(data.value[1]);
      init=dinit.getTime();
      finish=dfinish.getTime(); 
      //console.log("FILTERSATAGAMES:",init,finish);
      dispatchMetrics({type:"SET_DATE_RANGE",payload:{init:init,end:finish}});
    }

  }

  const renderLabel = (label) => ({
    color: "purple",
    content: `${label.text}`,
    icon: "check"
  });
  return (
    <>
      <Menu inverted stackable>
        <Menu.Item style={{ fontSize: "18px", width: "190px" }}>
          Date Range
          <Label color="purple" size="large">
            0
          </Label>
        </Menu.Item>
        <Menu.Item
          style={{
            fontSize: "12px",
            padding: "0px",
            background: "#34495E"
          }}
        >
          <SemanticDatepicker
            size="big"
            format="MM-DD-YYYY"
            icon="calendar outline"
            inverted
            backgroundColor="#34495E"
            onChange={handleDatesChange}
            type="range"
            label={renderLabel}
            style={{ background: "black", color: "white" }}
          />
        </Menu.Item>
      </Menu>
    </>
  );
}

export default PayPerDataDatesGames;
