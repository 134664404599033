import { useEffect, useRef, useState, useContext } from "react";
import GridPlanet from "../Grid/GridPlanet";
import { EmoContext } from "../../services/globalContext";
import { getListItemAvatarUtilityClass } from "@mui/material";
import {
  getMarketPlacePlanetList,
  prepareMarketPlaceQuery,
} from "../../services/Graphql/Game/queryMarketPlace";

console.log("MARKETPLACE-COMPONENT-MODULE-INIT");

const MarketplaceComponent = (props) => {

  console.log("----MARKETPLACE-COMPONENT-INIT--");

  console.log("MARKETPLACECOMPONENT PROPS=", props);
  console.log(
    "MARKETPLACECOMPONENT,SESSION STORAGE OBJECT: GLOBALSTATE=",
    JSON.parse(sessionStorage.getItem("globalState"))
  );

  var [mainHeight, setMainHeight] = useState("550px");
  const [globalState, dispatchGlobal] = useContext(EmoContext);
  const [stateList, setStateList] = useState(null);
  const [settingStateList, setSettingStateList] = useState(false);

  var query = null;

  var page = globalState.marketPlaceGrapQlParams.page;
  var next = globalState.marketPlaceGrapQlParams.nextToken;
  var limit = globalState.marketPlaceGrapQlParams.limit;
  var status = globalState.marketPlaceGrapQlParams.status;

  const gridRef = useRef(null);

  const setPage = (page) => {
    if (page === 0) {
      query = prepareMarketPlaceQuery(null, limit, false, status);
    } else {
      query = prepareMarketPlaceQuery(next, limit, false, status);
    }
    getMarketPlacePlanetList(query, setStateList);
  };

  const setHeight = () => {
    const seg = document.getElementsByClassName("galaxy-segment");
    if (seg[0]) {
      seg[0].style.minHeight = mainHeight;
    }
  };

  const gridScroll = (ref) => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const element = ref.current;
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + top - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handlerPreviousClick = () => {
    document.getElementById("prv").blur();
    setPage(--page);
    gridScroll(gridRef);
  };

  const handlerNextClick = () => {
    document.getElementById("nxt").blur();
    setPage(++page);
    gridScroll(gridRef);
  };

  useEffect(() => {
    if (settingStateList && stateList) {
      console.log(
        "MARKETPLACE COMPONENT:CHANGE STATELIST, SETTING IN REDUCER",
        stateList
      );
      dispatchGlobal({
        type: "SET_MARKETPLACE_PLANET_RESULTS",
        payload: stateList,
      });
      setSettingStateList(true);
    }
  }, [stateList]);

  useEffect(() => {
    setHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainHeight]);

  useEffect(async () => {
    console.log("SETTING PAGE", page);
    setPage(page);
  }, []);

  return stateList === null ? (
    <>
      <div>Loading...</div>
    </>
  ) : (
    <>
      <GridPlanet list={stateList.data.listMasterGames.items} />      
    </>
  );
};

export default MarketplaceComponent;
