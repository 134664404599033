import { ConsoleLogger } from '@aws-amplify/core';
import { API, graphqlOperation } from 'aws-amplify';
import { nextTokenList } from '../../userParams';

console.log("QUERYLISTS-MODULE-INIT");

let list = null;
let nextCount = null;
let pageAWSbackup = 0;
let mapListImages = [];
const items = `
    idGame
    planetOperation
    userAddressId
    description
    emotions
    emotionsFilter
    favorites
    gameDesign
    generalData {
      date
      deviceId
      email
      emoViewConnectionID
      gameId
      geoloc {
        accuracy
        latitude
        longitude
      }
      pngId
      posnegPlanet
      username
    }
    hashtags
    likes
    quote
    requesttime
    sourceip
    status
    tags
    textGame
    timestampEpoch
    
  `


export const gamesList = async (params) => {
    console.log("GAMESLIST PARAMS:",params);
    let repeatAWSresponseCount = 0;

    let listBackup = null;
    if (params.page < 0) params.page = 0;

    if (params.page === pageAWSbackup && listBackup !== null) {
        list = listBackup;
        repeatAWSresponseCount++;
        list.data.listMasterGames.items[0].repeatAWSresponseCount = repeatAWSresponseCount;
    } else {
        const listParams = {
            filter: params.filter,
            limit: params.limit,
        }
        repeatAWSresponseCount = 0;
        let nextToken;

        if (params.page === 0) {
            list = await getList(list, listParams);
            console.log("BORRAR LIST",list)
            if (list.data.listMasterGames) {
                nextToken = list.data.listMasterGames.nextToken;
                listParams.token = nextToken;
                listParams.count = true;
            }

            const nextCountData = await getList(list, listParams);
            if (nextTokenList[params.page] === null ||
                nextTokenList[params.page] !== nextToken) {
                nextTokenList[params.page] = nextToken;
            }
            if (listParams.count) {
                nextTokenList[params.page + 1] = nextCountData.data.listMasterGames.nextToken;
                nextCount = nextCountData.data.listMasterGames.items ? nextCountData.data.listMasterGames.items.length : 0;
            }

        } else {
            if (params.page > pageAWSbackup) {
                if (params.page === pageAWSbackup + 1 && nextTokenList[pageAWSbackup] !== null && nextTokenList[pageAWSbackup] !== undefined) {
                    //console.log("EXISTE TOKEN ANTERIOR, SE UTILIZA");
                    let nextToken = nextTokenList[pageAWSbackup];
                    listParams.token = nextToken;

                    list = await getList(list, listParams);

                    nextToken = list.data.listMasterGames.nextToken;
                    nextTokenList.push(nextToken);
                } else if ((params.page === pageAWSbackup - 1 && nextTokenList[pageAWSbackup - 1] !== null) || nextTokenList[pageAWSbackup - 1] === undefined) {
                    //console.log("EXISTE TOKEN ANTERIOR, SE UTILIZA");
                    nextToken = nextTokenList[params.page];
                    listParams.token = nextToken;

                    list = await getList(list, listParams);
                    list = await getList(list, nextToken, params.filter, params.limit);

                    nextToken = list.data.listMasterGames.nextToken;
                    if (nextTokenList[pageAWSbackup] !== nextToken) {
                        nextTokenList.push(nextToken);
                    }
                }
            } else {
                listParams.token = nextTokenList[params.page - 1];
                list = await getList(list, listParams);
            }
        }
        if (list.data.listMasterGames) {
            createMapList(list.data.listMasterGames.items, mapListImages);
            listBackup = list;
            pageAWSbackup = params.page;
        }
    }
    if (list.data.listMasterGames) {
        return { list: list.data.listMasterGames.items, nextCount };
    } else {
        return null;
    }
}

const createMapList = (list, mapListImages) => {
    const pathToAwsLowResolutionPlanets = "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";

    mapListImages.length = 0;

    for (let listElement of list) {
        listElement.url = pathToAwsLowResolutionPlanets + listElement.idGame + ".png";
        mapListImages.push(listElement.url);
    }
}

const getList = async (list, params) => {
    let gameList = gameListFilteredBuilder(params.filter, params.token, params.limit, params.count);
    console.log("BORRAR GAMELIST",gameList);
    if (params.filter) {
        
        console.log("BORRAR PARAMS FILTER",params.filter);
        let listaux =  await API.graphql(
            graphqlOperation(
                gameList
            ));
         list={ "data": {
             "listMasterGames": listaux.data.listeMaster20GamesPartiQL
         }}
         console.log("QUERYLIST GET LIST RETURN LIST",list)
         
         return list;

    } else {
        return null;
    }
}

const gameListFilteredBuilder = (filter, token, limit, count) => {
    let t = '';
    if (token) {
        t = `, nextToken: "${token}"`;
    }
    let l = '';
    if (limit) {
        l = `, limit: ${limit}`;
    }
    let user = null;
    let tag = null;
    let hashtag = null;
    let emotion = null;
    let favorites = null;
    let time = null;
    let status = null;

    if (filter) {
        user = filter.user;
        tag = filter.tag;
        hashtag = filter.hashtag;
        emotion = filter.emotion;
        favorites = filter.favorites;
        time = filter.time;
        status = filter.status;
    }
    let userFilter = '';
    let userFilter2 = '';
    if (user) {
        if (user !== '') {
            userFilter = `{userAddressId: {contains:"${user}"}}`
            //userFilter2 = `( userAddressId: "${user}" )`
            userFilter2 = ` userAddressId: "${user}", `
        }
    }

    let tagsFilter = '';
    if (tag && tag.length > 0) {
        if (user) {
            tagsFilter = tagsFilter + ',';
        }
        let tags = '';
        tag.forEach((t, i) => {
            tags = tags + `{tags: {contains: "${t}"}}` + ((i + 1 < tag.length) ? ',' : '');
        });
        if (tags !== '') {
            tagsFilter = `${tagsFilter} ${tags}`
        }
    }

    let hashtagFilter = '';
    if (hashtag && hashtag.length > 0) {
        if (user || (tag && tag.length > 0)) {
            hashtagFilter = hashtagFilter + ',';
        }
        let hashtags = '';

        hashtag.forEach((h, i) => {
            hashtags = hashtags + `{hashtags: {contains: "${h}"}}` + ((i + 1 < hashtag.length) ? ',' : '');
        });
        if (hashtags !== '') {
            hashtagFilter = `${hashtagFilter} ${hashtags}`
        }

    }

    let emotionFilter = '';
    if (emotion && emotion.length > 0) {
        if (user || (tag && tag.length > 0) || (hashtag && hashtag.length > 0)) {
            emotionFilter = emotionFilter + ',';
        }
        let emotions = '';

        emotion.forEach((e, i) => {
            emotions = emotions + `{emotionsFilter: {contains: "${e}"}}` + ((i + 1 < emotion.length) ? ',' : '');
        });
        if (emotions !== '') {
            emotionFilter = `${emotionFilter} ${emotions}`
        }
    }

    let favoritesFilter = '';
    if (favorites) {
        if (filter.favGroup && filter.favGroup === 'all') {
            userFilter = '';
        }
        if (tagsFilter.length>0 || hashtagFilter.length>0 || emotionFilter.length>0){// (userFilter !== '' || (tag && tag.length > 0) || (hashtag && hashtag.length > 0) || (emotion && emotion.length > 0))) {
            favoritesFilter = favoritesFilter + ',';
        }
        let favs = `{favorites: {contains: "${user}"}}`;
        favoritesFilter = `${favoritesFilter} ${favs}`;
    }

    let timeFilter = '';
    if (time) {
        if (userFilter !== '' || (tag && tag.length > 0) || (hashtag && hashtag.length > 0) || (emotion && emotion.length > 0) || favoritesFilter !== '') {
            timeFilter = timeFilter + ',';
        }
        let timeEpoch = `{timestampEpoch: {gt: ${time.start}, lt: ${time.end}}}`;
        timeFilter = `${timeFilter} ${timeEpoch}`;
    }
    let statusFilter = '';
    if (status) {
        if (userFilter !== '' || (tag && tag.length > 0) || (hashtag && hashtag.length > 0) || (emotion && emotion.length > 0) || favoritesFilter !== '' || timeFilter !== '') {
            statusFilter = statusFilter + ',';
        }
        if (status === 'public') {
            let statusData = `{status: {contains: "published"}}`;
            statusFilter = `${statusFilter} ${statusData}`;
        }
    }


    let result = count ? `items{idGame}` : `items{${items}}`;

    let filterString = '';
    if (userFilter !== '' || tagsFilter !== '' || hashtagFilter !== '' || emotionFilter !== '' || favoritesFilter !== '' || timeFilter !== '' || statusFilter !== '') {
        //filterString = `filter: {and:[${userFilter}${tagsFilter}${hashtagFilter}${emotionFilter}${favoritesFilter}${timeFilter}${statusFilter}]}`;
        filterString = `filter: {and:[${tagsFilter}${hashtagFilter}${emotionFilter}${favoritesFilter}${timeFilter}${statusFilter}]}`;
    }
    let params = '';
    if (filterString !== '' || t !== '' || l !== '') {
        //params = `(${filterString}${t}${l})`;
        params = `(${userFilter2}${filterString}${t}${l})`;
    }
   
    const retQuery=
    `
    query MyQuery {
        listeMaster20GamesPartiQL ${params} {
            nextToken
            ${result}
        }
    }
   `
   
    return retQuery; 
    //return `
    //query MyQuery {
    //  listMasterGames${params} {
    //    nextToken
    //    ${result}
    //  }
    //}
    //`
}
