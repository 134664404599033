import { Label, Card, Image, Button } from "semantic-ui-react";
import React, { useEffect, useState, useContext } from "react";

//import { userData, connectionData } from "../../../services/userParams.js";
import { UserContext } from "../../../services/userContext";
import { chargeImageToIPFSpinata1 } from "../../../services/Game/chargeImageToIPFSpinata";
import MaticLogo from "../../../shared/assets/icons/matic_cryptocurrency_logo_icon_131424.svg";
import NFTLogo from "../../../shared/assets/icons/NFT.svg";
import { getTokenizeStatus } from "../../../services/userWallet";

console.log("TOKENIZEPLANET-COMPONENT-MODULE-INIT");

const tokenizeStatus = {
  DONT_READ_STATUS: "Don't read status",
  NO_TOKENIZE: "no tokenize",
  GETTING_BLOB: "getting cors image from S3 to blob",
  SAVING_BLOB: "saving blob from image",
  CONVERTING_BLOB: "blob convert base64 to binary",
  SENDING_IMG_TO_IPFS: "sending image to IPFS network filesystem",
  CREATING_JSON_METADATA: "creating json metadata from IPFS png response",
  SENDING_JSON_TO_IPFS: "sending json from image to IPFS network filesystem",
  SENDING_DATA_TO_CONTRACT: "sending IPFS uri and metadata to contract",
  MINTING_NFT: "mint the New NFT with PRICE in EmotionyAsset contract",
  TOKENIZED: "!!! EmotionyAsset NFT tokenized!!!",
};

const Tokenize = (props) => {
  console.log("---TOKENIZEPLANET-COMPONENT-INIT---");
  var tokenizeProps = props;
  var planetId = tokenizeProps.planetId;
  var isConfigNetWorkId = false;

  var [stateTokenize, setStateTokenize] = useState(tokenizeStatus.NO_TOKENIZE);
  var [stateDisableTokenize, setStateDisableTokenize] = useState(false);
  var [labelText, setLabelText] = useState("No Info");
  var [userState, dispatchUser] = useContext(UserContext);
  var _reducer = undefined;

  const handleTokenize = (event, ButtonProps) => {
    console.log("EVENT", event, "BUTTONPROPS", ButtonProps); //, "MANAGER:", manager);
    console.log("USERDATA", userState.userData);

    console.log("PLANETID", planetId);
    if (userState.userData.connectionData) {
      let connectionData = userState.userData.connectionData;
      if (userState.userData.isConfigNetWorkId) {
        
        console.log("TOKENIZEPLANET: IS CONFIG NETWORK ID");
        //manager.chargeImageToIPFSpinata(event,item,setStateTokenize,tokenizeStatus,setStateDisableTokenize);
        _reducer={userState:userState,dispatchUser:dispatchUser};
        chargeImageToIPFSpinata1(
          event,
          planetId,
          setStateTokenize,
          tokenizeStatus,
          setStateDisableTokenize,
          _reducer
        );
      } else {
        alert(
          `PLEASE CONNECT TO A CORRECT NETWORK: ${connectionData.configNetWork.name} AT NETWORKID: ${connectionData.config.networkId}`
        );
      }
    }
  };

  useEffect(() => {
    if (props) {
      console.log("TOKENIZEPLANET: USEEFFECT PROPS", props);
    }
  }, [props]);

  useEffect(() => {
    console.log(" TOKENIZEPLANET USEFFECT:");
    let connectionData = userState.userData.connectionData;

    if (connectionData) {
      console.log(" TOKENIZEPLANET USEFFECT: CONNECTIONDATA=", connectionData);

      if (connectionData.connectedBlockchain) {
        console.log(
          " TOKENIZEPLANET USEFFECT: CONNECTED_BLOCKCHAIN=",
          connectionData.connectedBlockchain
        );

        getTokenizeStatus(
          planetId,
          setStateDisableTokenize,
          setStateTokenize,
          tokenizeStatus,
          setLabelText
        );

        console.log("TOKENIZEPLANET USEFFECT: executed getTokenizeStatus()...");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData.connectionData.connectedBlockchain]);

  return (
    <>
      <Button
        fluid
        onClick={handleTokenize}
        size="big"
        disabled={stateDisableTokenize}
        className="filled"
      >
        Tokenize
      </Button>

      {userState.userData.userAddressId ? (
        <Label className="ui label black"> Status: <br/> {stateTokenize}</Label>
      ) : (
        <Label className="ui label black">{labelText}</Label>
      )}
    </>
  );
};

export default Tokenize;
