import Web3 from "web3";
import { getUserDevice } from "../core/device";
import EmotionyAsset from "../core/abis/EmotionyAsset.json";
import CowSailContract from "../core/abis/CowSailContract.json";
import { createHashOperation } from "../core/blockchain/blockchainSigner";
import { getConfiguration } from "../core/blockchain/configuration.js";
import { wsLogin } from "./WebSockets/wsLogin";
import {
  //connectionData,
  //getUser,
  //getUserSigned,
  //setSession,
  setUser,
  //userData,
  //userDefault,
} from "./userParams.js";

import { _getUser, _getUserSigned } from "./userParamsToReducer";

import detectEthereumProvider from "@metamask/detect-provider";
import { getVars, setVars } from "../core/initVarsAndFunctions";
import { userDataDefault } from "./userDefault";
import localChains from "../core/blockchain/localChains.json";

console.log("USERWALLET-MODULE-INIT");

var windowProps = undefined;
var local_reducer = undefined;
var contractEmotionyAsset = undefined;
var contractCowsail = undefined;
var connectedBlockchain = false;
var existContractAddress = false;
var hasShowContractComprobations = false;
var _connectionData = undefined;
var _userData = undefined;


export const getContractEmotiony = () => {
  return contractEmotionyAsset;
};

export const getContractCowSail = () => {
  return contractCowsail;
};

//usada en ./src/components/Metrics/Totals/TotalsStatistics.jsx
export const getUserDatainWallet = () => {
  _userData = local_reducer.userState.userData;
  return _userData; //userData;
}

export const setReducer = (_reducer) => {
  local_reducer = _reducer;
  _userData = local_reducer.userState.userData;
  _connectionData = local_reducer.userState.userData.connectionData;
}

export const getConnectionDatainWallet = () => {
  _connectionData = local_reducer.userState.userData.connectionData;
  return _connectionData;
}



export const metamask = async (props, _reducer) => {
  console.log("\n\n---------------METAMASK--------------\n\n");
  local_reducer = _reducer;
  console.log(
    "REDUCER:_reducer={userState:userState=",
    _reducer.userState,
    ",dispatchUser:dispatchUser}",
    _reducer.dispatchUser
  );

  await _reducer.dispatchUser({
    type: "SET_HAS_WINDOW_ETHEREUM",
    payload: false,
  });

  if (window.ethereum) {
    console.log("userWallet:has window.ethereum");
    window.web3 = new Web3(window.ethereum);
    try {
      window.web3.eth
        .requestAccounts() // Show MetaMask Notification to select account
        .then(async () => {
          await _reducer.dispatchUser({
            type: "SET_HAS_WINDOW_ETHEREUM",
            payload: true,
          });

          windowProps = props;
          var web3 = window.web3; // BlockChainConnection
          // get accounts from the blockchain provider

          await _reducer.dispatchUser({
            type: "SET_ACCOUNTS",
            payload: await web3.eth.getAccounts(),
          });
          console.log("ACCOUNTS FROM _reducer:", _userData.accounts);
          //IF ADDRESS IN METAMASK AND STORAGE

          if (
            _userData.accounts[0] ===
            //_reducer.userState.getUserStateFromStorage().userData.userAddressId;
            _userData.userAddressId
          ) {
            // GET DATA FROM STORAGE
            console.log("GET USER DATA FROM STORAGE!!!");
            getUserData(null);
          } else {
            // GET NEW SESSION IN SIGN PROCESS

            getWalletSession(_userData.accounts[0]);
          }
        })
        .catch((err) => {
          // Cancel selection
          console.log(err);
        });
    } catch (e) {
      console.log("userWallet:ERROR AT CONNECTION:", e);
      //alert("userWallet:ERROR AT CONNECTION:", e);
      loadblockchaindata();
    }
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
    console.log("userWallet: window.web3");
    loadblockchaindata();
  } else {
    var ap = getUserDevice();
    console.log("USERDEVICE=" + ap);
    if (ap === "mobile") {
      var dappUrl = window.location.href.split("//")[1].split("/")[0];
      var metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
      //alert("USERWALLET: metamaskAppDeepLink="+metamaskAppDeepLink);
      await window.open(metamaskAppDeepLink, "_self");
      alert("mobile: window ethereum");
      window.web3 = new Web3(window.ethereum);
      try {
        window.web3.eth
          .requestAccounts() // Show MetaMask Notification to select account
          .then(async () => {
            await _reducer.dispatchUser({
              type: "SET_HAS_WINDOWS_ETHEREUM",
              payload: true,
            });

            windowProps = props;
            var web3 = window.web3; // BlockChainConnection
            //IF ADDRESS IN METAMASK AND STORAGE
            // get accounts from the blockchain provider

            await _reducer.dispatchUser({
              type: "SET_ACCOUNTS",
              payload: await web3.eth.getAccounts(),
            });

            console.log("ACCOUNTS FROM _reducer:", _userData.accounts);

            if (
              _reducer.userState.userData.accounts[0] === //local_accounts[0] ===
              //_reducer.userState.getUserStateFromStorage().userData.userAddressId
              _reducer.userState.userData.userAddressId
            ) {
              // GET DATA FROM STORAGE
              console.log("GET USER DATA FROM STORAGE IN MOBILE DEVICE");
              getUserData(null);
            } else {
              // GET NEW SESSION IN SIGN PROCESS

              getWalletSession(_userData.accounts[0]);
            }
          })
          .catch((err) => {
            // Cancel selection
            console.log(err);
          });
      } catch (e) {
        console.log("userWallet:ERROR AT CONNECTION:", e);
        alert("userWallet:ERROR AT CONNECTION:", e);
        loadblockchaindata();
      }
    } else {
      window.open("https://metamask.io/download.html", "_blank");
    }
  }
};

// GET NEW SESSION WITH SIGN PROCESS
const getWalletSession = async (publicAddress) => {
  console.log("\n\n----------GETWALLETSESSION-----------\n\n");

  console.log("sessionStorage.setItem('loging', 'true')");
  await local_reducer.dispatchUser({
    type: "SET_LOGGING",
    payload: "true",
  });
  await local_reducer.dispatchUser({
    type: "SET_USER_ADDRESS_ID",
    payload: publicAddress,
  });

  var hashLogin = createHashOperation(publicAddress);

  setVars(
    "webSocketLogin",
    await new wsLogin(publicAddress, hashLogin, local_reducer)
  );

  var sendPublicAddress = {
    action: "sendPublicAddress",
    wallet: "METAMASK",
    publicAddress: publicAddress,
    hashLogin: hashLogin,
  };
  console.log("INIT SIGN IN WSLOGIN:", local_reducer);
  getVars("webSocketLogin").send(JSON.stringify(sendPublicAddress));
};

export const disconnectMetaMask = (pageProps) => {
  sessionStorage.clear();
  local_reducer.dispatchUser({type: "SET_SESSION",payload:undefined});
  local_reducer.dispatchUser({type: "SET_TYPE",payload:undefined});
  local_reducer.dispatchUser({type: "SET_INITIAL_STATE",payload:userDataDefault()});
 
  //setUser(null);

  //setUser(userDefault);
  if (pageProps && pageProps.toindexData === 1) {
    pageProps.history.push("/");
  } else {
    pageProps.history.push("/login");
  }

  console.log("Metamask Offline");
};

export const getUserData = async (sessionData) => {
  console.log("\n\n--------GET USER DATA---------\n\n");
  let session = null;
  if (sessionData && sessionData.session) {
    session = sessionData.session;
    console.log("SESSION FROM SESSIONDATA IS=", session);
    //(props, signed, session = null)
    getUserAddressId(windowProps, false, session);
  }
  if (!sessionData) {
    console.log("---SESSION FROM STORAGE IN REDUCER---");
    //session = sessionStorage.getItem("session");
    session = local_reducer.userState.session;
    console.log("SESSION FROM USER REDUCER IS=", session);
    //(props, signed, session = null)
    getUserAddressId(windowProps, true, session);
  }
};

export const getUserBalance = (web3, account) => {
  return web3.eth.getBalance(account, (err, result) => {
    if (err) {
      console.log(err);
    } else {
      web3.utils.fromWei(result, "ether");
      //console.log(web3.utils.fromWei(result, "ether") + " ETH")
    }
  });
};

const getUserAddressId = async (props, signed, session = null) => {
  console.log("\n\n-------GET USERADDRESS ID-----");
  console.log("PROPS:\n", props, "SIGNED:", signed, "SESSION:\n", session);
  console.log("WINDOW:\n", window);
  console.log("-------GET USERADDRESS ID-----\n\n");

  var web3 = window.web3; // BlockChainConnection
  // userData=local_reducer.userState.userData;
  console.log("local_reducer", local_reducer);
  if (_userData.hasWindowEthereum) {
    console.log(" HAS WINDOWS ETHEREUM IN REDUCER USERSTATE!");
    var user = undefined;
    var account = undefined;

    if (!_userData.accounts) {
      // get accounts from blockchain provider
      console.log("NOT HAVE ACCOUNTS IN REDUCER USERSTATE, SETTING NOW!");
      await local_reducer.dispatchUser({
        type: "SET_ACCOUNTS",
        payload: await web3.eth.getAccounts(),
      });
    }

    account = _userData.accounts[0];

    if (signed) {
      console.log("GET-USER_SIGNED");
      user = await _getUserSigned(account, session);
    } else {
      console.log("GET-USER_UNSIGNED");
      user = await _getUser(account);
    }
    console.log("USER", user);
    //console.log("STORAGE:", sessionStorage); // eliminated: Reducer has storage
    if (user) {
      console.log("\n\n---------GETUSERADDRESSID: HAS USER:-----------\n\n");
      console.log("USER GETBALANCE");
      await getBalance(web3, account);

      if (!signed && session) {
        console.log("!SIGNED && SESSION", signed, session);
        //setUser(user);
        await local_reducer.dispatchUser({
          type: "SET_AWS_USER_DATA",
          payload: user,
        });
        //setSession(session);
        await local_reducer.dispatchUser({
          type: "SET_SESSION",
          payload: session,
        });
      }
      await loadblockchaindata();

      //const logging = local_reducer.userState.getUserStateFromStorage().userData.logging;
      const logging = _userData.logging;
      const queryString = window.location.pathname;
      console.log("LOGGING:", logging, " queryString:", queryString);

      if (
        logging === "true" &&
        (queryString.match(/\/login/g) || queryString === "/")
      ) {
        console.log("REMOVING LOGGING");
        await local_reducer.dispatchUser({
          type: "SET_LOGGING",
          payload: undefined,
        });

        //sessionStorage.setItem("type", "user");
        await local_reducer.dispatchUser({
          type: "SET_TYPE",
          payload: "user",
        });
        // remember: (example login)
        // window.location.pathname: "/login"
        // window.history.state.state.from.pathname: "/mygalaxy/undefined"
        console.log("PUSH PATHNAME", window.history.state.state.from.pathname);
        // if history includes galaxy --> goto galaxy/user , else --> goto previous page loaded in pathname.
        if (window.history.state.state.from.pathname.includes("mygalaxy")) {
          props.history.push(`/mygalaxy/${user.user}`);
          console.log("PUSH HISTORY:", `/mygalaxy/${user.user}`);
        } else {
          props.history.push(window.history.state.state.from.pathname);
          console.log(
            "PUSH HISTORY:",
            window.history.state.state.from.pathname
          );
        }
      }
    } else {
      console.log(
        "\n\n---------GETUSERADDRESSID: NOT USER!!!!:-----------\n\n"
      );
      console.log("------------------------------------ ");
      console.log("----------                ---------- ");
      console.log("----------  CLEAR SESSION ---------- ");
      console.log("----------                ---------- ");
      console.log("------------------------------------ ");
      // no user at system
      console.log("no user at system");
      sessionStorage.setItem('clearStorage',"user");
      await local_reducer.dispatchUser({
        type: "SET_SESSION",
        payload: undefined,
      });
      await local_reducer.dispatchUser({
        type: "SET_TYPE",
        payload: undefined,
      });
      await local_reducer.dispatchUser({
        type: "SET_INITIAL_STATE",
        payload: userDataDefault(),
      });

      //sessionStorage.clear();
      //setUser(null);
    }
  }
};

const getBalance = async (web3, account) => {
  let userBalance = await getUserBalance(web3, account);
  await local_reducer.dispatchUser({
    type: "SET_BALANCE",
    payload: userBalance,
  });
  console.log("BALANCE!!!! ", userBalance);
  setUser(local_reducer.userState.userData);
};

// CHARGE BLOCKCHAIN PARAMETERS IN MANAGER
export const loadblockchaindata = async () => {
  console.log("USERWALLETLOADBLOCKCHAINDATA");

  _userData = local_reducer.userState.userData;
  _connectionData = _userData.connectionData;

  // CHARGE CONFIGURATION OF THE CONNECTIONDATA
  //connectionData.config = getConfiguration();
  await local_reducer.dispatchUser({
    type: "SET_CONFIGURATION_CONNECTIONDATA",
    payload: getConfiguration(),
  });

  //LOAD LOCAL CRYPTO CHAINS NETWORKS OR WEB CRYPTO CHAINS NETWORKS
  var responseChains = false;
  // OBTAIN ALL BLOCKCHAIN NETWORK INFO FROM THE PAGE:
  await fetch("https://chainid.network/chains.json")
    .then((response) => {
      if (response.ok) {
        console.log("https://chainid.network/chains.json downloaded OK");
        responseChains = true;
      }
      return response.json();
    })
    .then(async (data) => {
      //connectionData.chainNetWorkInfoJson = data;
      await local_reducer.dispatchUser({
        type: "SET_CHAIN_NETWORK_INFO_JSON",
        payload: data,
      });
    });
  // IF NO WEB SOURCE CRYPTO CHAIN  INFO, LOAD AT LOCAL.
  if (!responseChains) {
    //connectionData.chainNetWorkInfoJson = localChains;
    await local_reducer.dispatchUser({
      type: "SET_CHAIN_NETWORK_INFO_JSON",
      payload: localChains,
    });
    console.log("chainid download failed!");
    console.log("Turn to local chainid info");
    console.log(
      "CHAIN NETWORK INFO JSON:",
      _connectionData.chainNetWorkInfoJson
    );
    console.log("LENGTH:", _connectionData.chainNetWorkInfoJso.length);
  }

  // SET THE CONFIG NETWORK INFO

  for (let i1 = 0; i1 < _connectionData.chainNetWorkInfoJson.length; i1++) {
    if (
      _connectionData.chainNetWorkInfoJson[i1].chainId ===
      _connectionData.config.networkId
    ) {
      //connectionData.configNetWork = connectionData.chainNetWorkInfoJson[i1];
      await local_reducer.dispatchUser({
        type: "SET_THE_CONFIG_NETWORK_INFO",
        payload: _connectionData.chainNetWorkInfoJson[i1],
      });
      console.log("CONFIG NETWORK INFO:", _connectionData.configNetWork);
    }
  }

  //LOAD WEB3 BLOCKCHAIN CONNECTION:
  const web3 = window.web3;
  console.log("---ACTUAL DATA IS:", local_reducer.userState);

  //SOLIDITY ACTUAL CONTRACT ADDRESS IN CONFIG
  var addressEmotionyAsset = _connectionData.config.contract_address;
  var emotionyAssetAbi = undefined;
  var cowsailAbi = undefined;

  // IF HAS BLOCKCHAIN, LOAD CORRECT NETWORKID CONFIG
  if (_userData.hasWindowEthereum) {
    console.log("BLOCKCHAINDATA: has window ethereum");

    //userData.accounts = await web3.eth.getAccounts(); // get accounts from the blockchain provider
    var networkId = await web3.eth.net.getId(); // get network id from the blochain provider.

    //userData.isConfigNetWorkId = false;
    //CONFIGURATE ID OF BLOCKCHAIN NETWORK
    await local_reducer.dispatchUser({
      type: "SET_CONFIG_NETWORKID",
      payload: false,
    });
    if (networkId === _connectionData.config.networkId) {
      //userData.isConfigNetWorkId = true;
      await local_reducer.dispatchUser({
        type: "SET_CONFIG_NETWORKID",
        payload: true,
      });
      console.log("NETWORK ID ", networkId, "IS CONFIG NETWORK ID");
    } else {
      //connectionData.config.networkId = networkId;
      await local_reducer.dispatchUser({
        type: "SET_NETWORKID",
        payload: networkId,
      });
      console.log("NETWORK ID ", networkId, "IS NOT CONFIG NETWORKID");
    }

    //SPECIAL GANACHE CASE AND CONFIG
    if (networkId === 5777) {
      addressEmotionyAsset = EmotionyAsset.networks[networkId].address;
      console.log("CONNECTED NETWORK  INFO: *********GANACHE NETWORK*********");
    }

    if (_connectionData.config.networkId === 5777) {
      if (networkId !== 5777) {
        console.log(
          "EXPECTED CONNECTING NETWORK  INFO: *********GANACHE NETWORK*********"
        );
      }
      await local_reducer.dispatchUser({
        type: "SET_THE_CONFIG_NETWORK_INFO",
        payload: {
          name: "GANACHE NETWORK (download page = https://www.trufflesuite.com/ganache )",
        },
      });
      console.log("CONFIG NETWORK INFO:", _connectionData.configNetWork);
    }

    //WEB3 CONNECTION TO BLOCKCHAIN NETWORK PARAMETERS
    // console.log("WEB3.ETH.NET", web3.eth.net);

    // ABI BINARY JSON CONNECTION TO CONTRACT FUNCTIONS.
    // IT'S MUST UP DATE
    emotionyAssetAbi = EmotionyAsset.abi;
    // MOOOOOOO CONTRACT
    cowsailAbi = CowSailContract.abi;
    // CONTRACT ENOTIONY ASSET INTERFACE CONSTRUCT

    //userData.userAddressId = userData.accounts[0];
    console.log("contract address::", addressEmotionyAsset);

    contractEmotionyAsset = new web3.eth.Contract(
      emotionyAssetAbi,
      addressEmotionyAsset
    );

    await local_reducer.dispatchUser({
      type: "SET_CONTRACT_EMOTIONY_ASSET",
      payload: getContractEmotiony,
    });

    // _userData.contractEmotionyAsset = new web3.eth.Contract(
    //   emotionyAssetAbi,
    //   addressEmotionyAsset
    // );

    console.log("contractEmotionyAsset:", contractEmotionyAsset);

    var addressCowsail;

    if (addressEmotionyAsset) {
      console.log("CONTRACT ADDRESS = ", addressEmotionyAsset);
      let comprobator = await Web3.utils.isAddress(addressEmotionyAsset);

      if (comprobator) {
        console.log("CONTRACT ADDRESS EXIST!:");
        console.log(" COWSAIL CONTRACT CALLING.");
        try {
          addressCowsail = await contractEmotionyAsset.methods
            .getCowSailAddress()
            .call();
          existContractAddress = true;
          console.log(
            "\nBLOCHCHAIN COMPROBATION AT NETWORK:",
            networkId,
            "\nFOR CONTRACT ADDRESS",
            addressEmotionyAsset,
            "\nIS OK AND THE COWSAIL CONTRACT ADRESSS CALL FUNCTION RETURNED:",
            addressCowsail
          );

          

        } catch (error) {
          console.log("ERROR AT INVOKE COWSAIL!!!", error);
        }
      } else {
        console.log(
          "ERROR!. No contract found in blockchain network:",
          networkId
        );
      }
    } else {
      console.log("ERROR!!!! CONTRACT ADDRESS NOT EXIST!");
    }
  }
  /* console.log("\n\nACCOUNTS:", accounts,
      "\nNETWORKID:", networkId,
      "\nMAINACCOUNT:", userData.userAddressId
    ) */
  // CREATES MANAGER IN ORDER TO ASYNCRONOUS PROCESS BLOCKCHAIN CONNECTION
  //createManagerNet(manager)
  connectedBlockchain = true;
  
  await local_reducer.dispatchUser({
    type: "SET_CONNECTED_BLOCKCHAIN",
    payload: connectedBlockchain
  });


  if (_userData.hasWindowEthereum && existContractAddress) {

    // SET CONTRACT COWSAIL
    contractCowsail = new web3.eth.Contract(cowsailAbi, addressCowsail);
    //userData.contractCowsail = contractCowsail;
    await local_reducer.dispatchUser({
      type: "SET_CONTRACT_COWSAIL",
      payload: getContractCowSail,
    });

    //userData.balance = await web3.eth.getBalance(userData.userAddressId);
    await local_reducer.dispatchUser({
      type: "SET_BALANCE",
      payload: await web3.eth.getBalance(_userData.userAddressId),
    });

    //userData.estimateGasCost = await web3.eth.getGasPrice();
    await local_reducer.dispatchUser({
      type: "SET_ESTIMATE_GAS_COST",
      payload: await web3.eth.getGasPrice(),
    });

    if (!hasShowContractComprobations) {
      console.log("COMPROBATES THE CONTRACT FUNCTIONALITY");
      _userData
        .contractEmotionyAsset()
        .methods.name()
        .call((err, result) => {
          console.log(
            "\nNAME OF THE EMOTIONY ASSET ERC721 CONTRACT: " + result
          );
        });
      //OTHER ASYNC CALL TO EMOTIONY ASSET CONTRACT FUNCTIONS:
      _userData
        .contractEmotionyAsset()
        .methods.symbol()
        .call((err, result) => {
          console.log(
            "\nSYMBOL OF THE EMOTIONY ASSET ERC721 CONTRACT: " + result
          );
        });
      _userData
        .contractEmotionyAsset()
        .methods.getVersion()
        .call((err, result) => {
          console.log(
            "\nVERSION OF THE EMOTIONY ASSET ERC721 CONTRACT: " + result
          );
        });

      // CALLS TO COWSAIL TO PROBE NORMAL FUNCTIONS
      // _userData
      //   .contractCowsail()
      contractCowsail
        .methods.defaultPricepayPerData().call((err, result) => {
          console.log("COWSAIL: PRICE PAY PER DATA =", result);
      });
      // _userData
      //   .contractCowsail()
      contractCowsail
        .methods.getBalance().call((err, result) => {
          console.log("COWSAIL: BALANCE OF COWSAIL CONTRACT =", result);
      });
      hasShowContractComprobations = true;
      //createManager(manager);
    }else{
      console.log("HAS SHOW CONTRACT COMPROBATIONS");
    }
  }
};

export const getTokenizeStatus = (
  planetId,
  setStateDisableTokenize,
  setStateTokenize,
  tokenizeStatus,
  setLabelText
) => {
  console.log("GET TOKENIZE STATUS:HANDLE STATE DISABLED TOKENIZE: ITEM=", planetId);
  console.log("GET TOKENIZE STATUS:CONNECTION DATA", _connectionData);
  console.log("GET TOKENIZE STATUS:USER DATA", _userData);
  if (connectedBlockchain) {
    // MANAGE ITEM NAME COMPARE WITH STORED TOKEN DATA
    if (_userData.tokensOfOwner.tokens.length > 0) {
      console.log("GET TOKENIZE STATUS:READING FROM STORED TOKENS DATA");
      for (let i = 0; i < _userData.tokensOfOwner.tokens.length; i++) {
        let dataResult = _userData.tokensOfOwner.tokens[i];
        let nftName = dataResult.nameNFT;
        let itemName = planetId;
        console.log("GET TOKENIZE STATUS: STORED DATA TOKEN:",
          dataResult.numToken,
          " NAME: ",
          nftName
        );
        console.log("GET TOKENIZE STATUS:STORED DATA TOKEN: COMPARING ITEM.IDGAME:", itemName);
        if (nftName === itemName) {
          console.log(nftName, "GET TOKENIZE STATUS: IS TOKENIZED!!!");

          setStateDisableTokenize(true);
          setStateTokenize(tokenizeStatus.TOKENIZED);
          setLabelText(
            (
              "Metadata: " +
              dataResult.tokenUri +
              "\nImage Link: " +
              dataResult.jsonData.image_url
            ).toString()
          );
        }
      }
    } else {
      console.log("GET TOKENIZE STATUS: >>>>>>>>>> GET TOKENS FROM CONTRACT CALL >>>>>>>>> ");
      // GET TOKENS OF OWNER (MAINACCOUNT) (CONTRACT CALL)
      _userData
        .contractEmotionyAsset()
        .methods.getTokensOfOwner(_userData.userAddressId)
        .call(async (err, arrayResult) => {
          if (err) {
            console.log("GET TOKENIZE STATUS:ERROR!!!", err);
          }
          console.log(
            "GET TOKENIZE STATUS: GET TOKENS OF OWNER: ",
            _userData.userAddressId,
            "IS:",
            arrayResult
          );

          // SET TO BLOCKCHAIN ARRAYTOKENS
          // userData.tokensOfOwner.owner = userData.userAddressId;

          await local_reducer.dispatchUser({
            type: "SET_TO_BLOCKCHAIN_ARRAYTOKENS_OWNER",
            payload: _userData.userAddressId,
          });

          // GET INDIVIDUAL VALUES OF ARRAYLIST TOKENS OF OWNER (MAINACCOUNT)
          if (arrayResult) {
            console.log("GET TOKENIZE STATUS: ARRAYRESULT IS=",arrayResult);
            for (let i = 0; i < arrayResult.length; i++) {
              console.log("GET TOKENIZE STATUS:VALUE:", arrayResult[i]);
              // GET TOKENURI OF ACTUAL INDIVIDUAL TOKEN OF OWNER (MAINACCOUNT) (CONTRACT CALL)

              _userData
                .contractEmotionyAsset()
                .methods.tokenURI(arrayResult[i])
                .call(
                  // eslint-disable-next-line no-loop-func
                  (err1, result1) => {
                    if (err1) {
                      console.log("GET TOKENIZE STATUS:ERROR!!!", err1);
                    }
                    console.log(
                      "GET TOKENIZE STATUS:tokenURI of:",
                      arrayResult[i],
                      " is: ",
                      result1
                    );
                    // GET JSON FROM THIS URI
                    fetch(result1)
                      .then((response) => response.json())
                      .then(async (data) => {
                        console.log(
                          "GET TOKENIZE STATUS:JSON DATA OF TOKENURI:",
                          result1,
                          " IS: ",
                          data
                        );
                        // GET NAME OF NFT FROM JSON DATA
                        let nameNFT = data.name.split(":");

                        console.log(
                          "GET TOKENIZE STATUS:NAME OF NFT in ",
                          arrayResult[i],
                          " IS: ",
                          nameNFT[1]
                        );
                        console.log("GET TOKENIZE STATUS:COMPARING ITEM.IDGAME:", planetId);
                        // iF NAME OF NFT FROM JSON DATA == ITEM.ITEM.IDGAME, THEN !!!IS TOKENIZED!!!
                        let dataResult = {
                          numToken: arrayResult[i],
                          tokenUri: result1,
                          jsonData: data,
                          nameNFT: nameNFT[1],
                        };
                        if (nameNFT[1] === planetId) {
                          console.log("GET TOKENIZE STATUS:",nameNFT[1], " IS TOKENIZED!!!");
                          setStateDisableTokenize(true);
                          setStateTokenize(tokenizeStatus.TOKENIZED);

                          setLabelText(
                            (
                              "Metadata: " +
                              dataResult.tokenUri +
                              "\nImage Link: " +
                              dataResult.jsonData.image_url
                            ).toString()
                          );
                        }
                        //_userData.tokensOfOwner.tokens.push(dataResult);
                        await local_reducer.dispatchUser({
                          type: "SET_TOKENS_OF_OWNER",
                          payload: dataResult,
                        });
                      });
                  }
                );
            }
          }
        });
    }
  } else {
    console.log(
      "GET TOKENIZE STATUS:TOKENIZE STATUS ERROR!. NOT CONNECTED TO BLOCKCHAIN, PLEASE CONNECT FIRST"
    );
    setStateDisableTokenize(true);
    setStateTokenize(tokenizeStatus.DONT_READ_STATUS);
  }
}
