import React, { useEffect, useState, useContext } from 'react';
import "./MyGalaxy.scss";
import UserHeader from '../../components/User/UserHeader';
import Galaxy from '../../components/Galaxy/Galaxy.js';

import { useHistory } from 'react-router-dom';
import { UserContext} from "../../services/userContext"

console.log("MYGALAXY-PAGE MODULE-INIT");

const MyGalaxy = () => {

    console.log("---MY GALAXY PAGE INIT----")
    const [userState, dispatchUser] = useContext(UserContext);
    const history = useHistory();
 
    useEffect(()=>{
        window.scrollTo(0, 0);
         if(userState.userData.userAddressId===undefined){
            console.log("---MY GALAXY: NO USER:--");
            console.log("------------------PUSHED HISTORY /LOGIN");
            history.push({
                pathname: "/login",
                state: {
                    response: 'err404'
                }
            });
        } 

    },[userState.userData]);

    return (
              <>
              <UserHeader userData={userState.userData} />
              <Galaxy userData={userState.userData} />
              </>
    );

}

export default MyGalaxy;