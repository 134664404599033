import React from "react";
import Box from "./Box";

console.log("PRICING-COMPONENT-MODULE-INIT");

const PricingComponent = () => {

  console.log("---PRICING-COMPONENT-INIT---");
  
    const featureBox1 = [
      "Apply any filter per query",
      "Storage for all your queries",
      "10 querys per month",
      "Anual Suscription"
    ];
    const featureBox2 = [
      "Apply 15 filters wherever you want",
      "Storage for all your queries",
      "Priority email support",
      "Help center access"
    ];
    const featureBox3 = [
      "Apply 150 filters wherever you want",
      "Storage for all your queries",
      "Phone and email support",
      "Help center access"
    ];
    const featureBox4 = [
      "Apply any filters wherever you want",
      "Storage for all your queries",
      "Phone and email support",
      "Help center access"
    ];
    return (
      <>
        <Box
          price="9.95$"
          title="Basic"
          extra="Try 1 Month Free Basic"
          feature={featureBox1}
        />

        <Box
          price="49.95$"
          title="Pro"
          extra="Try 1 Month Free Pro"
          feature={featureBox2}
        />

        <Box
          price="99.95$"
          title="Premium"
          extra="Try 1 Month Free Premium"
          feature={featureBox3}
        />
      
        <Box
          price="Contact us"
          title="Enterprise"
          extra="Contact us"
          feature={featureBox4}
        />
      </>
    );
  };
  
  export default PricingComponent;
  