import { Container, Icon } from 'semantic-ui-react';
import './ProfileForm.scss';

console.log("PROFILEFORM-COMPONENT-MODULE-INIT");

const ProfileForm = (props) => {

    console.log("---PROFILEFORM-COMPONENT-INIT---");

    var tab = 1;

    const activeTab = (value) => {
        tab = value;
    }

    return (
        <>
            <Container>
                <div className='profileForm'>
                    <div className='tabs'>
                        <div className={`tab-item profile ${tab === 1 ? 'active' : ''}`} onClick={() => activeTab(1)}>
                            <Icon name='id card'></Icon>
                            My Profile
                        </div>
                        <div className={`tab-item activity ${tab === 2 ? 'active' : ''}`} /* onClick={() => activeTab(2)} */ data-tooltip="Coming soon!" data-variation="tiny">
                            <Icon name='tasks'></Icon>
                            Activity
                        </div>
                    </div>
                    <div className={tab === 1 ? '' : 'hide'}>
                    </div>
                    <div className={tab === 2 ? '' : 'hide'}>
                        AAAAAA
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ProfileForm;