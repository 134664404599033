// USER REDUCER PARTS:

// userAction.type
// userAction.payload
// usersAction.error
// userAction.meta
// userAction.OTHER <= ( all other things! )

console.log("USERREDUCER-MODULE-INIT");

export const userReducer = (userState, userAction) => {
  var result = null;

  console.log(
    "USER-REDUCER: Action Type: ",
    userAction.type,
    " userAction.payload: ",
    userAction.payload
  );

  switch (userAction.type) {
    case "SET_AWS_USER_DATA":
      result = userState;

      result.userData.userDescription = userAction.payload.userDescription; // userProfile.data.getMasterUser.userDescription,
      result.userData.userAddressId = userAction.payload.userAddressId; // userProfile.data.getMasterUser.userAddressId
      result.userData.balance = userAction.payload.balance; // contracts.balance
      result.userData.user = userAction.payload.user; //userProfile.data.getMasterUser.user,
      result.userData.joined = userAction.payload.joined; //userProfile.data.getMasterUser.joined,
      result.userData.publicProfile = userAction.payload.publicProfile; //userProfile.data.getMasterUser.publicProfile,
      result.userData.myGalaxyName = userAction.payload.myGalaxyName; //userProfile.data.getMasterUser.myGalaxyName,
      result.userData.avatarLink = userAction.payload.avatarLink; // userProfile.data.getMasterUser.avatarLink,
      result.userData.backgroundImageLink =
        userAction.payload.backgroundImageLink; //
      result.userData.userDescription = userAction.payload.userDescription; //userProfile.data.getMasterUser.userDescription,
      result.userData.email = userAction.payload.email; //userProfile.data.getMasterUser.email,
      result.userData.realName = userAction.payload.realName; // userProfile.data.getMasterUser.user,
      result.userData.webAddress = userAction.payload.webAddress; //userProfile.data.getMasterUser.webAddress,
      result.userData.twitterAccount = userAction.payload.twitterAccount; //userProfile.data.getMasterUser.twitterAccount,
      result.userData.instagramAccount = userAction.payload.instagramAccount; // userProfile.data.getMasterUser.instagramAccount,
      result.userData.discordAccount = userAction.payload.discordAccount; // userProfile.data.getMasterUser.discordAccount,
      result.userData.redditAccount = userAction.payload.redditAccount; // userProfile.data.getMasterUser.redditAccount,
      result.userData.sessions = userAction.payload.sessions; //userSummary.data.getMasterUser.currentSessionIds,
      result.userData.tags = userAction.payload.tags; //userProfile.data.getMasterUser.tags,
      result.userData.hashtags = userAction.payload.hashtags; //userSummary.data.getMasterUser.currentHashtags,
      result.userData.currentFavoritePlanetIds =
        userAction.payload.currentFavoritePlanetIds; //userSummary.data.getMasterUser.currentFavoritePlanetIds,
      result.userData.currentFavoritePlanetIdsCount =
        userAction.payload.currentFavoritePlanetIdsCount; //userSummary.data.getMasterUser.currentFavoritePlanetIdsCount
      result.userData.subscriptionIds = userAction.payload.subscriptionIds; // userSummary.data.getMasterUser.subscriptionIds,
      result.userData.subscriptionKeys = userAction.payload.subscriptionKeys; // userSummary.data.getMasterUser.subscriptionKeys,
      result.userData.queryHashes = userAction.payload.queryHashes; // userSummary.data.getMasterUser.queryHashes,
      result.userData.currentPublicPlanetIds =
        userAction.payload.currentPublicPlanetIds; // userSummary.data.getMasterUser.currentPublicPlanetIds,
      result.userData.currentPublicPlanetsCount =
        userAction.payload.currentPublicPlanetsCount; // userSummary.data.getMasterUser.currentPublicPlanetsCount,
      result.userData.currentPlanetIds = userAction.payload.currentPlanetIds; // userSummary.data.getMasterUser.currentPlanetIds,
      result.userData.currentPlanetIdsCount =
        userAction.payload.currentPlanetIdsCount; // userSummary.data.getMasterUser.currentPlanetIdsCount,
      result.userData.currentHashtagsCount =
        userAction.payload.currentHashtagsCount; // userSummary.data.getMasterUser.currentHashtagsCount
      result.userData.currentLocations = userAction.payload.currentLocations;
      break;

    case "SET_USER_ADDRESS_ID":
      result = userState;
      result.userData.userAddressId = userAction.payload;
      break;

    case "SET_LOGGING":
      result = userState;
      result.userData.logging = userAction.payload;
      break;

    case "SET_HAS_WINDOW_ETHEREUM":
      result = userState;
      result.userData.hasWindowEthereum = userAction.payload;
      break;

    case "SET_ACCOUNTS":
      result = userState;
      result.userData.accounts = userAction.payload;
      break;

    case "SET_INITIAL_STATE":
      result = userState;
      result.userData = userAction.payload;
      break;

    case "SET_SESSION":
      result = userState;
      result.session = userAction.payload;
      break;

    case "SET_TYPE":
      result = userState;
      result.type = userAction.payload;
      break;

    case "SET_BALANCE":
      result = userState;
      result.balance = userAction.payload;
      break;

    case "SET_CONFIGURATION_CONNECTIONDATA":
      result = userState;
      result.userData.connectionData.config.networkId =
        userAction.payload.networkId;
      result.userData.connectionData.config.contract_address =
        userAction.payload.contract_address;
      result.userData.connectionData.config.tokenImage =
        userAction.payload.tokenImage;
      result.userData.connectionData.config.pinataApiKey =
        userAction.payload.pinataApiKey;
      result.userData.connectionData.config.pinataSecretApiKey =
        userAction.payload.pinataSecretApiKey;
      result.userData.connectionData.config.url = userAction.payload.url;
      result.userData.connectionData.config.urlJson =
        userAction.payload.urlJson;
      result.userData.connectionData.config.emotionyApp1S3Planets =
        userAction.payload.emotionyApp1S3Planets;
      break;

    case "SET_CONFIG_NETWORKID":
      result = userState;
      result.userData.isConfigNetWorkId = userAction.payload;
      break;

    case "SET_NETWORKID":
      result = userState;
      result.userData.connectionData.config.networkId = userAction.payload;
      break;

    case "SET_CHAIN_NETWORK_INFO_JSON":
      result = userState;
      result.userData.connectionData.chainNetWorkInfoJson = userAction.payload;
      break;

    case "SET_THE_CONFIG_NETWORK_INFO":
      result = userState;
      result.userData.connectionData.configNetWork = userAction.payload;
      break;

    case "SET_CONTRACT_EMOTIONY_ASSET":
      result = userState;
      result.userData.contractEmotionyAsset = userAction.payload;
      break;

    case "SET_ESTIMATE_GAS_COST":
      result = userState;
      result.userData.estimateGasCost = userAction.payload;
      break;

    case "SET_CONTRACT_COWSAIL":
      result = userState;
      result.userData.contractCowSail = userAction.payload;
      break;

    case "SET_TO_BLOCKCHAIN_ARRAYTOKENS_OWNER":
      result = userState;
      result.userData.tokensOfOwner.owner = userAction.payload;
      break;

    case "SET_TOKENS_OF_OWNER":
      result = userState;
      result.userData.tokensOfOwner.tokens.push(userAction.payload);
      break;

    case "SET_CONNECTED_BLOCKCHAIN":
      result = userState;
      result.userData.connectionData.connectedBlockchain = userAction.payload;
      break;

    case "SET_ITEMS_HEADER":
      result = userState;
      // SEARCH IN LIST ITEMS IN PAYLOAD
      // SEARCH IN LIST ITEMS IN USERDATA
      // IF MATCH ITEM.idGAme, ADD DATA AT USERDATA ITEM.
      for (let i = 0; i < userAction.payload.items.length; i++) {
        let itemPayload = userAction.payload.items[i];
        for (let j = 0; j < result.userData.items.length; j++) {
          let itemUserData = result.userData.items[j];
          if (itemPayload.idGame === itemUserData.idGame) {
            itemUserData.planetOperation = itemPayload.planetOperation;
            itemUserData.userAddressId = itemPayload.userAddressId;
            itemUserData.connectionid = itemPayload.connectionid;
            itemUserData.connectionData = itemPayload.connectionData;
            itemUserData.sourceip = itemPayload.sourceip;
            itemUserData.requesttime = itemPayload.requesttime;
            itemUserData.emotions = itemPayload.emotions;
            itemUserData.description = itemPayload.description;
            itemUserData.gameDesign = itemPayload.gameDesign;
            itemUserData.generalData.gameId = itemPayload.generalData.gameId;
            itemUserData.generalData.emoViewConnectionID =
              itemPayload.generalData.emoViewConnectionID;
            itemUserData.generalData.posnegPlanet =
              itemPayload.generalData.posnegPlanet;
            itemUserData.generalData.geoloc.accuracy =
              itemPayload.generalData.geoloc.accuracy;
            itemUserData.generalData.geoloc.latitude =
              itemPayload.generalData.geoloc.latitude;
            itemUserData.generalData.geoloc.longitude =
              itemPayload.generalData.geoloc.longitude;
            itemUserData.generalData.pngId = itemPayload.generalData.pngId;
            itemUserData.generalData.deviceId =
              itemPayload.generalData.deviceId;
            itemUserData.generalData.email = itemPayload.generalData.email;
            itemUserData.generalData.username =
              itemPayload.generalData.username;
            itemUserData.hashtags = itemPayload.hashtags;
            itemUserData.tags = itemPayload.tags;
            itemUserData.quote = itemPayload.quote;
            itemUserData.status = itemPayload.status;
            itemUserData.textGame = itemPayload.textGame;
            itemUserData.timestampEpoch = itemPayload.timestampEpoch;
            itemUserData.favorites = itemPayload.favorites;
            itemUserData.location = itemPayload.location;
            itemUserData.locationFilter = itemPayload.locationFilter;
            itemUserData.locationPretty = itemPayload.locationPretty;
            itemUserData.likes = itemPayload.likes;
          }
        }
      }
      break;

    case "SET_ITEMS_GAMES":
      result = userState;
      for (let i = 0; i < userAction.payload.items.length; i++) {
        let item = userAction.payload.items[i];
        result.userData.items.push(item);
      }
      break;

    case "SET_NEXTTOKENHEADER":
      result = userState;
      result.userData.nextTokensHeader.push(userAction.payload);
      break;

    case "SET_NEXTTOKEN":
      result = userState;
      result.userData.nextTokens.push(userAction.payload);
      break;

    case "SET_PAGE":
      result = userState;
      result.userData.pages.push(userAction.payload);
      break;

    case "IS_SET_ITEMS_HEADER":
      result = userState;
      result.userData.isSetItemsHeaders = true;
      break;

    default:
      result = userState;
  }

  sessionStorage.setItem("userState", JSON.stringify(result));
  //console.log("USER-REDUCER: NEW RESULT STATE",result);
  //console.log("\n");
  return result;
};
