import React from "react";
import { Card, Label } from "semantic-ui-react";
import { useContext,useEffect,useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";
import { setOptions } from "highcharts";

const TotalDateHourBox = () => {
  const [dateRange,setDateRange] = useState("from " + "1/5/2022" + " to " + "6/5/2022");
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);

  const hoursRange = "Early Morning & Late Morning";

  useEffect(()=>{

    //console.log("METRICSTATE",metricsState); 
    //console.log("INIT=",metricsState.metricResults.IntervalData.epoch_init);
    //console.log("FINISH=",metricsState.metricResults.IntervalData.epoch_finish);
    if(metricsState.metricResults && metricsState.metricResults.IntervalData){
    let einit = metricsState.metricResults.IntervalData.epoch_init;
    let efinish = metricsState.metricResults.IntervalData.epoch_finish;
    
    let init=new Date(einit).toLocaleDateString();
    let finish=new Date(efinish).toLocaleDateString()
    //console.log("DATEINIT",init);
    //console.log("DATEFINISH",finish);

    setDateRange("from " + init + " to " + finish);
  
    }
    
    
  },[metricsState.metricResults]);


  return (
    <>
      <Card
        fluid
        style={{
          backgroundColor: "#000000",
          borderColor: "#ffffff",
          borderWidth: "20px",
          borderRadius: 15,
          fontFamily: "Tahoma"
        }}
      >
        <Card.Content>
          <Label ribbon color="purple">
            Date and Hours Range
          </Label>
          <Card.Header
            textAlign="center"
            style={{
              color: "#ABB2B9",
              fontSize: "15px"
            }}
          >
            this data is filtered to the next time range
          </Card.Header>
          <Card.Content
            textAlign="center"
            style={{
              color: "#EBF1F4",
              fontSize: "19px"
            }}
          >
            {dateRange}
          </Card.Content>
          <Card.Content
            textAlign="center"
            style={{
              color: "#EBF1F4",
              fontSize: "16px"
            }}
          >
            {hoursRange}
          </Card.Content>
        </Card.Content>
      </Card>
    </>
  );
};

export default TotalDateHourBox;
